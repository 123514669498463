import React, { Component } from 'react'
import { connect } from 'react-redux'
import sha256 from 'sha256'

import Sidebar from './Sidebar'

import * as LOGIN from '../../redux/actions/login'

class RootSidebar extends Component {

    render() {

        return (<>
            <Sidebar
                listItems={this.props.items}
                onClickButton={() => this.props.logout()}
                buttonText={"Logout"} />
        </>)
    }
}

const mapStateToProps = reducers => ({
})

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(LOGIN.logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(RootSidebar)