import React from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Delete as DeleteIcon } from '@material-ui/icons'
import moment from 'moment'

import * as CONSTANTS from '../../../utils/constants'
import * as NOTIFICATIONS from '../../../utils/notification'
import * as TICKET_REQUESTS from '../../../redux/actions/tickets'

import ConfirmationDialog from '../../common/ConfirmationDialog'
import SimpleTable from '../../common/SimpleTable'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        overflow: 'auto'
    },
    leftHeaderButton: {
        fontSize: '32px',
        marginRight: '5px',
        color: '#0f2557'
    },
    squareStatus: {
        borderRadius: 2,
        color: 'white',
        fontWidth: 'bold',
        fontSize: 16,
        width: 25,
        height: 25,
        textAlign: '-webkit-center',
        paddingBottom: '0px',
        alignContent: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    legend: {
        fontWidth: 'bold',
        fontSize: 12,
        textAlign: '-webkit-center',
        alignContent: 'center',
        marginLeft: '3px',
        color: 'black',

    },
    alignLegend: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '3px'
    },
    margin: {
        margin: '4px'
    },
    bottomLegend: {
        display: 'flex',
        bottom: 0,
        //position: 'absolute'
    },
    simpleTable: {
        maxHeight: '50%',
        overflow: 'auto',
        maxHeight: '590px'
    }
})

class TicketsList extends React.Component {

    ticketToDelete = {}

    state = {
        count: 0,
        from: 0,
        rowsPerPage: 10,
        search: '',
        rows: [],
        openConfirmationModal: false
    }

    componentWillReceiveProps(nextProps) {
        let nextFilter = nextProps.match.params.FILTER
        let currentFilter = this.props.match.params.FILTER

        if (nextFilter !== currentFilter) {
            this.getTicketsHandler({ filter: nextFilter })
        }

        if (nextProps.tickets.create && !this.props.tickets.create) {
            this.getTicketsHandler({})
        }
    }

    componentDidMount() {
        this.getTicketsHandler()
    }

    filterOptionHandler = options => {
        let filterOption = ''
        if (options && options.filter) {
            filterOption = options.filter
        }
        else if (options && options.filter === undefined) {
            filterOption = ''
        }
        else {
            filterOption = this.props.match.params.FILTER
        }

        return filterOption
    }

    getTicketsHandler = options => {
        const filterOptions = this.filterOptionHandler(options)
    
        return this.props.getTickets({
            from: this.state.from,
            search: this.state.search,
            limit: this.state.rowsPerPage,
            filter: filterOptions
        }).then(({ tickets, count }) => {
            this.props.unsetCreated()
            this.setState({
                count,
                rows: tickets.map((ticket, index) => ({
                    index: index + 1,
                    _id: ticket._id,
                    identifier: ticket._id.slice(-6),
                    status: ticket.status || 'OPEN',
                    subject: ticket.subject || '**',
                    author: ticket.author ? `${ticket.author.firstName} ${ticket.author.lastName}` : '**',
                    role: ticket.author ? (ticket.author.role).toUpperCase() : '**',
                    company: ticket.assignedCompany ? ticket.assignedCompany.name : '**',
                    building: ticket.building ? ticket.building.name : '**',
                    service: ticket.services ? `${ticket.services[0]}` : '**',
                    created: moment(ticket.createdAt).format(CONSTANTS.DATE_FORMAT) || '**',
                    updated: moment(ticket.updatedAt).format(CONSTANTS.DATE_FORMAT) || '**'
                })),
                openConfirmationModal: false
            })
        })
    }

    changeRowsPerPageHandler = rowsPerPage => {
        this.setState({ rowsPerPage }, this.getTicketsHandler)
    }

    changePageHandler = currentFrom => {
        this.setState({ from: currentFrom }, this.getTicketsHandler)
    }

    onDeleteHandler = () => {
        this.props.deleteTicket(this.ticketToDelete._id).then(() => {
            NOTIFICATIONS.success(this.props.language.toastr.ticket.successDelete)
            this.getTicketsHandler()
        })
            .catch(() => {
                NOTIFICATIONS.error(this.props.language.toastr.ticket.errorDelete)
            })
    }

    populateLegend = () => {
        let { classes } = this.props
        const newArray = CONSTANTS.STATUS.map(legendItem =>
            <div className={classes.alignLegend}><div className={classes.margin}><div style={{ backgroundColor: legendItem.color }} className={classes.squareStatus}>
                {this.props.language.labels.status[legendItem.name].substr(0, 1).toUpperCase()}
            </div></div> <p className={classes.legend}>{this.props.language.labels.status[legendItem.name].toUpperCase()}</p></div>)
        return newArray;
    }

    onSearch = search => this.setState({ search }, this.getTicketsHandler)

    render() {
        let { classes } = this.props
        //let mappedLegend = this.populateLegend()

        return (
            <div className={classes.container}>
                <ConfirmationDialog
                    open={this.state.openConfirmationModal}
                    cancelButtonText={this.props.language.buttons.cancel}
                    acceptButtonText={this.props.language.buttons.delete}
                    text={`${this.props.language.utils.delete}`}
                    onCancel={() => this.setState({ openConfirmationModal: false })}
                    onClose={() => this.setState({ openConfirmationModal: false })}
                    onAccept={this.onDeleteHandler}
                />
                <div >
                    <SimpleTable
                        title={this.props.language.titles.ticketTable}
                        header={this.props.language.tableHeaders.tickets}
                        rows={this.state.rows}
                        onClickRow={item => {
                            this.props.history.push(`/tickets/${item._id}`)
                        }}
                        leftHeaderButton={{

                        }}
                        headerButton={{
                        }}
                        disableBorders
                        search={{
                            onSearch: this.onSearch
                        }}
                        actions={this.props.loggedUser && (this.props.loggedUser.role == 'admin' || this.props.loggedUser.role == 'pm') ? [
                            {
                                tooltip: this.props.language.buttons.delete,
                                onClick: (event, row) => {
                                    this.ticketToDelete = row
                                    this.setState({ openConfirmationModal: true })
                                },
                                icon: <DeleteIcon />
                            }
                        ] : []}
                        count={this.state.count}
                        rowsPerPage={CONSTANTS.DEFAULT_ROWS_PER_PAGE_VALUE}
                        changeRowsPerPage={this.changeRowsPerPageHandler}
                        changePageHandler={this.changePageHandler}
                    />
                </div>

                {/* <div className={classes.bottomLegend}>
                    {mappedLegend}
                </div> */}
            </div>
        )
    }

}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    tickets: reducers.ticketsReducer,
    loggedUser: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    unsetCreated: () => dispatch(TICKET_REQUESTS.unsetCreated()),
    getTickets: options => dispatch(TICKET_REQUESTS.get(options)),
    deleteTicket: ticketId => dispatch(TICKET_REQUESTS.del(ticketId))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TicketsList)))