import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import { ErrorOutline as InformIcon } from '@material-ui/icons'
import { connect } from 'react-redux'

import InputGenerator from '../../common/InputGenerator'
import SimpleModal from '../../common/SimpleModal'
import AnafButton from '../../common/AnafButton'

import * as BUILDING_REQUESTS from '../../../redux/actions/buildings'
import * as USER_REQUESTS from '../../../redux/actions/users'
import * as TAG_REQUESTS from '../../../redux/actions/tags'
import * as NOTIFICATION from '../../../utils/notification'

let InputWrapper = props => <div>
    <InputGenerator
        key={props.key}
        fullWidth={true}
        InputLabelProps={props.shrink ? { shrink: true } : {}}
        margin="dense"
        {...props.input}
        value={props.value}
        onChange={props.onChange}
        {...props}
    />
</div>

let Create = props => {
    let { classes } = props
    let [fields, setFields] = useState([
        { value: '', type: 'text', label: props.language.labels.name, name: 'name' },
        { value: 0, type: 'number', label: props.language.labels.totalSurface, name: 'totalArea' },
        { value: 0, type: 'number', label: props.language.labels.commercialSurface, name: 'commercialArea' },
        { value: 0, type: 'number', label: props.language.labels.officeSurface, name: 'officeArea' },
        { value: 0, type: 'number', label: props.language.labels.utilSurface, name: 'usableArea' },
        { value: '', type: 'text', label: props.language.labels.contactPhone, name: 'phoneNumber' },
        { value: '', type: 'text', label: props.language.labels.website, name: 'website' },
        { value: '', type: 'text', label: props.language.labels.mail, name: 'email' },
        { type: 'simpleSelector', name: 'owner', label: props.language.labels.propertyOwner, value: null, options: [], shrink: false },
        { type: 'simpleSelector', name: 'facilityManager', label: props.language.labels.facilityManager, value: null, options: [], shrink: false },
        { type: 'simpleSelector', name: 'propertyManager', label: props.language.labels.propertyManager, value: null, options: [], shrink: false },
        {
            type: 'fixedMultiSelect',
            name: 'services',
            utils: props.language.labels.services,
            options: [],
            value: []
        }
    ])
    let [cui, setCui] = useState('')

    let onCuiChange = event => setCui(event.target.value)

    let getServices = type => props.getServices(type)
        .then(data => data)

    useEffect(() => {
        if (props.building) {
            return populate()
        }

        let getAllUsers = props.getAllUsers()
        let getAllServices = getServices('services')

        Promise.all([getAllUsers, getAllServices]).then(values => {
            let options = values[0].users.map(user => ({
                name: `${user.firstName} ${user.lastName}`,
                value: user._id,
            }))

            let servicesOptions = [{ name: '', value: false, label: '' }].concat([...values[1].map(type => ({
                name: type.name,
                value: false,
                label: type.name
            }))])

            let newFields = [...fields]

            let ownerIndex = newFields.findIndex(field => field.name === 'owner')
            let facilityManagerIndex = newFields.findIndex(field => field.name === 'facilityManager')
            let propertyManagerIndex = newFields.findIndex(field => field.name === 'propertyManager')
            let servicesIndex = newFields.findIndex(field => field.name === 'services')

            if (ownerIndex < 0 || facilityManagerIndex < 0 || propertyManagerIndex < 0 || servicesIndex < 0) {
                return NOTIFICATION(props.language.notifications.populateError)
            }

            newFields[ownerIndex].options = options
            newFields[facilityManagerIndex].options = options
            newFields[propertyManagerIndex].options = options
            newFields[servicesIndex].options = servicesOptions
            newFields[servicesIndex].defaultValue = servicesOptions.map(service => ({ ...service, value: true })).filter(service => service.name)
            newFields[servicesIndex].value = servicesOptions.map(service => ({ ...service, value: true })).filter(service => service.name)

            setFields(newFields)
        })

        return () => { }
    }, [props.building])

    let populate = () => {
        let newFields = [...fields]
        Object.keys(props.building).forEach(key => {
            let fieldIndex = newFields.findIndex(f => f.name === key)

            if (fieldIndex <= -1) return

            newFields[fieldIndex].value = props.building[key]
            newFields[fieldIndex].disabled = false
        })

        setFields(newFields)
    }

    let onInputChange = event => {
        let fieldIndex = fields.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...fields]

        newFields[fieldIndex].value = event.target.value

        setFields(newFields)
    }

    let createHandler = () => {
        let data = {}
        data.area = {}
        data.contact = {}
        data.cui = cui
        
        fields.forEach(field => {
            if (field.name === 'totalArea') {
                data.area.total = field.value
            } else if (field.name === 'usableArea') {
                data.area.usable = field.value
            } else if (field.name === 'officeArea') {
                data.area.office = field.value
            } else if (field.name === 'commercialArea') {
                data.area.commercial = field.value
            } else if (field.name === 'email' || field.name === 'website' || field.name === 'phoneNumber') {
                data.contact[field.name] = field.value
            } else if (field.name === 'services')
                data.services = field.value.map(field => field.name)
            else data[field.name] = field.value
        })

        if (!props.building) return props.createBuilding({ building: data })
            .then(() => {
                props.onAccept()
                clearForm()
                NOTIFICATION.success(props.language.toastr.building.successCreate)
            })
            .catch(() => NOTIFICATION.error(props.language.toastr.building.errorCreate))

        return props.editBuilding(props.building._id, { building: data })
            .then(() => {
                props.onAccept()
                clearForm()
                NOTIFICATION.success(props.language.toastr.building.successedit)
            })
            .catch(() => NOTIFICATION.error(props.language.toastr.building.errorEdit))

    }

    let onAnafButtonClickedHandler = anafData => {
        let newFields = [...fields]

        let indexOfName = newFields.findIndex(field => field.name == 'name')

        if(indexOfName < 0) return

        newFields[indexOfName].value = anafData.denumire

        setFields(newFields)
    }

    let clearForm = () => {
        let newFields = [...fields.map(field => ({ ...field, value: null }))]
        setFields(newFields)
    }

    let renderFields = () => {
        let jsonMap = {}
        fields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={classes.flexColumn}>
                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['name']}
                        input={jsonMap['name']}
                        key={'name'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                {<div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['owner']}
                        input={jsonMap['owner']}
                        key={'owner'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>}

                {<div className={`${classes.inputField} ${classes.flexRow}`}>
                    <div className={classes.inputField}>
                        <InputWrapper
                            {...jsonMap['facilityManager']}
                            input={jsonMap['facilityManager']}
                            key={'facilityManager'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                        />
                    </div>

                    <div className={classes.inputField}>
                        <InputWrapper
                            {...jsonMap['propertyManager']}
                            input={jsonMap['propertyManager']}
                            key={'propertyManager'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                        />
                    </div>
                </div>
                }


                <div className={`${classes.inputField} ${classes.flexRow}`}>
                    <div className={`${classes.inputField} ${classes.paddingRight}`}>
                        <InputWrapper
                            {...jsonMap['totalArea']}
                            input={jsonMap['totalArea']}
                            key={'totalArea'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                        />
                    </div>
                    <div className={`${classes.inputField} ${classes.paddingRight}`}>
                        <InputWrapper
                            {...jsonMap['usableArea']}
                            input={jsonMap['usableArea']}
                            key={'usableArea'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                        />
                    </div>

                    <div className={`${classes.inputField} ${classes.paddingRight}`}>
                        <InputWrapper
                            {...jsonMap['commercialArea']}
                            input={jsonMap['commercialArea']}
                            key={'commercialArea'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                        />
                    </div>

                    <div className={classes.inputField}>
                        <InputWrapper
                            {...jsonMap['officeArea']}
                            input={jsonMap['officeArea']}
                            key={'officeArea'}
                            shrink={true}
                            onChange={event => onInputChange(event)}
                        />
                    </div>
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['phoneNumber']}
                        input={jsonMap['phoneNumber']}
                        key={'phoneNumber'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['email']}
                        input={jsonMap['email']}
                        key={'email'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['website']}
                        input={jsonMap['website']}
                        key={'website'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['services']}
                        input={jsonMap['services']}
                        key={'services'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                {!props.building && <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <div className={classes.flexRow}>
                        <InformIcon className={classes.informIcon} />
                        <div className={classes.importantTextContainer}>
                            <span className={classes.importantText}>IMPORTANT</span>
                        </div>
                    </div>
                    <p className={classes.infoText}>Atribuirea persoanelor responsabile de cladire se face din sectiunea de detalii.</p>
                </div>}
            </div>
        )
    }

    return (
        <SimpleModal
            open={props.open}
            maxWidth={'sm'}
            title={props.language.titles.createBuilding}
            acceptButtonText={props.building ? props.language.edit : props.language.create}
            cancelButtonText={props.language.cancel}
            withCancelButton={true}
            onCancel={() => {
                props.onCancel()
                clearForm()
            }}
            onAccept={createHandler}
            styles={{
                title: classes.titleStyle
            }}
        >
            <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                <InputWrapper
                    name={'cui'}
                    type='text'
                    value={cui}
                    label={'Cui'}
                    onChange={onCuiChange}
                />
            </div>
            <div className={classes.anafButton}>
                <AnafButton 
                    cui={cui}
                    onAnafButtonClicked={onAnafButtonClickedHandler}
                />
            </div>
            {renderFields()}
        </SimpleModal>
    )
}

const styles = theme => ({
    paddingRight: {
        marginRight: 6
    },
    anafButton: {
        paddingTop: 10
    },
    informIcon: {
        color: '#f50057'
    },
    infoText: {
        color: 'rgba(0,0,0,0.5)',
        fontSize: 14,
        margin: 0,
        padding: 0,
        paddingLeft: 24,
        fontWeight: 'bold'
    },
    importantTextContainer: {
        marginTop: 0,
        marginLeft: 4
    },
    importantText: {
        color: '#f50057',
        letterSpacing: 1,
        fontWeight: 'bold',
        fontSize: 12
    },
    titleStyle: {
        height: '30%',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        padding: '16px 8px'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    inputField: {
        flex: 1
    },
    paddingRightLeft: {
        paddingRight: 12,
        '&:last-child': {
            paddingLeft: 12
        }
    },
    smallText: {
        fontSize: 11,
        color: '#757575'
    },
    paddingTopButton: {
        padding: '8px 0px'
    }
})


const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    createBuilding: building => dispatch(BUILDING_REQUESTS.create(building)),
    getAllUsers: () => dispatch(USER_REQUESTS.get({ all: true })),
    getServices: type => dispatch(TAG_REQUESTS.getAll({ type })),
    editBuilding: (id, building) => dispatch(BUILDING_REQUESTS.edit(id, building))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Create))
