import React, { useState, useEffect } from 'react'
import { withStyles, Button, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'

import InputGenerator from '../../common/InputGenerator'
import SimpleModal from '../../common/SimpleModal'

import { ROLES } from '../../../utils/constants'
import * as USER_REQUESTS from '../../../redux/actions/users'
import * as COMPANY_REQUESTS from '../../../redux/actions/companies'
import * as NOTIFICATION from '../../../utils/notification'

let InputWrapper = props => <div>
    <InputGenerator
        key={props.key}
        fullWidth={true}
        InputLabelProps={props.shrink ? { shrink: true } : {}}
        margin="dense"
        {...props.input}
        value={props.value}
        onChange={props.onChange}
        {...props}
    />
</div>

let Create = props => {
    let { classes } = props
    let [fields, setFields] = useState([
        { value: '', type: 'text', label: props.language.labels.name, name: 'firstName' },
        { value: '', type: 'text', label: props.language.labels.midName, name: 'lastName' },
        { value: '', type: 'text', label: props.language.labels.title, name: 'title' },
        { value: '', type: 'text', label: props.language.labels.email, name: 'email' },
        { value: '', type: 'text', label: props.language.labels.phone, name: 'phoneNumber' },
        {
            type: 'dropdownSelector',
            name: 'role',
            utils: props.language.labels.role,
            value: '',
            options: [{ name: '', value: true, label: '' }].concat(Object.values(ROLES).map(role => ({ name: role, value: false, label: role }))),
        }
    ])
    let [formDataProfileImage, setFormDataProfileImage] = useState(null)

    useEffect(() => {
        // Acts like componentWillRecieveProps and watches for props.open change
        props.getCompanies({})
            .then(({ companies }) => {
                let companyFieldIndex = fields.findIndex(field => field.name === 'company')

                if (!(companyFieldIndex > -1)) return

                if (companies.length) {
                    let newFields = [...fields]
                    newFields[companyFieldIndex].options = [{ name: '', label: '', value: false }].concat(companies.map(company => ({ name: company._id, label: company.name, _id: company._id })))
                    //newFields[companyFieldIndex].value = [{ name: '', label: '', value: false }].concat(companies[0]._id)

                    setFields(newFields)
                }
            })

        // Check if opened for edit
        if (props.user) {
            // Means is opened for edit, populate
            populateRoleOptions(true)
            populate()
        }

        if (!props.user) {
            populateRoleOptions(false)
        }

        setFormDataProfileImage(null)

        return () => { }
    }, [props.open, props.user])

    let populateRoleOptions = (all) => {
        let newFields = [...fields]
        let fieldIndex = fields.findIndex(field => field.name === 'role')

        if (fieldIndex > -1) {
            if (all)
                newFields[fieldIndex].options = [{ name: '', value: true, label: '' }].concat(Object.values(ROLES).map(role => ({ name: role, value: false, label: role })))
            newFields[fieldIndex].options = newFields[fieldIndex].options.filter(option => option.name != 'user')
        }

        setFields(newFields)
    }

    let populate = () => {
        let newFields = [...fields]
        Object.keys(props.user).forEach(key => {
            let fieldIndex = newFields.findIndex(f => f.name === key)

            if (fieldIndex <= -1) return
            if (key === 'password') return

            newFields[fieldIndex].value = props.user[key]

            if (key === 'role') {
                newFields[fieldIndex].defaultValue = props.user[key]
            }

            // if (key === 'company') {
            //     newFields[fieldIndex].defaultValue = props.user[key] ? props.user[key]._id : null
            //     newFields[fieldIndex].value = props.user[key] ? props.user[key]._id : null
            // }
        })


        setFields(newFields)
    }

    let onInputChange = event => {
        let fieldIndex = fields.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...fields]
        newFields[fieldIndex].value = event.target.value

        setFields(newFields)
    }

    let createUserHandler = () => {
        let data = {}
        fields.forEach(field => data[field.name] = field.value)

        // if (data.password !== data.confirmPassword) return NOTIFICATION.error(props.language.toastr.user.passwordsMatchError)
        // // Delete password property of password is empty string
        // if (!data.password.length) {
        //     delete data.password
        //     delete data.confirmPassword
        // }

        if (!data.role) return NOTIFICATION.error(props.language.toastr.user.selectRole)

        if (!props.user) return props.createUser(data)
            .then((user) => {
                if (!formDataProfileImage) {
                    props.onAccept()
                    clearForm()
                }
                NOTIFICATION.success(props.language.toastr.user.successCreate)

                if (formDataProfileImage) {
                    props.uploadImageTo(user._id, formDataProfileImage)
                        .then(() => {
                            props.onAccept()
                            clearForm()
                            NOTIFICATION.success(props.language.toastr.image.successUpload)
                        })
                        .catch(() => {
                            props.onCancel()
                            NOTIFICATION.error(props.language.toastr.image.errorUpload)
                        })
                }
            })
            .catch(() => NOTIFICATION.error(props.language.toastr.user.errorCreate))

        return props.editUser(props.user._id, data)
            .then(() => {
                if (!formDataProfileImage) {
                    props.onAccept()
                    clearForm()
                }
                NOTIFICATION.success(props.language.toastr.user.successEdit)

                if (formDataProfileImage) {
                    props.uploadImageTo(props.user._id, formDataProfileImage)
                        .then(() => {
                            props.onAccept()
                            clearForm()
                            NOTIFICATION.success(props.language.toastr.image.successUpload)
                        })
                        .catch(() => {
                            props.onCancel()
                            NOTIFICATION.error(props.language.toastr.image.errorUpload)
                        })
                }
            })
            .catch(() => NOTIFICATION.error(props.language.toastr.user.errorEdit))
    }

    let clearForm = () => {
        let newFields = [...fields.map(field => ({ ...field, value: null, defaultValue: null }))]
        setFields(newFields)
    }

    let imageUploadHandler = event => {
        let filesArray = Array.from(event.target.files)
        const fileFormData = new FormData();
        fileFormData.append('file', filesArray[0])
        setFormDataProfileImage(fileFormData)
    }
    let uploadRef = React.createRef();

    let triggerInput = () => {
        uploadRef.current.click();
    }

    let renderFields = () => {

        let jsonMap = {}
        fields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={classes.flexColumn}>
                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['firstName']}
                        shrink={true}
                        input={jsonMap['firstName']}
                        key={'firstName'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['lastName']}
                        input={jsonMap['lastName']}
                        shrink={true}
                        key={'lastName'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['title']}
                        input={jsonMap['title']}
                        shrink={true}
                        key={'title'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['phoneNumber']}
                        input={jsonMap['phoneNumber']}
                        shrink={true}
                        key={'phoneNumber'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['email']}
                        shrink={true}
                        input={jsonMap['email']}
                        key={'email'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['role']}
                        input={jsonMap['role']}
                        key={'role'}
                        shrink={true}
                        value={''}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div>
                    <label htmlFor='imageUpload' >
                        <Tooltip placement={'top'} title={props.language.tooltip.uploadImage}>
                            <Button color='primary' variant="contained" onClick={triggerInput} >{props.language.tooltip.uploadImage}</Button>
                        </Tooltip>
                    </label>
                    <input id='imageUpload' ref={uploadRef} type={'file'} onChange={imageUploadHandler} style={{ display: "none" }}></input>
                </div>
            </div>
        )
    }

    return (
        <SimpleModal
            open={props.open}
            maxWidth={'sm'}
            title={props.language.titles.createUser}
            acceptButtonText={props.user ? props.language.edit : props.language.create}
            cancelButtonText={props.language.cancel}
            withCancelButton={true}
            onCancel={() => {
                props.onCancel()
                clearForm()
            }}
            onAccept={createUserHandler}
            styles={{
                title: classes.titleStyle
            }}
        >
            {renderFields()}
        </SimpleModal>
    )
}

const styles = theme => ({
    titleStyle: {
        height: '30%',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        padding: '16px 8px'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    inputField: {
        flex: 1
    },
    paddingRightLeft: {
        paddingRight: 12,
        '&:last-child': {
            paddingLeft: 12
        }
    },
    smallText: {
        fontSize: 11,
        color: '#757575'
    },
    paddingTopButton: {
        padding: '8px 0px'
    }
})


const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getCompanies: (options) => dispatch(COMPANY_REQUESTS.get(options)),
    createUser: user => dispatch(USER_REQUESTS.createAccountFromCompany({ user })),
    editUser: (id, user) => dispatch(USER_REQUESTS.edit(id, user)),
    uploadImageTo: (id, images) => dispatch(USER_REQUESTS.uploadImage(id, images))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Create))
