import React, { useState, useEffect } from 'react'
import { withStyles, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'
import { PhotoLibrary as UploadBuildingImage, Delete as DeleteIcon } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'

import InputGenerator from '../../../common/InputGenerator'
import * as USER_REQUESTS from '../../../../redux/actions/users'
import * as BUILDING_REQUESTS from '../../../../redux/actions/buildings'
import * as TAG_REQUESTS from '../../../../redux/actions/tags'
import * as NOTIFICATION from '../../../../utils/notification'

let InputWrapper = props => <div>
    <InputGenerator
        key={props.key}
        fullWidth={true}
        InputLabelProps={props.shrink ? { shrink: true } : {}}
        margin="dense"
        {...props.input}
        value={props.value}
        onChange={props.onChange}
        {...props}
    />
</div>

let Editable = props => {
    let { classes } = props
    let [renderPage, setRenderPage] = useState(false)
    let [fields, setFields] = useState([
        { value: '', type: 'text', label: props.language.labels.name, name: 'name' },
        { value: 0, type: 'number', label: props.language.labels.totalSurface, name: 'totalArea' },
        { value: 0, type: 'number', label: props.language.labels.commercialSurface, name: 'commercialArea' },
        { value: 0, type: 'number', label: props.language.labels.officeSurface, name: 'officeArea' },
        { value: 0, type: 'number', label: props.language.labels.utilSurface, name: 'usableArea' },
        { value: '', type: 'text', label: props.language.labels.contactPhone, name: 'phoneNumber' },
        { value: '', type: 'text', label: props.language.labels.website, name: 'website' },
        { value: '', type: 'text', label: props.language.labels.mail, name: 'email' },
        { type: 'simpleSelector', name: 'owner', label: props.language.labels.propertyOwner, value: null, options: [], shrink: false },
        { type: 'simpleSelector', name: 'facilityManager', label: props.language.labels.facilityManager, value: null, options: [], shrink: false },
        { type: 'simpleSelector', name: 'propertyManager', label: props.language.labels.propertyManager, value: null, options: [], shrink: false },
        {
            type: 'fixedMultiSelect',
            name: 'services',
            utils: props.language.labels.services,
            options: [],
            value: [],
            defaultValue: []
        }
    ])

    let onInputChange = event => {
        let fieldIndex = fields.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...fields]

        if (event.target.name === 'facilityManager' || event.target.name === 'propertyManager' || event.target.name === 'owner') {
            newFields[fieldIndex].value = event.target.value.length ? event.target.value : null
        } else newFields[fieldIndex].value = event.target.value


        setFields(newFields)

        let building = {
            ...props.building
        }

        fields.forEach(field => {
            if (field.name === 'totalArea') {
                building.area.total = field.value
            } else if (field.name === 'commercialArea') {
                building.area.commercial = field.value
            } else if (field.name === 'officeArea') {
                building.area.office = field.value
            } else if (field.name === 'usableArea') {
                building.area.usable = field.value
            } else if (field.name === 'website') {
                building.contact.website = field.value
            } else if (field.name === 'phoneNumber') {
                building.contact.phoneNumber = field.value
            } else if (field.name === 'email') {
                building.contact.email = field.value
            } else if (field.name === 'services') {
                building.services = field.value.length ? field.value.map(field => field.name) : field.defaultValue.map(field => field.name)
            } else building[field.name] = field.value
        })

        editBuilding(props.building._id, building)
    }

    let imageUploadHandler = (event) => {
        let filesArray = Array.from(event.target.files)
        const fileFormData = new FormData();
        fileFormData.append('file', filesArray[0])
        props.uploadImageTo(props.building._id, fileFormData)
            .then(() => {
                NOTIFICATION.success(props.language.toastr.image.successUpload)
            })
            .catch(() => {
                NOTIFICATION.error(props.language.toastr.image.errorUpload)
            })
    }


    let getServices = type => props.getServices(type)
        .then(data => data)


    useEffect(() => {
        let getAllUsers = props.getUsers()
        let getAllServices = getServices('services')

        Promise.all([getAllUsers, getAllServices]).then(values => {
            let options = values[0].users.map(user => ({
                name: `${user.firstName} ${user.lastName}`,
                value: user._id,
            }))

            let servicesOptions = [...values[1].map(type => ({
                name: type.name,
                value: false,
                label: type.name
            }))]

            let newFields = [...fields]

            let ownerIndex = newFields.findIndex(field => field.name === 'owner')
            let facilityManagerIndex = newFields.findIndex(field => field.name === 'facilityManager')
            let propertyManagerIndex = newFields.findIndex(field => field.name === 'propertyManager')
            let servicesIndex = newFields.findIndex(field => field.name === 'services')

            if (ownerIndex < 0 || facilityManagerIndex < 0 || propertyManagerIndex < 0 || servicesIndex < 0) {
                return NOTIFICATION(props.language.notifications.populateError)
            }

            newFields[ownerIndex].options = options
            newFields[facilityManagerIndex].options = options
            newFields[propertyManagerIndex].options = options
            newFields[servicesIndex].options = servicesOptions

            setFields(newFields)
        })

        if (props.building) {
            populate()
        }
    }, [props.building])

    let populate = () => {
        let newFields = [...fields]

        let building = {
            name: props.building.name,
            phoneNumber: props.building.contact.phoneNumber,
            email: props.building.contact.email,
            website: props.building.contact.website,
            totalArea: props.building.area.total,
            usableArea: props.building.area.usable,
            officeArea: props.building.area.office,
            commercialArea: props.building.area.commercial,
            propertyManager: props.building.propertyManager,
            owner: props.building.owner,
            facilityManager: props.building.facilityManager,
            services: props.building.services
        }

        Object.keys(building).forEach(key => {
            let fieldIndex = fields.findIndex(f => f.name === key)

            if (!(fieldIndex > -1)) return
            if (key === 'services') {
                newFields[fieldIndex].defaultValue = building[key].map(service => ({
                    name: service
                }))
            }
            else if (key === 'owner' || key === 'facilityManager' || key === 'propertyManager') {
                newFields[fieldIndex].value = building[key] ? building[key]._id : null
            } else newFields[fieldIndex].value = building[key] || null

        })

        setFields(newFields)
        setRenderPage(true)

    }

    let editBuilding = (id, building) => {
        return props.editBuilding(id, building)
            .then(() => NOTIFICATION.success(props.language.toastr.building.successEdit))
            .catch(() => NOTIFICATION.error(props.language.toastr.building.errorEdit))
    }

    let deleteBuilding = async () => {
        if (!props.building) return
        console.log(props.history)
        let confirmed = window.confirm(`Sunteti sigur ca vreti sa stergeti cladirea ${props.building ? props.building.name : ''}?`)

        if (!confirmed) return

        try{
            await props.deleteBuilding(props.building._id)
            props.history.push('/buildings')
            NOTIFICATION.success(`Cladirea ${props.building ? props.building.name : ''} a fost stearsa cu succes.`)
        }catch(e) {
            NOTIFICATION.success(`Eroare stergere cladire.`)
        }

    }

    let renderFields = () => {
        let jsonMap = {}
        fields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={classes.flexColumn}>
                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['name']}
                        input={jsonMap['name']}
                        key={'name'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['owner']}
                        input={jsonMap['owner']}
                        key={'owner'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.flexRow}`}>
                    <div className={`${classes.inputField} ${classes.extraPadding}`}>
                        <InputWrapper
                            {...jsonMap['facilityManager']}
                            input={jsonMap['facilityManager']}
                            key={'facilityManager'}
                            onChange={event => onInputChange(event)}
                        />
                    </div>

                    <div className={`${classes.inputField} ${classes.extraPadding}`}>
                        <InputWrapper
                            {...jsonMap['propertyManager']}
                            input={jsonMap['propertyManager']}
                            key={'propertyManager'}
                            onChange={event => onInputChange(event)}
                        />
                    </div>
                </div>


                <div className={`${classes.inputField} ${classes.flexRow}`}>
                    <div className={`${classes.inputField} ${classes.extraPadding}`}>
                        <InputWrapper
                            {...jsonMap['totalArea']}
                            input={jsonMap['totalArea']}
                            key={'totalArea'}
                            onChange={event => onInputChange(event)}
                        />
                    </div>
                    <div className={`${classes.inputField} ${classes.extraPadding}`}>
                        <InputWrapper
                            {...jsonMap['usableArea']}
                            input={jsonMap['usableArea']}
                            key={'usableArea'}
                            onChange={event => onInputChange(event)}
                        />
                    </div>

                    <div className={`${classes.inputField} ${classes.extraPadding}`}>
                        <InputWrapper
                            {...jsonMap['commercialArea']}
                            input={jsonMap['commercialArea']}
                            key={'commercialArea'}
                            onChange={event => onInputChange(event)}
                        />
                    </div>

                    <div className={`${classes.inputField} ${classes.extraPadding}`}>
                        <InputWrapper
                            {...jsonMap['officeArea']}
                            input={jsonMap['officeArea']}
                            key={'officeArea'}
                            onChange={event => onInputChange(event)}
                        />
                    </div>
                </div>

                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['phoneNumber']}
                        input={jsonMap['phoneNumber']}
                        key={'phoneNumber'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['email']}
                        input={jsonMap['email']}
                        key={'email'}
                        onChange={event => onInputChange(event)}
                    />
                </div>

                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['website']}
                        input={jsonMap['website']}
                        key={'website'}
                        onChange={event => onInputChange(event)}
                    />
                </div>
                <div className={`${classes.inputField} ${classes.extraPadding}`}>
                    <InputWrapper
                        {...jsonMap['services']}
                        input={jsonMap['services']}
                        key={'services'}
                        shrink={true}
                        onChange={event => onInputChange(event)}
                    />
                </div>
            </div>
        )
    }
    if (renderPage) {
        return (
            <div className={classes.container}>
                <div className={classes.titleContainer}>
                    <span className={classes.titleText}>{props.building && props.building.name}</span>
                    <div className={classes.deleteBuilding}>
                        <Tooltip placement={'top'} title={'Sterge companie'} onClick={deleteBuilding} >
                            <DeleteIcon />
                        </Tooltip>
                    </div>
                    <div className={classes.uploadImageContainer}>
                        <label htmlFor='imageUpload' className={classes.uploadLabel}>
                            <Tooltip placement={'top'} title={props.language.tooltip.uploadImage}>
                                <UploadBuildingImage />
                            </Tooltip>
                        </label>
                        <input id='imageUpload' type={'file'} className={classes.uploadBuildingImage} onChange={imageUploadHandler}></input>
                    </div>
                </div>
                <div className={classes.contentContainer}>
                    <div className={`${classes.content}`}>
                        <div className={`${classes.contentWhiteBg}`}>
                            <div className={classes.fields}>
                                {renderFields()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else return null
}

const styles = theme => ({
    deleteBuilding: {
        paddingLeft: 10,
        marginTop: 6,
        cursor: 'pointer'
    },
    fields: {
        padding: 10
    },
    inputField: {
        flex: 1,
        marginRight: 8
    },
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#f7f8fa',
        display: 'flex',
        flexDirection: 'column',
    },
    titleContainer: {
        height: 58,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 28px',
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    titleText: {
        color: '#606771',
        fontSize: 24,
        fontWeight: 300
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        height: '100%',
        padding: '8px 8px 0px 8px',
        maxHeight: 'calc(100% - 76px)'
    },
    status: {
        marginLeft: 'auto'
    },
    content: {
        width: '95%',
        minHeight: 350,
        maxHeight: 465,
        padding: '8px 28px'
    },
    contentWhiteBg: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        overflowY: 'auto'
    },
    details: {
        padding: '8px 28px'
    },
    detailsText: {
        fontSize: 14,
        fontWeight: 300,
        color: '#3d4147'
    },
    agreeIcon: {
        color: '#34aa44'
    },
    declineIcon: {
        color: '#E74C3C'
    },
    detailsAssigned: {
        display: 'flex',
        flexDirection: 'row'
    },
    extraPadding: {
        padding: '4px 12px 1px 12px'
    },
    borderBottom: {
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    equalsFlex: {
        flex: 1
    },
    iconContainer: {
        alignItems: 'center',
        padding: '14px 0px 0px 24px'
    },
    smallButton: {
        width: 30,
        height: 30
    },
    uploadBuildingImage: {
        display: 'none'
    },
    uploadImageContainer: {
        display: 'flex',
        flex: '1',
        justifyContent: 'flex-end',
        color: '#0F2557',
        marginTop: '6px'
    },
    uploadLabel: {
        cursor: 'pointer',
        marginRight: '7px'
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getUsers: () => dispatch(USER_REQUESTS.get({ all: true })),
    editBuilding: (id, building) => dispatch(BUILDING_REQUESTS.edit(id, building)),
    getServices: type => dispatch(TAG_REQUESTS.getAll({ type })),
    uploadImageTo: (id, images) => dispatch(BUILDING_REQUESTS.uploadImage(id, images)),
    deleteBuilding: buildingId => dispatch(BUILDING_REQUESTS.remove(buildingId))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Editable)))