import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import InputGenerator from '../../../common/InputGenerator'
import SimpleModal from '../../../common/SimpleModal'

import * as TAG_REQUESTS from '../../../../redux/actions/tags'
import * as NOTIFICATIONS from '../../../../utils/notification'

let InputWrapper = props => <div>
    <InputGenerator
        key={props.key}
        fullWidth={true}
        InputLabelProps={props.shrink ? { shrink: true } : {}}
        margin="dense"
        {...props.input}
        value={props.value}
        onChange={props.onChange}
        {...props}
    />
</div>

let Create = props => {
    let { classes } = props
    let [fields, setFields] = useState([
        { value: '', type: 'text', label: props.language.labels.name, name: 'name' },
    ])

    useEffect(() => {

        return () => { }
    }, [props.open, props.type])


    let onInputChange = event => {
        let fieldIndex = fields.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...fields]
        newFields[fieldIndex].value = event.target.value

        setFields(newFields)
    }

    let createHandler = () => {
        let data = {}

        fields.forEach(field => data[field.name] = field.value)
        props.create({ ...data, type: props.type })
            .then(() => {
                NOTIFICATIONS.success(props.language.toastr.tags.successCreate)
                clearForm()
                props.onCreate()
            })
            .catch(() => {
                NOTIFICATIONS.error(props.language.toastr.tags.errorCreate)
                clearForm()
                props.onCreate()
            })

    }

    let clearForm = () => {
        let newFields = [...fields.map(field => ({ ...field, value: null }))]
        setFields(newFields)
    }

    let renderFields = () => {

        let jsonMap = {}
        fields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={classes.flexColumn}>
                <div className={`${classes.inputField} ${classes.paddingTopButton}`}>
                    <InputWrapper
                        {...jsonMap['name']}
                        input={jsonMap['name']}
                        key={'name'}
                        onChange={event => onInputChange(event)}
                    />
                </div>
            </div>
        )
    }

    return (
        <SimpleModal
            open={props.open}
            maxWidth={'sm'}
            title={props.title}
            acceptButtonText={props.acceptButtonText}
            cancelButtonText={props.language.cancel}
            withCancelButton={true}
            onCancel={() => {
                props.onCancel()
                clearForm()
            }}
            onAccept={createHandler}
            styles={{
                title: classes.titleStyle
            }}
        >
            {renderFields()}
        </SimpleModal>
    )
}

const styles = theme => ({
    titleStyle: {
        height: '30%',
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    inputField: {
        flex: 1
    },
    paddingRightLeft: {
        paddingRight: 12,
        '&:last-child': {
            paddingLeft: 12
        }
    },
    smallText: {
        fontSize: 11,
        color: '#757575'
    },
    paddingTopButton: {
        padding: '8px 0px'
    }
})


const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    create: tag => dispatch(TAG_REQUESTS.create(tag))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Create))
