import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles, CardContent, CardMedia, Typography } from '@material-ui/core'
import {
    BusinessOutlined as BuildingIcon,
} from '@material-ui/icons'

import CardWrapper from '../../common/CardWrapper'

//import * as CONSTANTS from '../../../utils/constants'

//import Card from '../../common/Card'

import { BACKEND_URL } from '../../../utils/constants'

const styles = theme => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    cardsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    cardContainer: {
        padding: '18px',
        width: '390px'
    },
    disableBorder: {
        borderBottom: 'none',
        height: '240px',
        //heightu se poate modifica mai tarziu
        display: 'flex',
        flexDirection: 'column'
    },
    surfaceTitle: {
        paddingTop: 20,
        fontWeight: 'bold',
        color: '#606771'
    },
    title: {
        paddingBottom: 0,
        margin: 0,
        fontWeight: 'bold',
        color: '#3778C2',
        width: '343px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    subtitle: {
        paddingBottom: 4,
        margin: 0,
        fontSize: 15
    },
    details: {
        display: 'flex',
        flexDirection: 'row'
    },
    smallDetailsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingTop: 12
    },
    smallDetailsContent: {
        paddingRight: 5
    },
    smallDetailsText: {
        marginTop: '-3px'
    },
    smallDetailsLeftContractor: {
        display: 'flex',
        backgroundColor: '#3778c2ab',
        border: '1px solid #3778c2ab',
        color: 'white',
        borderRadius: '5%',
        height: '20px'
    },
    smallDetailsLeft: {
        display: 'flex',
        backgroundColor: '#ef5350c2',
        border: '1px solid #ef5350c2',
        color: 'white',
        borderRadius: '5%',
        height: '20px'
    },
    smallDetailsRight: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    user: {
        fontSize: 12,
        color: 'primary',
        alignSelf: 'center'
    },
    companyImageStyle: {
        width: '100%',
        maxHeight: '200px',
        objectFit: 'fill'
    },
    typePadding: {
        padding: '1px 1px 1px 1px',
        alignItems: 'center',
        fontSize: 12
    },
    companyDetailsIndividual: {
        flex: '1'
    }
})

class CompanyCards extends React.Component {

    render() {
        let { classes } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.cardsContainer}>
                    {this.props.companies.map(company => {
                        let imagePath = company.image ? `${BACKEND_URL}/static/${company.image.path}` : 'https://via.placeholder.com/150'
                        return (
                            <div className={classes.cardContainer}>
                                {/* 
                                ****Old cards, keep it, just in case changes required****
                                <Card
                                    smallDetails={{ icon: <PeopleIcon />, text: company.users.length }}
                                    language={this.props.language}
                                    onSelect={company => this.props.history.push(`/companies/${company._id || company.id}`)}
                                    logo={true}
                                    title={company.name}
                                    subtitle={company.name}
                                    contentFields={[
                                        { name: 'mainBusiness', label: this.props.language.labels.mainBusiness, type: CONSTANTS.TYPE_TEXT },
                                        { name: 'official', multiple: ['lastName', 'firstName', 'email'], label: this.props.language.labels.official, type: CONSTANTS.TYPE_OBJECT },
                                        { name: 'tags', label: this.props.language.labels.tags, type: CONSTANTS.TYPE_OBJECT }]}
                                    item={company}
                                    onDelete={() => this.props.onDelete(company._id)}
                                    onEdit={() => this.props.onEdit(company)} /> */}
                                <CardWrapper onClick={() => this.props.history.push(`/companies/${company._id}`)}>
                                    <CardMedia
                                        component="img"
                                        alt="Company image"
                                        height="200"
                                        image={imagePath}
                                        title="Company image"
                                        className={classes.companyImageStyle}
                                    />
                                    <CardContent className={classes.disableBorder}>
                                        <div className={classes.companyDetailsIndividual}>
                                            <Typography gutterBottom variant="h5" component="h2" className={this.props.classes.title}>
                                                {company.name}
                                            </Typography>
                                        </div>
                                        {/* <Typography gutterBottom variant="h6" component="h4" className={this.props.classes.subtitle}>
                                            {company.building.name}
                                        </Typography> */}
                                        {/* <Typography gutterBottom color="textPrimary" className={classes.surfaceTitle || '-'}>
                                            {this.props.language.labels.companyDetails}
                                        </Typography> */}
                                        <div className={classes.companyDetailsIndividual}>
                                            <Typography color="textSecondary" className={classes.details}>
                                                {this.props.language.labels.mainBusiness}: &nbsp; <Typography className={classes.user} color='textPrimary'>{`${company.mainBusiness.toUpperCase() || '-'}`}</Typography>
                                            </Typography>
                                        </div>
                                        <div className={classes.companyDetailsIndividual}>
                                            <Typography color="textSecondary" className={classes.details}>
                                                {this.props.language.labels.official}: &nbsp; <div>
                                                    <Typography className={classes.user} color='textPrimary'>{company.official ? `${company.official.firstName}` : ' -'}</Typography>
                                                    <Typography className={classes.user} color='textPrimary'>{company.official ? `${company.official.lastName}` : ' -'}</Typography>
                                                </div>
                                            </Typography>
                                        </div>
                                        <div className={classes.companyDetailsIndividual}>
                                            <Typography color="textSecondary" className={classes.details}>
                                                {this.props.language.labels.email} &nbsp;  <Typography className={classes.user} color='textPrimary'>{company.official ? `${company.official.email}` : ' -'}</Typography>
                                            </Typography>
                                        </div>
                                        <div className={classes.companyDetailsIndividual}>
                                            {company.type.toLowerCase() === 'contractor' ? <Typography color="textSecondary" className={classes.details}>
                                                {this.props.language.labels.services} &nbsp; <Typography className={classes.user} color='textPrimary'>{company.services ? `${company.services.map((service, index) => index !== company.services.length - 1 ? ' ' + service : ' ' + service)}` : '-'}</Typography>
                                            </Typography> : ''}
                                        </div>
                                        {<div className={`${this.props.classes.smallDetailsContainer} ${classes.companyDetailsIndividual}`}>
                                            <span className={`${classes.smallDetailsContent} ${company.type ? company.type.toLowerCase() === 'contractor' ? classes.smallDetailsLeftContractor : classes.smallDetailsLeft : ''} ${classes.typePadding}`}><Typography className={`${classes.typePadding}`} color='white'>{company.type ? `${company.type}` : ''}</Typography></span>
                                            <div className={classes.smallDetailsRight}>
                                                <span className={`${classes.smallDetailsContent}`}><BuildingIcon /></span>
                                                <span className={`${classes.smallDetailsContent} ${classes.smallDetailsText}`}>{company.buildings ? company.buildings.length : '-'}</span>
                                            </div>
                                        </div>}
                                    </CardContent>
                                </CardWrapper>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CompanyCards)))