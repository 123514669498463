import React, { Component } from 'react'
import { JssProvider } from 'react-jss'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import {
    Assignment as AssignmentIcon,
    People as PeopleIcon,
    Domain as DepartmentsIcon,
    Assessment as AssessmentIcon,
    Wc as TeamsIcon,
    Dashboard as DashboardIcon,
    Settings as SettingsIcon,
    Timer as ActiveIcon,
    InsertDriveFile as NewIcon,
    VerifiedUser as VerifyIcon,
    AccountBox as CustomerIcon,
    AccountCircle as AgentIcon,
    HowToReg as AdminIcon,
    Close as ClosedIcon,
    BusinessCenter as CompaniesIcon,
    DomainOutlined as BuildingIcon,
    LocalOffer as TagIcon,
    GroupWork as UserTeamsIcon
} from '@material-ui/icons'

import RootSidebar from './sidebar/RootSidebar'
import Navbar from './navbar/Navbar'
import Dashboard from './dashboard/Dashboard'
import Tickets from './tickets/Tickets'
import Users from './users/Users'
import TicketDetails from './tickets/details/Details'
import UserDetails from './users/details/Details'
import Login from './Login/Login'
import Companies from './companies/Companies'
import CompanyDetails from './companies/details/Details'
import Buildings from './buildings/Buildings'
import BuildingDetails from './buildings/details/Details'
import Tags from './settings/tags/Tags'
import Teams from './teams/Teams'
import TeamDetails from './teams/details/Details'

import { checkForRole } from '../utils/helpers'

import * as LOGIN from '../redux/actions/login'
import * as CONSTANTS from '../utils/constants'

import '../styles/Sidebar.css'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%'
    },
    navbar: {
        height: 75,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#0F2557',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        padding: '0px 15px',
        color: '#f7f8fa',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(100% - 76px)'
    },
    sidebar: {
        width: 300,
        height: '100%',
    }
})

class Root extends Component {

    state = {
        renderPage: false,
        sidebarItems: [
            {
                label: 'dashboard',
                to: '/',
                accessFor: [CONSTANTS.ADMIN, CONSTANTS.PM, CONSTANTS.FM, CONSTANTS.USER],
                hasAccess: false,
                icon: <DashboardIcon />
            },
            {
                label: 'tickets',
                to: '/tickets',
                nested: true,
                hasAccess: false,
                accessFor: [CONSTANTS.ADMIN, CONSTANTS.PM, CONSTANTS.FM, CONSTANTS.USER],
                expandedText: 'tickets',
                icon: <AssignmentIcon />,
                nestedComponents: [
                    {
                        to: '/tickets/pending',
                        label: 'ticketPending',
                        icon: <ActiveIcon />
                    },
                    {
                        to: '/tickets/new',
                        label: 'ticketNew',
                        icon: <NewIcon />
                    },
                    {
                        to: '/tickets/verify',
                        label: 'ticketVerify',
                        icon: <VerifyIcon />

                    },
                    {
                        to: '/tickets/closed',
                        label: 'ticketClosed',
                        icon: <ClosedIcon />

                    }
                ]
            },
            {
                label: 'companies',
                to: '/companies',
                icon: <CompaniesIcon />,
                hasAccess: false,
                accessFor: [CONSTANTS.ADMIN, CONSTANTS.PM, CONSTANTS.FM]
            },
            {
                label: 'buildings',
                to: '/buildings',
                icon: <BuildingIcon />,
                hasAccess: false,
                accessFor: [CONSTANTS.ADMIN, CONSTANTS.PM, CONSTANTS.FM]
            },
            // {
            //     label: 'teams',
            //     to: '/teams',
            //     icon: <UserTeamsIcon />
            // }
            {
                label: 'users',
                nested: true,
                expandedText: 'users',
                to: '/users',
                hasAccess: false,
                accessFor: [CONSTANTS.ADMIN],
                icon: <PeopleIcon />,
                nestedComponents: [
                    {
                        to: '/users/pms',
                        label: 'pm',
                        icon: <CustomerIcon />
                    },
                    {
                        to: '/users/fms',
                        label: 'fm',
                        icon: <AgentIcon />
                    },
                    {
                        to: '/users/users',
                        label: 'user',
                        icon: <AdminIcon />

                    }
                ]
            },
            // {
            //     label: 'teams',
            //     to: '/teams',
            //     icon: <TeamsIcon />
            // },
            // {
            //     label: 'departments',
            //     to: '/departments',
            //     icon: <DepartmentsIcon />
            // },
            // {
            //     label: 'raports',
            //     to: '/raports',
            //     icon: <AssessmentIcon />
            // },
            {
                label: 'settings',
                icon: <SettingsIcon />,
                expandedText: 'settings',
                nested: true,
                hasAccess: false,
                accessFor: [CONSTANTS.ADMIN],
                nestedComponents: [
                    {
                        to: '/settings/tags',
                        label: 'tags',
                        icon: <TagIcon />
                    }
                ]
            },
        ]
    }

    setRoles = loginData => {
        let sidebarItemsCopy = this.state.sidebarItems.map(item => ({ ...item }))
        for (const el in sidebarItemsCopy) {
            let canAccess = checkForRole(sidebarItemsCopy[el].accessFor, loginData.role)
            sidebarItemsCopy[el].hasAccess = canAccess
        }

        this.setState({ sidebarItems: sidebarItemsCopy, renderPage: true })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.loginReducer.role !== nextProps.loginReducer.role) {
            this.setRoles(nextProps.loginReducer)
        }
    }

    componentDidMount() {
        this.props.isLogged().then(() => {
            this.setRoles(this.props.loginReducer)
        })
            .catch(() => {
                this.setState({ renderPage: true })
            })
    }

    render() {
        let { classes } = this.props

        if (this.state.renderPage) {
            if (this.props.loginReducer.isLogged) {
                return (
                    <JssProvider>
                        <Router>
                            <div className={classes.container}>
                                <div className={classes.navbar}>
                                    <Navbar />
                                </div>
                                <div className={classes.content}>
                                    <div className='sidebar'>
                                        <RootSidebar
                                            items={this.state.sidebarItems}
                                            onLogout={this.onLogoutHandler} />
                                    </div>
                                    <Switch>
                                        <Route path="/" exact component={checkForRole([CONSTANTS.USER], this.props.loginReducer.role) ? Tickets : Dashboard} />
                                        {<Route path="/tickets" exact component={Tickets} />}
                                        {checkForRole([CONSTANTS.PM, CONSTANTS.FM, CONSTANTS.ADMIN], this.props.loginReducer.role) && <Route path="/companies" exact component={Companies} />}
                                        {checkForRole([CONSTANTS.PM, CONSTANTS.FM, CONSTANTS.ADMIN], this.props.loginReducer.role) && <Route path="/companies/:ID" exact component={CompanyDetails} />}
                                        <Route path="/tickets/:FILTER(pending|new|verify|closed)" exact component={Tickets} />
                                        <Route path="/tickets/:ID" exact component={TicketDetails} />
                                        {checkForRole([CONSTANTS.ADMIN], this.props.loginReducer.role) && <Route path="/users" exact component={Users} />}
                                        {checkForRole([CONSTANTS.ADMIN], this.props.loginReducer.role) && <Route path="/users/:FILTER(pms|fms|users)" exact component={Users} />}
                                        {checkForRole([CONSTANTS.ADMIN, CONSTANTS.PM, CONSTANTS.FM], this.props.loginReducer.role) && <Route path="/users/:ID" exact component={UserDetails} />}
                                        {checkForRole([CONSTANTS.ADMIN, CONSTANTS.PM, CONSTANTS.FM], this.props.loginReducer.role) && <Route path="/buildings" exact component={Buildings} />}
                                        {checkForRole([CONSTANTS.ADMIN, CONSTANTS.PM, CONSTANTS.FM], this.props.loginReducer.role) && <Route path="/buildings/:ID" exact component={BuildingDetails} />}
                                        {checkForRole([CONSTANTS.ADMIN], this.props.loginReducer.role) && <Route path="/settings/tags" excat component={Tags} />}
                                        {/* <Route path="/teams" exact component={Teams} />
                                        <Route path="/teams/:ID" exact component={TeamDetails} /> */}
                                        <Redirect from="*" to={checkForRole([CONSTANTS.USER], this.props.loginReducer.role) ? Tickets : Dashboard} />
                                    </Switch>
                                </div>
                            </div>
                        </Router>
                    </JssProvider>
                )
            }
            else return (
                <Login />
            )
        }
        else return null
    }
}

const mapStateToProps = reducers => ({
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    isLogged: () => dispatch(LOGIN.isLogged())
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Root))