import React from 'react'
import { ResponsiveBar } from '@nivo/bar'

const DailyTicketsBar = props => {
    return (
        <>
            <p className={props.classes.chartTitle}>{props.language.titles.dailyTicketsBarTitle}</p>
            <div className={props.classes.dailyChart}>
                <ResponsiveBar
                    data={props.data}
                    groupMode={'grouped'}
                    keys={Object.values(props.language.dashboard.ticketsChartStatus)}
                    indexBy={props.language.dashboard.day}
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    padding={0.3}
                    colors={['#e8c1a0', '#f44336', '#4caf50', '#2196f3', '#424242']}
                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: props.language.dashboard.day,
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: props.language.dashboard.tickets,
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                />
            </div>
        </>
    )
}

export default DailyTicketsBar