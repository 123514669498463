import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions, DialogTitle, Button, withStyles } from '@material-ui/core'
import { Close, Delete } from '@material-ui/icons'

const style = theme => ({
    dialogTitle: {
        borderBottom: '1px solid #eaedf3',
        padding: '4px 0px 8px 4px',
        padding: '16px 8px'
    },
    dialogActions: {
        borderTop: '1px solid #eaedf3',
        marginTop: 10,
        paddingTop: 25
    },
    acceptButton: {
        backgroundColor: '#00458B',
        boxShadow: '0 0.5px 0.5px 0 rgba(22, 29, 37, 0.05), inset 0 1px 0 0 rgba(255, 255, 255, 0.05)',

    },
    cancelButton: {
        marginRight: 'auto',
        marginLeft: 4
    },
    topRightCancelButton: {
        float: 'right',
        margin: '5px 15px 0px 0px',
        cursor: 'pointer',
        fontSize: 18
    },
    dialogContent: {
        padding: '0px 10px'
    },
    deleteIcon: {
        color: '#f50057'
    }
})

class SimpleModal extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                fullWidth={true}
                maxWidth={this.props.maxWidth || 'md'}
                onClose={this.props.onClose}
            >
                {this.props.title && <DialogTitle className={this.props.styles ? this.props.styles.title : this.props.classes.dialogTitle}>
                    {this.props.title}
                    {this.props.withDeleteButton ? <Delete onClick={() => this.props.onDelete()} className={`${this.props.classes.topRightCancelButton} ${this.props.classes.deleteIcon}`} >
                        {this.props.deleteButtonText}
                    </Delete> : null}
                    {!this.props.withCancelButton ? <Close onClick={this.props.onCancel} className={this.props.classes.topRightCancelButton} /> : null}
                </DialogTitle>}
                <DialogContent className={this.props.styles ? this.props.styles.contentStyle : this.props.classes.dialogContent}>
                    {this.props.children}
                </DialogContent>
                <DialogActions className={this.props.classes.dialogActions}>
                    {this.props.editButtonText && <Button variant="contained" onClick={() => this.props.onEdit()} color="secondary">
                        {this.props.editButtonText}
                    </Button>}
                    {this.props.cancelButtonText && <Button variant="contained" className={this.props.classes.cancelButton} onClick={() => this.props.onCancel()} color="secondary">
                        {this.props.cancelButtonText}
                    </Button>}
                    {this.props.acceptButtonText && <Button variant="contained" className={this.props.styles ? this.props.styles.acceptStyle : this.props.classes.acceptButton} onClick={() => this.props.onAccept()} color="primary">
                        {this.props.acceptButtonText}
                    </Button>}
                </DialogActions>
            </Dialog>
        )
    }
}

SimpleModal.propTypes = {
    open: PropTypes.bool.isRequired,
    cancelButtonText: PropTypes.string,
    acceptButtonText: PropTypes.string,
    onAccept: PropTypes.func,
    onClose: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    size: PropTypes.string
}

export default withStyles(style)(SimpleModal)