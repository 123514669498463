import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TablePagination, withStyles } from '@material-ui/core'

const style = theme => ({

})

class Pagination extends Component {

    state = {
        rowsPerPage: this.props.rowsPerPage,
        page: 0
    }


    changeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value }, () => this.props.changeRowsPerPage(this.state.rowsPerPage))
    }

    changePage = (event, page) => {
        let currentFrom = page * this.state.rowsPerPage
        this.props.changePageHandler(currentFrom)

        this.setState({ page })
    }

    render() {
        return (
            <div>
                <TablePagination
                    rowsPerPageOptions={10}
                    component="div"
                    count={this.props.count}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page'
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page'
                    }}
                    onChangePage={this.changePage}
                    onChangeRowsPerPage={this.changeRowsPerPage}
                ></TablePagination>
            </div>

        )
    }
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n,
})

const mapDispatchToProps = dispatch => ({
})

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(Pagination))