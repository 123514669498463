import React, { useState } from 'react'
import { withStyles, Button, TextField, InputAdornment } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'

let Header = props => {
    let { classes } = props
    let [search, setSearch] = useState('')

    let onInputSearch = text => {
        setSearch(text)
        props.onSearch(text)
    }

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                {props.title}
            </div>
            <div className={classes.actions}>
                <div className={classes.search}>
                    {!props.simple && <TextField
                        className={classes.searchInput}
                        value={search}
                        onChange={event => onInputSearch(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon className={classes.searchIcon} color="secondary" />
                                </InputAdornment>
                            ),
                            classes: {
                                underline: classes.removeUnderline
                            }
                        }}
                    />}
                </div>
                {(!props.simple || props.button) && <div>
                    <Button className={classes.button} onClick={props.onClick}>
                        {props.buttonText}
                    </Button>
                </div>}
            </div>
        </div>
    )
}


let styles = theme => ({
    removeUnderline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
        "&&&&:hover:before": {
            borderBottom: "none"
        }
    },
    searchInput: {
        width: '250px',
        marginRight: '27px',
        border: '1px solid rgba(0,0,0,0.15)',
        padding: '0px 6px',
        backgroundColor: 'white'
    },
    searchInputUnderline: {
        '&:after': {
            borderBottom: '2px solid ' + theme.palette.redColor,
        }
    },
    searchIcon: {
        fontSize: '21px'
    },
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#f7f8fa',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    title: {
        fontSize: 30,
        color: '#606771',
        fontWeight: 300,
        paddingLeft: 35
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        width: '100%',
        paddingRight: 35
    },
    button: {
        backgroundColor: '#00458B',
        boxShadow: '0 0.5px 0.5px 0 rgba(22, 29, 37, 0.05), inset 0 1px 0 0 rgba(255, 255, 255, 0.05)',
        color: 'white',
        fontWeight: 300,
        '&:hover': {
            backgroundColor: '#00458B',
            opacity: 0.75
        }
    },
    search: {
        paddingRight: 15
    }
})

export default withStyles(styles)(Header)