import axios from '../../utils/axios'
import jwtDecode from 'jwt-decode';
import * as TYPES from '../actionTypes'

export const login = (email, password) => dispatch => axios.post('/auth', {
    email,
    password
}).then(response => {
    localStorage.setItem("token", response.data.token)
    dispatch({
        type: TYPES.IS_LOGGED,
        payload: {
            username: `${response.data.user.firstName} ${response.data.user.lastName}`,
            email: response.data.user.email,
            _id: response.data.user._id,
            role: response.data.user.role,
            profileImagePath: response.data.user.profileImage ? response.data.user.profileImage.path : null
        }
    })
    return Promise.resolve(response);
})

export const isLogged = () => dispatch => axios.get('/isLogged').then(() => {
    var loggedUser = jwtDecode(localStorage.getItem("token"))
    if (loggedUser) {
        dispatch({
            type: TYPES.IS_LOGGED,
            payload: {
                username: `${loggedUser.firstName} ${loggedUser.lastName}`,
                email: loggedUser.email,
                _id: loggedUser._id,
                role: loggedUser.role,
                profileImagePath: loggedUser.profileImagePath ? loggedUser.profileImagePath : null
            }
        })
    }
})

export const recover = email => dispatch => axios.put('/auth/recover', { email })

export const logout = () => dispatch => {
    localStorage.removeItem("token")
    dispatch({
        type: TYPES.IS_NOT_LOGGED
    })
}