import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'

import SimpleModal from '../../../common/SimpleModal'
import InputGenerator from '../../../common/InputGenerator'

import * as CONSTANTS from '../../../../utils/constants'
import * as USER_REQUESTS from '../../../../redux/actions/users'
import * as NOTIFICATION from '../../../../utils/notification'

const styles = theme => ({
    inputContainer: {
        paddingTop: '10px'
    }
})

class AddUsers extends Component {

    initialFields = [
        { value: '', type: 'text', label: this.props.language.labels.name, name: 'firstName' },
        { value: '', type: 'text', label: this.props.language.labels.midName, name: 'lastName' },
        { value: '', type: 'text', label: this.props.language.labels.title, name: 'title' },
        { value: '', type: 'text', label: this.props.language.labels.email, name: 'email' },
        { value: '', type: 'text', label: this.props.language.labels.phone, name: 'phoneNumber' },
        { value: '', type: 'password', label: this.props.language.labels.password, name: 'password' },
        { value: '', type: 'password', label: this.props.language.labels.confirmPassword, name: 'confirmPassword' },
        {
            type: 'dropdownSelector',
            name: 'role',
            utils: this.props.language.labels.role,
            value: CONSTANTS.ROLES.USER,
            options: Object.values(CONSTANTS.ROLES).map(role => ({ name: role, value: false, label: role })).filter(option => option.name === 'user' || option.name === 'fm'),
        },
        {
            type: 'dropdownSelector',
            name: 'company',
            utils: this.props.language.labels.company,
            defaultValue: '',
            value: '',
            options: [],
        },
    ]

    state = {
        openModal: false,
        renderPage: false,
        modalFields: this.initialFields,
        checkedUsers: []
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.open !== nextProps.open)
            this.populateCompany(nextProps.open)
    }

    onCancelHandler = () => {
        this.setState({ modalFields: this.initialFields }, () => {
            this.props.getCompany()
            this.props.close()
        })
    }

    populateCompany = isOpen => {
        let modalFieldsCopy = this.state.modalFields.map(field => ({ ...field }))
        let companyIndex = modalFieldsCopy.findIndex(index => index.name === 'company')
        if (companyIndex > -1) {
            // Populate current company
            modalFieldsCopy[companyIndex].value = { name: this.props.company._id, value: true, label: this.props.company.name }
            modalFieldsCopy[companyIndex].defaultValue = { name: this.props.company._id, value: true, label: this.props.company.name }
            modalFieldsCopy[companyIndex].options = [{ name: this.props.company._id, value: true, label: this.props.company.name }]
            this.setState({ modalFields: modalFieldsCopy, renderPage: true, openModal: isOpen })
        }
    }

    onAddUser = () => {
        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            if (field.name === 'company') {
                jsonMap[field.name] = field.value.name
            }
            else jsonMap[field.name] = field.value
        })

        this.props.createUser({ ...jsonMap, buildingId: this.props.building._id }).then(() => {
            NOTIFICATION.success(this.props.language.toastr.user.successCreate)
            this.setState({ modalFields: this.initialFields }, () => {
                this.props.getCompany()
                this.onCancelHandler()
            })
        })
            .catch(() => {
                NOTIFICATION.error(this.props.language.toastr.user.errorCreate)
            })

    }

    onChange = event => {
        let modalFieldsCopy = this.state.modalFields.map(field => ({ ...field }))
        let currentIndex = modalFieldsCopy.findIndex(index => index.name === event.target.name)
        if (currentIndex > -1) {
            modalFieldsCopy[currentIndex].value = event.target.value
            this.setState({ modalFields: modalFieldsCopy })
        }
    }

    InputWrapper = ({ disabled, input, key, shrink, onChange }) => <div>
        <InputGenerator
            key={key}
            disabled={disabled}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            {...input}
            onChange={event => onChange ? onChange(event) : this.onChange(event)}
        />
    </div>

    renderFields = () => {
        const InputWrapper = this.InputWrapper
        let jsonMap = {}
        this.state.modalFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (

            <div>
                <div className={this.props.classes.inputContainer}>
                    <InputWrapper shrink={true} input={jsonMap['firstName']} key={'firstName'} />
                </div>
                <div className={this.props.classes.inputContainer}>
                    <InputWrapper shrink={true} input={jsonMap['lastName']} key={'lastName'} />
                </div>
                <div className={this.props.classes.inputContainer}>
                    <InputWrapper shrink={true} input={jsonMap['title']} key={'title'} />
                </div>
                <div className={this.props.classes.inputContainer}>
                    <InputWrapper shrink={true} input={jsonMap['phoneNumber']} key={'phoneNumber'} />
                </div>
                <div className={this.props.classes.inputContainer}>
                    <InputWrapper shrink={true} input={jsonMap['email']} key={'email'} />
                </div>
                <div className={this.props.classes.inputContainer}>
                    <InputWrapper shrink={true} input={jsonMap['role']} key={'role'} />
                </div>
                <div className={this.props.classes.inputContainer}>
                    <InputWrapper disabled={true} shrink={true} input={jsonMap['company']} key={'company'} />
                </div>
            </div>
        )
    }

    render() {
        if (this.state.renderPage) {
            return (
                <div>
                    <SimpleModal
                        open={this.state.openModal}
                        acceptButtonText={this.props.language.buttons.add}
                        cancelButtonText={this.props.language.buttons.cancel}
                        title={this.props.language.titles.createUser}
                        maxWidth={'sm'}
                        onCancel={() => this.onCancelHandler()}
                        onAccept={() => this.onAddUser()}
                    >
                        {this.renderFields()}
                    </SimpleModal>
                </div>
            )
        }
        else return null
    }

}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    createUser: user => dispatch(USER_REQUESTS.createAccountFromCompany({ user })),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddUsers))