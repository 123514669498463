import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'

import Editable from './editable/Editable'
import Comments from './comments/Comments'

import * as TICKET_REQUESTS from '../../../redux/actions/tickets'


const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    editableContainer: {
        height: '100%',
        flex: 1,
        borderRight: '1px solid rgba(0,0,0,0.15)'
    },
    commentsContainer: {
        height: '100%',
        flex: 3
    }
})

class TicketDetails extends Component {

    state = {
        ticket: null
    }

    componentDidMount() {
        this.getTicket()
    }

    getTicket = () => {
        return this.props.getById(this.props.match.params.ID).then(result => {
            this.setState({ ticket: result })
            
            return true
        })
    }

    render() {
        let { classes } = this.props
        return (
            <div className={classes.container}>
                <div className={classes.editableContainer}>
                    {this.state.ticket && <Editable ticketId={this.props.match.params.ID} ticket={this.state.ticket} getTicket={() => this.getTicket()} />}
                </div>
                <div className={classes.commentsContainer}>
                    {this.state.ticket && <Comments ticketId={this.props.match.params.ID} ticket={this.state.ticket} getTicket={() => this.getTicket()} />}
                </div>
            </div>
        )
    }
}


const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
})

const mapDispatchToProps = dispatch => ({
    getById: id => dispatch(TICKET_REQUESTS.getById(id)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TicketDetails))