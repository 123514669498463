import React, { useState, useEffect } from 'react'
import { FormControl, InputLabel, Select, withStyles } from '@material-ui/core'

let selector = props => {
    let { classes } = props
    let [value, setValue] = useState(null)

    useEffect(() => {
        if (props.value) setValue(props.value)
    }, [props.value])

    let onInputChange = event => {
        setValue(event.target.value)
        props.onChange(event)
    }

    return (
        <FormControl className={classes.container}>
            <InputLabel htmlFor="age-native-simple">{props.label}</InputLabel>
            <Select
                disabled={props.disabled}
                native
                name={props.name}
                value={value || 0}
                onChange={onInputChange}
            >
                <option aria-label="None" value="" />
                {props.options.map(option => <option key={option.value} value={option.value}>{option.name}</option>)}
            </Select>
        </FormControl>

    )
}

let styles = () => ({
    container: {
        width: '100%',
        height: '100%'
    }
})

export default withStyles(styles)(selector)