import * as TYPES from '../actionTypes'

const initialState = {
    isLogged: false,
    username: '',
    email: '',
    _id: '',
    role: '',
    profileImagePath: ''
}

function loginReducer(state = initialState, action) {
    switch (action.type) {
        case TYPES.IS_LOGGED:
            return {
                ...state,
                isLogged: true,
                username: action.payload.username,
                email: action.payload.email,
                _id: action.payload._id,
                role: action.payload.role,
                profileImagePath: action.payload.profileImagePath
            }
        case TYPES.LOGIN:
            return {
                ...state,
                isLogged: true,
                username: action.payload.username,
                email: action.payload.email,
                _id: action.payload._id,
                role: action.payload.role,
                profileImagePath: action.payload.profileImagePath
            }
        case TYPES.IS_NOT_LOGGED:
            return { ...state, isLogged: false }
        default:
            return state
    }
}

export default loginReducer