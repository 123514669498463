import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Header from '../../components/common/Header'
import BuildingCard from './card/Card'
import CreateModal from './create/Create'
import Pagination from '../common/Pagination'

import * as BUILDING_REQUESTS from '../../redux/actions/buildings'
import * as CONSTANTS from '../../utils/constants'

const Buildings = props => {
    let { classes } = props
    let [showCreateModal, setShowCreateModal] = useState(false)
    let [buildings, setBuildings] = useState([])
    let [selectedBuilding, setSelectedBuilding] = useState(null)
    let [count, setCount] = useState(0)
    let [from, setFrom] = useState(0)
    let [rowsPerPage, setRowsPerPage] = useState(CONSTANTS.DEFAULT_BIG_CARD_ROWS_PER_PAGE_VALUE)

    let getBuildings = filter => props.getBuildings({ search: filter, from: from, limit: rowsPerPage })
        .then(result => {
            setBuildings(result.buildings)
            setCount(result.count)
        })

    useEffect(() => {
        getBuildings()

        return () => { }
    }, [selectedBuilding])

    const changeRowsPerPageHandler = rowsPerPage => {
        setRowsPerPage(rowsPerPage)
    }

    const changePageHandler = currentFrom => {
        setFrom(currentFrom)
    }

    useEffect(() => {
        getBuildings()
    }, [rowsPerPage, from])
    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.header}>
                    <Header
                        title={props.language.buildings}
                        buttonText={props.language.create}
                        onSearch={getBuildings}
                        onClick={() => setShowCreateModal(true)}
                    />
                </div>
                <div className={classes.container}>
                    <div className={classes.cardsDisplay}>
                        {buildings.map(building => (
                            <div className={classes.spacing}>
                                <BuildingCard
                                    onClick={() => props.history.push('/buildings/' + building._id)}
                                    image={building.image}
                                    contact={building.contact}
                                    area={building.area}
                                    name={building.name}
                                    propertyManager={building.propertyManager && building.propertyManager.lastName && building.propertyManager.firstName ? `${building.propertyManager.lastName} ${building.propertyManager.firstName.toUpperCase()}` : ''}
                                    owner={building.owner && building.owner.lastName && building.owner.firstName ? `${building.owner.lastName} ${building.owner.firstName.toUpperCase()}` : '-'}
                                    facilityManager={building.facilityManager && building.facilityManager.lastName && building.facilityManager.firstName ? `${building.facilityManager.lastName} ${building.facilityManager.firstName.toUpperCase()}` : ''}
                                    language={props.language}
                                />
                            </div>
                        ))}
                    </div>

                    <div>
                        <Pagination
                            count={count}
                            rowsPerPage={rowsPerPage}
                            changeRowsPerPage={changeRowsPerPageHandler}
                            changePageHandler={changePageHandler}
                        />
                    </div>
                </div >


            </div>
            <CreateModal
                open={showCreateModal}
                building={selectedBuilding}
                onAccept={() => {
                    setShowCreateModal(false)
                    setSelectedBuilding(null)
                    getBuildings()
                }}
                onCancel={() => {
                    setShowCreateModal(false)
                    setSelectedBuilding(null)
                }}
            />
        </>
    )
}
const styles = theme => ({
    header: {
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        height: 70
    },
    wrapper: {
        width: 'calc(100% - 216px)',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto'
    },
    line: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    spacing: {
        margin: 10,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        width: '400px',
        maxWidth: '400px'
    },
    cardsDisplay: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row'
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getBuildings: options => dispatch(BUILDING_REQUESTS.get(options)),
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Buildings)))