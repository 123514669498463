import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Tooltip, CircularProgress } from '@material-ui/core'
import { ErrorOutline as InformIcon } from '@material-ui/icons'

import SimpleModal from '../../common/SimpleModal'
import InputGenerator from '../../common/InputGenerator'
import AnafButton from '../../common/AnafButton'

import * as CONSTANTS from '../../../utils/constants'
import * as COMPANY_REQUESTS from '../../../redux/actions/companies'
import * as USERS_REQUESTS from '../../../redux/actions/users'
import * as TAG_REQUESTS from '../../../redux/actions/tags'
import * as BUILDING_REQUESTS from '../../../redux/actions/buildings'
import * as NOTIFICATIONS from '../../../utils/notification'

const styles = theme => ({
    informIcon: {
        color: '#f50057'
    },
    infoText: {
        color: 'rgba(0,0,0,0.5)',
        fontSize: 14,
        margin: 0,
        padding: 0,
        paddingLeft: 24,
        fontWeight: 'bold'
    },
    importantTextContainer: {
        marginTop: 0,
        marginLeft: 4
    },
    importantText: {
        color: '#f50057',
        letterSpacing: 1,
        fontWeight: 'bold',
        fontSize: 12
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    inputField: {
        flex: 1
    },
    paddingRightLeft: {
        paddingRight: 12,
        '&:last-child': {
            paddingLeft: 12
        }
    },
    smallText: {
        fontSize: 11,
        color: '#757575'
    },
    paddingTopButton: {
        padding: '8px 0px'
    },
    circularProgress: {
        color: '#00458B',
        height: '25px !important',
        width: '25px !important',
        paddingRight: 8
    },
    serviceContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
})

class CreateCompanyModal extends Component {

    initialCompanyFields = [
        { value: '', type: 'text', label: this.props.language.labels.name, name: 'name' },
        { value: '', type: 'text', label: this.props.language.labels.mainBusiness, name: 'mainBusiness' },
        { type: 'dropdownSelector', name: 'building', utils: this.props.language.labels.building, value: '', options: [] },
        { type: 'dropdownSelector', name: 'type', utils: this.props.language.labels.type.type, value: '', options: [] },
        { type: 'dropdownSelector', name: 'official', utils: this.props.language.labels.official, value: '', options: [] },
        {
            type: 'fixedMultiSelect',
            utils: this.props.language.labels.service,
            name: 'services',
            options: [],
            value: []
        }
    ]

    state = {
        companyFields: this.initialCompanyFields,
        isContractor: false,
        waitForServices: false,
        cui: ''
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open) {
            this.populateFields()
        }
    }

    modifyCompanyFields = (fields, toEdit, newValues) => {
        const companyCopy = this.state.companyFields.map(field => ({ ...field }))
        fields.forEach((field, position) => {
            const fieldIndex = companyCopy.findIndex(index => index.name === field)
            if (fieldIndex > -1) {
                companyCopy[fieldIndex][toEdit[position]] = newValues[position]
            }
        })

        this.setState({ companyFields: companyCopy })
    }

    getTags = type => this.props.getTags(type)
        .then(data => data)

    getBuildings = () => this.props.getBuildings()
        .then(data => data.buildings)

    getUsers = () => this.props.getUsers()
        .then(data => data.users)

    populateFields = async () => {
        let companyTypes = await this.getTags('company')
        //let servicesTypes = await this.getTags('services')
        let allBuildings = await this.getBuildings()
        let allUsers = await this.getUsers()

        const buildings = [{ name: '', value: false, label: '' }].concat(allBuildings.map(building => ({
            name: building._id,
            value: false,
            label: building.name
        })))

        const users = [{ name: '', value: false, label: '' }].concat(allUsers.map(user => ({
            name: user._id,
            value: false,
            label: `${user.firstName} ${user.lastName}`
        })))

        const types = [{ name: '', value: false, label: '' }].concat([...companyTypes.map(type => ({
            name: type.name,
            value: false,
            label: type.name
        }))])

        // const serviceTypes = [{ name: '', value: false, label: '' }].concat([...servicesTypes.map(type => ({
        //     name: type.name,
        //     value: false,
        //     label: type.name
        // }))])

        this.modifyCompanyFields(['building', 'type', 'official'], ['options', 'options', 'options'], [buildings, types, users])

    }

    InputWrapper = ({ disabled, input, key, shrink, onChange, placeholder }) => <div>
        <InputGenerator
            key={key}
            disabled={disabled}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            placeholder={placeholder ? placeholder : null}
            {...input}
            onChange={event => onChange ? onChange(event) : this.onChangeHandler(event)}
        />
    </div>

    onChangeHandler = async event => {
        const currentIndex = this.state.companyFields.findIndex(index => index.name === event.target.name)

        if (currentIndex > -1) {
            const companyFieldsCopy = this.state.companyFields.map(field => ({ ...field }))

            const buildingIndex = companyFieldsCopy.findIndex(index => index.name === 'building')
            const servicesIndex = companyFieldsCopy.findIndex(index => index.name === 'services')

            if (event.target.name === 'type') {

                if (companyFieldsCopy[buildingIndex].value === '') {
                    return NOTIFICATIONS.error("Trebuie aleasa cladirea!")
                }

                let isContractor = event.target.value.toLocaleLowerCase() === CONSTANTS.COMPANY_TYPES.CONTRACTOR.toLocaleLowerCase()
                if (isContractor) {
                    this.setState({ waitForServices: true }, () => {
                        this.props.getBuildingServices(companyFieldsCopy[buildingIndex].value).then(buildingServices => {
                            const serviceTypes = [{ name: '', value: false, label: '' }].concat([...buildingServices.services.map(service => ({
                                name: service,
                                value: false,
                                label: service
                            }))])
                            companyFieldsCopy[servicesIndex].options = serviceTypes
                            companyFieldsCopy[servicesIndex].defaultValue = serviceTypes.map(service => ({ ...service, value: true })).filter(service => service.name)
                            companyFieldsCopy[servicesIndex].value = serviceTypes.map(service => ({ ...service, value: true })).filter(service => service.name)
                            this.setState({ isContractor: true, waitForServices: false })
                        })
                            .catch(() => {
                                this.setState({ waitForServices: false })
                            })
                    })
                }
                else {
                    this.setState({ isContractor: false })
                }
            }

            companyFieldsCopy[currentIndex].value = event.target.value
            this.setState({ companyFields: companyFieldsCopy })
        }
    }

    onCloseHandler = () => {
        this.props.onClose()
        this.setState({ companyFields: this.initialCompanyFields })
    }

    onAcceptHandler = () => {
        let jsonMap = {}
        this.state.companyFields.forEach(field => {
            jsonMap[field.name] = field
        })

        const companyToAdd = {
            name: jsonMap['name'].value,
            services: this.state.isContractor ? jsonMap['services'].value.map(service => service.name) : null,
            type: jsonMap['type'].value,
            building: jsonMap['building'].value,
            official: jsonMap['official'].value,
            mainBusiness: jsonMap['mainBusiness'].value,
            cui: this.state.cui
        }
        this.props.create({ company: companyToAdd }).then(() => {
            this.props.onClose()
            this.setState({ companyFields: this.initialCompanyFields })
            NOTIFICATIONS.success(this.props.language.toastr.company.successCreate)
        })
            .catch(() => NOTIFICATIONS.error(this.props.language.toastr.company.errorCreate))
    }

    renderFields = () => {
        const InputWrapper = this.InputWrapper
        let { classes } = this.props
        let jsonMap = {}
        this.state.companyFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={this.props.classes.flexColumn}>
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['name']} key={'name'} />
                </div>
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['mainBusiness']} key={'mainBusiness'} />
                </div>
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['building']} key={'building'} />
                </div>
                {<div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['official']} key={'official'} />
                </div>}
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['type']} key={'type'} />
                </div>
                {this.state.isContractor && <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    {this.state.waitForServices ? <div className={this.props.classes.serviceContainer}>
                        <CircularProgress className={this.props.circularProgress} />
                        <span className={this.props.classes.infoText}>Se asteapta serviciile</span>
                    </div> :
                        < InputWrapper disabled={this.state.waitForServices} shrink={true} input={jsonMap['services']} key={'services'} />}
                </div>}
                {this.props.open && <div className={`${this.props.classes.flexRo} ${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <div className={classes.flexRow}>
                        <InformIcon className={classes.informIcon} />
                        <div className={classes.importantTextContainer}>
                            <span className={classes.importantText}>IMPORTANT</span>
                        </div>
                    </div>
                    <p className={classes.infoText}>Reprezentantul companiei se alege din sectiunea de detalii.</p>
                </div>}
            </div>
        )
    }

    onAnafButtonClickedHandler = anafData => {
        let indexOfName = this.state.companyFields.findIndex(field => field.name == 'name')

        if (indexOfName < 0) {
            return
        }

        let newFields = [...this.state.companyFields]

        newFields[indexOfName].value = anafData.denumire || ''

        this.setState({ companyFields: newFields })
    }

    render() {
        const { language } = this.props
        const InputWrapper = this.InputWrapper

        return <>
            <SimpleModal
                open={this.props.open}
                onCancel={() => this.onCloseHandler()}
                onAccept={() => this.onAcceptHandler()}
                acceptButtonText={this.props.language.buttons.create}
                cancelButtonText={this.props.language.buttons.cancel}
                canSubmitForm={true}
                maxWidth={'sm'}
                title={this.props.language.titles.createCompany}
            >
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <InputWrapper
                        placeholder='Introdu cui companie...'
                        input={'cui'}
                        onChange={event => this.setState({ cui: event.target.value })} />
                </div>
                <AnafButton
                    cui={this.state.cui}
                    onAnafButtonClicked={this.onAnafButtonClickedHandler}
                />
                {this.renderFields()}
            </SimpleModal>

        </>
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    create: company => dispatch(COMPANY_REQUESTS.create(company)),
    getTags: type => dispatch(TAG_REQUESTS.getAll({ type })),
    getUsers: () => dispatch(USERS_REQUESTS.get({ all: true })),
    getBuildings: () => dispatch(BUILDING_REQUESTS.get({ all: true })),
    getBuildingServices: (id) => dispatch(BUILDING_REQUESTS.getBuildingServices(id))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreateCompanyModal))