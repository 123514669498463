import React, { useEffect, useState } from 'react'
import { Equalizer, CheckCircle, HourglassFull, Launch, LinearScale } from '@material-ui/icons'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import DailyTicketsBar from './charts/DailyTicketsBar'
import BuildingsPie from './charts/BuildingsPie'
import CriteriaTicketsBar from './charts/CriteriaTicketsBar'

import * as REPORTS_REQUESTS from '../../redux/actions/reports'

let generateIcon = (props, type) => {
    switch (type) {
        case 'all':
            return <Equalizer className={`${props.classes.iconStyle} ${props.classes.red}`} />
        case 'pending':
            return <LinearScale className={`${props.classes.iconStyle} ${props.classes.green}`} />
        case 'verify':
            return <HourglassFull className={`${props.classes.iconStyle} ${props.classes.orange}`} />
        case 'new':
            return <Launch className={`${props.classes.iconStyle}`} />
        case 'closed':
            return <CheckCircle className={`${props.classes.iconStyle}  ${props.classes.green}`} />
        default:
            return null

    }
}

let computeDataChart = (props, data, chartType) => {
    const resultArray = []
    const ticketTypes = [
        { label: props.language.dashboard.ticketsChartStatus.total, field: 'all' },
        { label: props.language.dashboard.ticketsChartStatus.verify, field: 'verify' },
        { label: props.language.dashboard.ticketsChartStatus.new, field: 'neww' },
        { label: props.language.dashboard.ticketsChartStatus.closed, field: 'closed' },
        { label: props.language.dashboard.ticketsChartStatus.pending, field: 'pending' }
    ]

    const ticketPriority = [
        { label: props.language.dashboard.ticketsChartPriority.minor, field: 'minor' },
        { label: props.language.dashboard.ticketsChartPriority.normal, field: 'normal' },
        { label: props.language.dashboard.ticketsChartPriority.urgent, field: 'urgent' }
    ]

    data.forEach(day => {
        const dayJson = {}
        dayJson[props.language.dashboard.day] = day.day
        if (chartType === 'status') {
            ticketTypes.forEach(type => {
                dayJson[type.label] = day.data.statuses[type.field]
                //dayJson[`${type.label}Color`] = type.color
            })
        }

        if (chartType === 'priority') {
            ticketPriority.forEach(type => {
                dayJson[type.label] = day.data.priorities[type.field]
                // dayJson[`${type.label}Color`] = type.color
            })
        }
        resultArray.push(dayJson)
    })
    return resultArray
}

let computeDataBuildings = (props, data) => {
    const resultArray = []

    data.forEach(building => {
        resultArray.push({
            ["id"]: building.field,
            ["label"]: building.field,
            ["value"]: building.value
        })
    })

    return resultArray
}

const dashboard = props => {
    const { classes } = props

    let [ticketsLastDays, setTicketsLastDays] = useState({ pending: 0, verify: 0, closed: 0, new: 0, all: 0 })
    let [dailyTicketsChart, setDailyTicketsChart] = useState([])
    let [buildingTicketsPie, setBuildingTicketsPie] = useState([])
    let [tickets, setTickets] = useState([
        { type: 'all' },
        { type: 'pending' },
        { type: 'verify' },
        { type: 'new' },
        { type: 'closed' }
    ])

    useEffect(() => {
        props.getReports()
            .then(data => {
                setTicketsLastDays({ ...data.ticketsLastDays, new: data.ticketsLastDays.neww })
                setDailyTicketsChart(Object.values(data.linearChart))
                setBuildingTicketsPie(Object.keys(data.buildingTicketsCount).map(key => ({ field: key, value: data.buildingTicketsCount[key] })))
            })
    }, [])

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.header}>
                    <div className={classes.title}>
                        <span>{props.language.titles.dashboard}</span>
                    </div>
                </div>
                <div className={classes.container}>
                    <div className={`${classes.firstRow}`}>
                        {tickets.map(ticket => {
                            return (
                                <div className={`${classes.firstRowChild}`}>
                                    <div className={`${classes.leftColumn}`}>
                                        {ticket.type === 'all' ? <span className={classes.titleText}>{props.language.utils.totalTickets}</span> : <span className={classes.titleText}>Tichete {props.language.dashboard.ticketsChartStatus[ticket.type]}</span>}
                                        <span className={classes.valueText}>{ticketsLastDays[ticket.type]}</span>
                                    </div>
                                    <div className={`${classes.rightColumn}`}>
                                        {generateIcon(props, ticket.type)}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={`${classes.secondRow}`}>
                        <div className={classes.barChart}>
                            <DailyTicketsBar {...props} data={computeDataChart(props, dailyTicketsChart, 'status')} />
                        </div>
                        <div className={`${classes.flexRow}`}>
                            <div className={`${classes.criteriaTicketsBarContainer}`}>
                                <CriteriaTicketsBar {...props} data={computeDataChart(props, dailyTicketsChart, 'priority')} />
                            </div>
                            <div className={`${classes.buildingPieContainer}`}>&gt;
                                <BuildingsPie {...props} data={computeDataBuildings(props, buildingTicketsPie)} />
                            </div>

                        </div>

                    </div>
                </div >
            </div>
        </>
    )
}

const styles = theme => ({
    criteriaTicketsBarContainer: {
        width: '60%',
        height: 400
    },
    buildingPieContainer: {
        width: '40%',
        height: 400
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    buildingPie: {
        marginTop: '-26px',
        width: '100%',
        height: '100%'
    },
    criteriaPie: {
        marginTop: '-34px',
        width: '100%',
        height: '100%'
    },
    dailyChart: {
        marginTop: '-34px',
        width: '100%',
        height: '100%'
    },
    chartTitle: {
        color: '#606771',
        fontSize: 22,
        fontWeight: 300,
        margin: 0,
        padding: '12px 0px 4px 36px'
    },
    header: {
        minHeight: 70,
        width: '100%',
        backgroundColor: '#f7f8fa',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    wrapper: {
        width: 'calc(100% - 216px)',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        overflowY: 'auto',
    },
    title: {
        fontSize: 30,
        color: '#606771',
        fontWeight: 300,
        paddingLeft: 35
    },
    firstRow: {
        display: 'flex',
        flexDirection: 'row',
        padding: '22px 12px 20px 12px'
    },
    firstRowChild: {
        flex: 1,
        margin: '0px 20px',
        height: 60,
        backgroundColor: '#fff',
        border: '#d9d9d9',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        transition: 'all 280ms ease-in-out',
        display: 'flex',
        flexDirection: 'row',
        padding: '12px 18px'
    },
    leftColumn: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    rightColumn: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center'
    },
    valueText: {
        fontSize: 24,
        fontWeight: 500,
        color: '#606771',
        paddingTop: 10
    },
    titleText: {
        color: '#757575 !important',
        fontSize: 12
    },
    iconStyle: {
        fontSize: 30
    },
    red: {
        color: '#f44336'
    },
    green: {
        color: "#4caf50"
    },
    orange: {
        color: '#ff9800'
    },
    secondRow: {
        minHeight: 100,
        maxHeight: 'calc(100% - 130px)',
        display: 'flex',
        flexDirection: 'column'
    },
    barChart: {
        height: 400,
        width: '90%',
        paddingBottom: 30
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getReports: () => dispatch(REPORTS_REQUESTS.get()),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(dashboard))