import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Button, Avatar, CircularProgress } from '@material-ui/core'
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'

import * as CONSTANTS from '../../../../utils/constants'
import * as TICKET_REQUESTS from '../../../../redux/actions/tickets'
import * as NOTIFICATIONS from '../../../../utils/notification'

import AddComment from './AddComment'

import {
    Print as PrintIcon,
    PersonPin as PersonIcon,
    FiberManualRecord as StatusColor
} from '@material-ui/icons'


const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#f7f8fa',
        display: 'flex',
        flexDirection: 'column',
    },
    titleContainer: {
        height: 50,
        minHeight: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 18px 8px 28px',
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    titleText: {
        color: '#606771',
        fontSize: 22,
        fontWeight: '500'
    },
    changeText: {
        color: '#606771',
        fontSize: 18,
        fontWeight: '500'
    },
    madeByText: {
        color: '#606771',
        fontSize: 12,
        fontWeight: '500',
        display: 'flex',
        flexDirection: 'column'
    },
    contentContainer: {
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 8px 54px 8px',
        backgroundColor: '#fff',
        maxHeight: 'calc(100% - 130px)'
    },
    leftAuto: {
        marginLeft: 'auto'
    },
    content: {
        padding: '8px 28px'
    },
    detailsText: {
        fontSize: 14,
        fontWeight: 300,
        color: '#3d4147'
    },
    pointer: {
        cursor: 'pointer'
    },
    icon: {
        fontSize: 32,
        color: '#3d4147'
    },
    extraPadding: {
        padding: '4px 12px 4px 12px'
    },
    extraSize: {
        fontSize: 58
    },
    borderBottom: {
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    equalsFlex: {
        flex: 1
    },
    smallText: {
        color: '#606771',
        fontSize: 12,
        fontWeight: '300'
    },
    paddingBottom: {
        paddingBottom: 4
    },
    paddingTop: {
        paddingTop: 18
    },
    paddingLeft: {
        paddingLeft: 8
    },
    redColor: {
        color: '#ed7669'
    },
    detailsContainer: {
        padding: '18px 28px',
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    commentsContainer: {
        padding: '18px 28px',
        overflow: 'auto'
    },
    commentsTilte: {
        paddingBottom: 18,
        fontWeight: 500,
        fontSize: 16,
        color: '#606771'
    },
    comments: {
        borderBottom: '1px solid rgba(0,0,0,0.15)',
    },
    comment: {
        paddingBottom: 14
    },
    textArea: {
        width: '100%'
    },
    button: {
        backgroundColor: '#00458B',
        boxShadow: '0 0.5px 0.5px 0 rgba(22, 29, 37, 0.05), inset 0 1px 0 0 rgba(255, 255, 255, 0.05)',
        color: 'white',
        fontWeight: 300,
        '&:hover': {
            backgroundColor: '#00458B',
            opacity: 0.75
        }
    },
    ticketContainerWidth: {
        width: '100%'
    },
    replyTitleContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    replyTitle: {
        flex: 1
    },
    statusAndTime: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    buttons: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    buttonElement: {
        marginRight: 10
    },
    ticketChanges: {
        border: '1px solid #c1c1c1',
        padding: '4px 4px 4px 4px',
        boxShadow: '2px 2px 6px 0px #959090'
    },
    ticketReplyChanges: {
        border: '1px solid #c1c1c1',
        padding: '6px 6px 6px 6px',
        boxShadow: '2px 2px 6px 0px #959090',
        marginBottom: '15px'
    },
    roleText: {
        fontSize: '10px'
    },
    commenterPicMargin: {
        marginRight: '6px',
        marginLeft: '6px',
        fontSize: '62px',
        color: '#3d4147',
        width: '44px',
        height: '44px'
    },
    spinnerStyle: {
        height: '35px !important',
        width: '150px !important'
    }
})

class TicketComments extends Component {

    state = {
        comment: '',
        reset: false,
        loading: false,
        loadingClosed: false,
    }

    onCreate = () => {
        this.props.createComment(this.props.ticketId, { comment: this.state.comment, writtenBy: this.props.loginReducer._id, status: this.props.ticket.status, writtenAt: moment() }).then(() => {
            this.props.getTicket()
            this.setState({ comment: '', reset: true })
        })
    }

    setComment = newComment => {
        this.setState({ comment: newComment, reset: false })
    }

    returnField = (value, field) => {
        switch (field) {
            case 'priority': return this.props.language.comments.priority[value]
            case 'status': return this.props.language.comments.status[value]
            case 'type': return this.props.language.comments.type[value] || value.toLowerCase()
            case 'assignee': return value

            default: return value
        }
    }

    returnColor = (status, field) => {
        if (field === 'status') {
            const statusIndex = CONSTANTS.STATUS.findIndex(index => index.name === status)
            if (statusIndex > -1) return CONSTANTS.STATUS[statusIndex].color
            else return 'black'
        }
        else
            return 'black'
    }

    renderStatusButton = () => {
        let { classes } = this.props
        if (!this.props.ticket && !this.props.ticket.status) return

        let changeStatusHandler = status => {
            if (!this.state.comment && !this.state.comment.length) {
                this.setState({ loading: false, loadingClosed: false })
                return NOTIFICATIONS.error(this.props.language.notifications.changeStatusWithoutComment)
            }

            this.props.changeStatus(this.props.ticket._id, status, this.state.comment)
                .then(() => this.props.getTicket())
                .then(() => this.setState({ comment: '', reset: true }))
                .then(() => {
                    this.setState({ loading: false, loadingClosed: false })
                    NOTIFICATIONS.success(this.props.language.notifications.statusChanged)
                })
                .catch(() => {
                    NOTIFICATIONS.error(this.props.language.notifications.errorChangingStatus)
                    this.setState({ loading: false, loadingClosed: false })
                })
        }

        switch (this.props.ticket.status) {
            case CONSTANTS.TICKET_STATUSES.NEW: return <Button
                onClick={() => this.setState({ loading: true }, () => changeStatusHandler(CONSTANTS.TICKET_STATUSES.PENDING))}
                style={{ backgroundColor: CONSTANTS.STATUS_COLORS.PENDING, color: 'white' }}
                className={`${this.state.loading ? classes.spinnerStyle : ''}`}
            >
                {this.state.loading ? <CircularProgress color="inherit" size={20} /> : this.props.language.buttons.move + this.props.language.labels.status[CONSTANTS.TICKET_STATUSES.PENDING]}
            </Button>
            case CONSTANTS.TICKET_STATUSES.PENDING: return <Button
                onClick={() => this.setState({ loading: true }, () => changeStatusHandler(CONSTANTS.TICKET_STATUSES.VERIFY))}
                style={{ backgroundColor: CONSTANTS.STATUS_COLORS.VERIFY, color: 'white' }}
                className={`${this.state.loading ? classes.spinnerStyle : ''}`}
            >
                {this.state.loading ? <CircularProgress color="inherit" size={20} /> : this.props.language.buttons.moveTo + this.props.language.labels.status[CONSTANTS.TICKET_STATUSES.VERIFY]}
            </Button>
            case CONSTANTS.TICKET_STATUSES.VERIFY: return <>
                <Button
                    onClick={() => {
                        this.setState({ loadingClosed: true }, () => changeStatusHandler(CONSTANTS.TICKET_STATUSES.CLOSED))
                    }}
                    style={{ backgroundColor: CONSTANTS.STATUS_COLORS.CLOSED, color: 'white', marginRight: 10 }}
                    className={`${this.state.loadingClosed ? classes.spinnerStyle : ''}`}
                >
                    {this.state.loadingClosed ? <CircularProgress color="inherit" size={20} /> : this.props.language.buttons.moveTo + this.props.language.labels.status[CONSTANTS.TICKET_STATUSES.CLOSED]}
                </Button>
                <Button
                    onClick={() => this.setState({ loading: true }, () => changeStatusHandler(CONSTANTS.TICKET_STATUSES.PENDING))}
                    style={{ backgroundColor: CONSTANTS.STATUS_COLORS.PENDING, color: 'white' }}
                    className={`${this.state.loading ? classes.spinnerStyle : ''}`}
                >
                    {this.state.loading ? <CircularProgress color="inherit" size={20} /> : this.props.language.buttons.move + this.props.language.labels.status[CONSTANTS.TICKET_STATUSES.PENDING]}
                </Button>
            </>

            default: return null
        }
    }

    render() {
        let { classes } = this.props
        const authorProfileImage = this.props.ticket.author.profileImage ? `${CONSTANTS.BACKEND_URL}/static/${this.props.ticket.author.profileImage.path}` : ''
        const loggedUserProfileImage = this.props.loginReducer.profileImagePath ? `${CONSTANTS.BACKEND_URL}/static/${this.props.loginReducer.profileImagePath}` : ''

        return (
            <div className={classes.container}>
                <div className={classes.titleContainer}>
                    {this.props.language.labels.title}: {this.props.ticket ? this.props.ticket.subject : ''}
                    <PrintIcon className={`${classes.icon} ${classes.leftAuto} ${classes.pointer}`} />
                </div>
                <div className={classes.contentContainer}>
                    <div className={`${classes.commentsContainer} ${classes.flexColumn}`}>
                        <span className={`${classes.commentsTilte}`}>{this.props.language.comments.actions}: {this.props.ticket.comments.length}</span>
                        <div className={classes.comments}>
                            {this.props.ticket.comments.map(comment => {
                                const commenterProfileImage = comment.writtenBy.profileImage ? `${CONSTANTS.BACKEND_URL}/static/${comment.writtenBy.profileImage.path}` : ''
                                return (
                                    <div>
                                        {/* <StatusColor></StatusColor> */}
                                        {!comment.statusChange ?
                                            <div className={`${classes.flexRow} ${classes.comment} ${classes.ticketContainerWidth}`}>

                                                {commenterProfileImage ? <Avatar aria-label="recipe" src={commenterProfileImage} className={`${classes.commenterPicMargin}`} /> : <PersonIcon className={`${classes.icon} ${classes.extraSize}`} />}
                                                <div className={`${classes.flexColumn} ${classes.paddingLeft} ${classes.ticketContainerWidth} ${classes.ticketReplyChanges}`}>
                                                    <div className={classes.replyTitleContainer}>
                                                        <span className={`${classes.titleText} ${classes.paddingBottom} ${classes.replyTitle}`}>{this.props.language.utils.reply}: {this.props.ticket.subject}</span>
                                                        <div className={classes.statusAndTime}>
                                                            <span style={{ color: this.returnColor(comment.status, 'status') }}>{this.props.language.labels.status[comment.status]}</span>
                                                            <span className={`${classes.smallText} ${classes.paddingBottom}`}>{moment(comment.writtenAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT)}</span>
                                                        </div>

                                                    </div>
                                                    <span className={`${classes.detailsText} ${classes.paddingBottom} ${classes.redColor}`}>{`${comment.writtenBy.firstName} ${comment.writtenBy.lastName} <${comment.writtenBy.email}>`}</span>
                                                    <span className={`${classes.detailsText} ${classes.paddingTop}`}><div>{ReactHtmlParser(comment.comment)}</div></span>
                                                </div>
                                            </div>
                                            :
                                            <div className={`${classes.flexRow} ${classes.comment} ${classes.ticketContainerWidth}`}>
                                                <div className={`${classes.flexColumn} ${classes.paddingLeft} ${classes.ticketContainerWidth} ${classes.ticketChanges}`}>
                                                    <div className={classes.replyTitleContainer}>
                                                        <span className={`${classes.changeText} ${classes.paddingBottom} ${classes.replyTitle}`}>{this.props.language.comments.ticketChange}
                                                            <span>{this.props.language.comments.change[comment.field]}
                                                            </span>{this.props.language.comments.from}
                                                            <span style={{ color: this.returnColor(comment.changedFrom, comment.field) }}>
                                                                {this.returnField(comment.changedFrom, comment.field)}
                                                            </span>
                                                            {this.props.language.comments.in}
                                                            <span style={{ color: this.returnColor(comment.comment, comment.field) }}>
                                                                {this.returnField(comment.comment, comment.field)}
                                                            </span>
                                                        </span>
                                                        <div className={classes.statusAndTime}>
                                                            <span style={{ color: this.returnColor(comment.status, 'status') }}>{this.props.language.labels.status[comment.status]}</span>
                                                            <span className={`${classes.smallText} ${classes.paddingBottom}`}>{moment(comment.writtenAt).format(CONSTANTS.DEFAULT_DATE_TIME_FORMAT)}</span>
                                                        </div>
                                                    </div>
                                                    <span className={`${classes.madeByText} ${classes.paddingBottom}`}>
                                                        <span>{this.props.language.comments.ticketAuthor}{`${comment.writtenBy.firstName} ${comment.writtenBy.lastName}`}</span>
                                                        <span className={classes.roleText}>{`${comment.writtenBy.role}`}</span>
                                                    </span>
                                                </div>
                                            </div>}
                                    </div>


                                )
                            })}
                        </div>
                        {this.props.ticket && this.props.ticket.status && !(this.props.ticket.status == CONSTANTS.TICKET_STATUSES.CLOSED) && <div className={`${classes.postComment} ${classes.paddingTop} ${classes.equalsFlex} ${classes.flexRow}`}>
                            <div>
                                {loggedUserProfileImage ? <Avatar aria-label="recipe" src={loggedUserProfileImage} className={`${classes.icon} ${classes.extraSize}`} /> : <PersonIcon className={`${classes.icon} ${classes.extraSize}`} />}
                            </div>
                            <div className={`${classes.paddingLeft} ${classes.equalsFlex} ${classes.flexColumn}`}>
                                <AddComment setComment={newComment => this.setComment(newComment)} reset={this.state.reset} />
                                <div className={`${classes.paddingTop} ${classes.leftAuto} ${classes.buttons}`}>
                                    <div className={classes.buttonElement}>
                                        <Button onClick={() => this.onCreate()} color="primary" className={`${classes.leftAuto} ${classes.button}`}>{this.props.language.buttons.comment}</Button>
                                    </div>

                                    {this.props.loginReducer.role != 'user' && <div className={classes.buttonElement}>
                                        {this.renderStatusButton()}
                                    </div>}
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    createComment: (ticketId, comment) => dispatch(TICKET_REQUESTS.createComment(ticketId, comment)),
    changeStatus: (id, status, comment) => dispatch(TICKET_REQUESTS.update(id, { status, commentToAdd: comment }))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TicketComments))