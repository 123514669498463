import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import List from '../../../common/SimpleTable'
import * as COMPANIE_REQUESTS from '../../../../redux/actions/companies'
import * as CONSTANTS from '../../../../utils/constants'

let Companies = props => {
    let [count, setCount] = useState(0)
    let [rowsPerPage, setRowsPerPage] = useState(CONSTANTS.DEFAULT_MINIMODAL_ROWS_PER_PAGE_VALUE)
    let [companies, setCompanies] = useState([])
    let [currentFrom, setCurrentFrom] = useState(0)

    useEffect(() => {
        getCompanies()
    }, [props.buildingId])

    let getCompanies = options => props.getCompanies({ building: props.buildingId, ...options })
        .then(({ companies, count }) => {
            setCompanies(companies.map(company => ({
                _id: company._id,
                id: company._id.slice(-4),
                name: company.name ? company.name : '-',
                type: company.type ? company.type : '-',
                mainBusiness: company.mainBusiness ? company.mainBusiness : '-',
                usersCount: company.users && company.users.length ? company.users.length : '-',
            })))
            setCount(count)
        })


    let changeRowsPerPageHandler = rowsPerPage => {
        setRowsPerPage(rowsPerPage)
        getCompanies({ from: currentFrom, limit: rowsPerPage })
    }

    let changePageHandler = currentFrom => {
        setCurrentFrom(currentFrom)
        getCompanies({ from: currentFrom, limit: rowsPerPage })
    }

    let onSearchHandler = text => getCompanies({ search: text })

    return (
        <List
            title={props.language.titles.companies}
            header={props.language.titles.companies}
            rows={companies}
            header={props.language.tableHeaders.buildingCompanies}
            onClickRow={item => { props.history.push('/companies/' + item._id) }}
            overflow={true}
            headerButton={{
            }}
            disableBorders
            search={{
                onSearch: text => onSearchHandler(text)
            }}
            actions={[
                {

                }
            ]}
        // count={count}
        // rowsPerPage={rowsPerPage}
        // changeRowsPerPage={changeRowsPerPageHandler}
        // changePageHandler={changePageHandler}
        />
    )
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getCompanies: options => dispatch(COMPANIE_REQUESTS.get({ ...options }))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Companies))