export const PRIORITY = [
    { name: 'minor', value: false, color: '#6fbf73' },
    { name: 'normal', value: false, color: '#64b5f6' },
    { name: 'urgent', value: false, color: '#ef5350' }
]

export const STATUS_COLORS = {
    PENDING: '#ffb74d',
    CLOSED: '#2e7d32',
    VERIFY: '#1976d2',
    NEW: '#ef5350'
}

export const STATUS = [
    { name: 'pending', value: false, color: '#ffb74d' },
    { name: 'closed', value: false, color: '#2e7d32' },
    { name: 'verify', value: false, color: '#1976d2' },
    { name: 'new', value: false, color: '#ef5350' }
]

export const PM = 'pm'
export const FM = 'fm'
export const USER = 'user'
export const ADMIN = 'admin'

export const SERVICE_FIELD = 'services'
export const PRIORITY_FIELD = 'priority'
export const BUILDING_FIELD = 'building'
export const TYPE_FIELD = 'type'
export const USER_FIELD = 'official'
export const PHOTO = 'photo'

export const DEFAULT_ROWS_PER_PAGE = [5, 10, 15, 25, 50]
export const DEFAULT_ROWS_PER_PAGE_VALUE = 10
export const DEFAULT_CARD_ROWS_PER_PAGE_VALUE = 8
export const DEFAULT_BIG_CARD_ROWS_PER_PAGE_VALUE = 9
export const DEFAULT_MINIMODAL_ROWS_PER_PAGE_VALUE = 4

export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss'
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY'
export const INPUT_TYPE_DATE_FORMAT = 'YYYY-MM-DD'

export const BACKEND_URL = 'http://helpdesk.502.ro:9000'

export const TYPE_TEXT = 'text'
export const TYPE_NUMBER = 'number'
export const TYPE_OBJECT = 'object'

export const TICKET_STATUSES = {
    NEW: 'new',
    PENDING: 'pending',
    VERIFY: 'verify',
    CLOSED: 'closed'
}

export const TICKET_TYPES = {
    SOLICITATION: 'solicitation',
    NOTIFICATION: 'notification'
}

export const COMPANY_TYPES = {
    CONTRACTOR: 'contractor',
    TENANT: 'tenant',
    OWNER: 'owner',
    MANAGING: 'managing'
}

export const TICKET_PRIORITIES = {
    MINOR: 'minor',
    NORMAL: 'normal',
    URGENT: 'urgent'
}

export const GROUP_TYPES = {
    CUSTOMER: 'customer',
    SUPPORT: 'support'
}

export const ROLES = {
    PM: 'pm',
    FM: 'fm',
    USER: 'user',
    ADMIN: 'admin'
}

export const DATE_FORMAT = 'MM-DD-YYYY'

export const primaryColor = '#3e3f42'
export const primaryColorHover = '#6b6c6f'
export const secondaryColor = '#1665d8'
export const whiteColor = '#ffffff'
export const mainTextColor = '#ffffff'
export const blueColor = '#1665d8'
export const greenColor = '#34aa44'
export const redColor = '#e6492d'
