import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table, TableCell, TableHead, TableRow, TableBody, Typography, InputAdornment, Tooltip, IconButton, Paper, Toolbar, TextField, TablePagination, withStyles, Button } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'

import * as CONSTANTS from '../../utils/constants'
import HtmlTooltip from '../common/HtmlTooltip'

const style = theme => ({
    tableBackground: {
        backgroundColor: theme.palette.common.white
    },
    tableContainer: {
        borderCollapse: 'unset',
        width: '100%'
    },
    tableHead: {
        backgroundColor: '#f7f8fa',
        padding: 4,
        position: 'sticky',
        top: '0'
    },
    tableToolbar: {
        minHeight: 58,
        backgroundColor: '#f7f8fa',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        padding: "4px 0px 4px 17px"
    },
    spacer: {
        flex: '1 1 100%'
    },
    searchIcon: {
        fontSize: '21px'
    },
    removeUnderline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
        "&&&&:hover:before": {
            borderBottom: "none"
        }
    },
    searchInput: {
        width: '250px',
        marginRight: '27px',
        border: '1px solid rgba(0,0,0,0.15)',
        padding: '0px 6px',
        backgroundColor: 'white'
    },
    searchInputUnderline: {
        '&:after': {
            borderBottom: '2px solid ' + theme.palette.redColor,
        }
    },
    tableTh: {
        padding: '4px 0px 4px 21px',
        '&:nth-of-type(1)': {
            width: '20px',
            padding: '4px 0px 0px 25px'
        }
    },
    tableTd: {
        padding: '4px 10px 4px 15px',
        '&:nth-of-type(1)': {
            width: '20px',
            padding: '4px 0px 0px 25px'
        }
    },
    tableActionsTh: {
        textAlign: 'center',
        width: '100px'
    },
    tableActionsTd: {
        margin: 'auto'
    },
    tableWrapperFullWidth: {
        width: '100%',
        minHeight: '100%'
    },
    disableBorders: {
        boxShadow: 'none'
    },
    disableToolbarPadding: {
        paddingLeft: '0px'
    },
    clickableRow: {
        cursor: 'pointer',
    },
    tablePaper: {
        minHeight: '100%'
    },
    titleContainer: {
    },
    titleText: {
        color: '#606771',
        fontSize: 30,
        fontWeight: 300
    },
    actionTableCell: {
        width: 'auto',
        position: 'relative',
        margin: 'auto',
        height: '50px',
        textAlign: 'center'
    },
    displayRight: {
        marginLeft: 'auto',
        display: 'flex'
    },
    squareStatus: {
        borderRadius: 2,
        color: 'white',
        fontWidth: 'bold',
        fontSize: 14,
        textAlign: 'center'
    },
    imagePicture: {
        borderRadius: '50%',
        width: '35px',
        height: '35px',
        backgroundSize: 'cover',
        marginTop: 5,
        marginRight: '20px'
    },
    statusButton: {
        maxHeight: '30px',
        width: 'max-content',
        width: '90px'
    },
    tableListOverflow: {
        overflowY: 'scroll',
        maxHeight: '578px'
    }
})

class SimpleTable extends Component {
    state = {
        searchInput: '',
        page: 0,
        rowsPerPage: this.props.rowsPerPage,
        overflow: this.props.overflow
    }


    changeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value }, () => this.props.changeRowsPerPage(this.state.rowsPerPage))
    }

    changePage = (event, page) => {
        let currentFrom = page * this.state.rowsPerPage
        this.props.changePageHandler(currentFrom)

        this.setState({ page })
    }

    renderSquare = value => {
        const statusIndex = CONSTANTS.STATUS.findIndex(index => index.name === value)
        if (statusIndex > -1) {
            const statusColor = CONSTANTS.STATUS[statusIndex].color
            return (
                <Tooltip placement={"top"} title={this.props.language.labels.status[value]}>
                    <div className={this.props.classes.squareStatus}>
                        <Button style={{ color: 'white', backgroundColor: statusColor }} className={this.props.classes.statusButton} disabled>
                            {this.props.language.labels.status[value].toLowerCase()}
                        </Button>
                    </div>
                </Tooltip>
            )
        }
    }

    render() {
        return (
            <div className={this.props.classes.tableWrapperFullWidth}>
                <Paper className={`${this.props.tableClass}  ${this.props.disableBorders ? this.props.classes.disableBorders : ''} ${this.props.classes.tablePaper}`}>
                    <Toolbar className={`${this.props.classes.tableToolbar} ${this.props.toolbarClass} ${this.props.disableToolbarPadding ? this.props.classes.disableToolbarPadding : ''}`}>
                        {this.props.title && <Typography variant="div" className={this.props.classes.titleContainer} id="tableTitle">
                            {<span className={this.props.classes.titleText}>{this.props.title}</span>}
                        </Typography>}

                        {this.props.search &&
                            <div className={this.props.classes.displayRight}>

                                {this.props.leftHeaderButton &&
                                    <HtmlTooltip
                                        title={this.props.leftHeaderButton.tooltip}
                                        onClick={this.props.leftHeaderButton.onClick}
                                        color={this.props.leftHeaderButton.color}
                                        tooltip={this.props.leftHeaderButton.tooltip}
                                        icon={this.props.leftHeaderButton.icon}
                                    >
                                    </HtmlTooltip>
                                }

                                <TextField
                                    placeholder={this.props.language.utils.searchText}
                                    className={this.props.classes.searchInput}
                                    value={this.state.searchInput}
                                    onKeyPress={event => event.key === 'Enter' ? this.props.search.onSearch(this.state.searchInput) : () => { }}
                                    onChange={event => this.setState({ searchInput: event.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon className={this.props.classes.searchIcon} color="secondary" />
                                            </InputAdornment>
                                        ),
                                        classes: {
                                            underline: this.props.classes.removeUnderline
                                        }
                                    }}
                                />
                            </div>
                        }
                        <div className={this.props.classes.actions}>
                            {this.props.headerButton &&
                                <Tooltip title={this.props.headerButton.tooltip}>
                                    <div onClick={this.props.headerButton.onClick} color={this.props.headerButton.color || 'secondary'} aria-label={this.props.headerButton.tooltip}>
                                        {this.props.headerButton.icon}
                                    </div>
                                </Tooltip>}
                        </div>
                    </Toolbar>
                    <div className={`${this.props.overflow ? this.props.classes.tableListOverflow : ''}`}>
                        <Table className={`${this.props.disableTableContainer ? null : this.props.classes.tableContainer}`}>
                            <TableHead className={this.props.classes.tableHead}>
                                <TableRow>
                                    {this.props.header.map(value => <TableCell key={value} style={typeof value === 'object' ? value.style : {}} className={this.props.classes.tableTh}>{typeof value === 'object' ? value.val : value}</TableCell>)}
                                    {this.props.actions && <TableCell className={`${this.props.classes.tableTh} ${this.props.classes.tableActionsTh}`}> {this.props.actionsTitle} </TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody  >
                                {this.props.rows.map(row => {
                                    let values = Object.values(row)
                                    let keys = Object.keys(row)
                                    return (
                                        <TableRow
                                            selected={this.props.selectedRow && this.props.selectedRow === row._id ? true : false}
                                            hover={this.props.onClickRow ? true : false}
                                            className={this.props.onClickRow ? this.props.classes.clickableRow : ''}
                                            onClick={event => {
                                                if (event.target.tagName.toLowerCase() !== 'button' && event.target.tagName !== 'path' && event.target.tagName !== 'svg' && typeof this.props.onClickRow === 'function')
                                                    this.props.onClickRow(row)
                                            }} key={row.index}>
                                            {values.map((value, index) => {
                                                if (keys[index].substr(0, 1) !== '_') {
                                                    if (keys[index] === 'status') {
                                                        return <TableCell key={index} className={`${this.props.classes.tableTd}`} style={value.style}>{this.renderSquare(value)}</TableCell>
                                                    }
                                                    if (value && typeof value === 'object') {
                                                        if (value.type === 'image')
                                                            return <TableCell key={index} className={this.props.classes.tableTd} style={value.style}><img src={value.value} className={this.props.classes.imagePicture} alt="not found" /></TableCell>
                                                        return <TableCell key={index} className={this.props.classes.tableTd} style={value.style}>{value.val}</TableCell>
                                                    }
                                                    return <TableCell key={index} className={this.props.classes.tableTd}>{value}</TableCell>
                                                } else return null
                                            })}
                                            {this.props.actions && <TableCell style={{ width: this.props.actionsWidth || this.props.actionTableCell }}>
                                                <div className={this.props.classes.tableActionsTd}>
                                                    {this.props.actions.map(action => {
                                                        if ((action.deleteIf && !action.deleteIf(row)) || !action.deleteIf) {
                                                            return <Tooltip key={action.tooltip} title={action.tooltip} placement='top'>
                                                                <IconButton
                                                                    onClick={event => action.onClick ? action.onClick(event, row) : () => { }}
                                                                    color={action.color || 'primary'}
                                                                    aria-label={action.tooltip || ''}>
                                                                    {action.icon}
                                                                </IconButton>
                                                            </Tooltip>
                                                        } else return null
                                                    })}
                                                </div>
                                            </TableCell>}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    {this.props.changePageHandler && <TablePagination
                        rowsPerPageOptions={10}
                        component="div"
                        count={this.props.count}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page'
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page'
                        }}
                        onChangePage={this.changePage}
                        onChangeRowsPerPage={this.changeRowsPerPage}
                    />}
                </Paper>
            </div>
        )
    }
}

SimpleTable.propTypes = {
    rows: PropTypes.array.isRequired,
    header: PropTypes.array.isRequired,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    actionsTitle: PropTypes.string,
    actions: PropTypes.array,
    headerButton: PropTypes.object.isRequired,
    search: PropTypes.object
}

const mapStateToProps = state => ({
    language: state.languageReducer.i18n,
})

const mapDispatchToProps = dispatch => ({
})

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(SimpleTable))