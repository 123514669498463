import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles, List, ListItem, ListItemAvatar, Avatar, ListItemText, Icon, ListItemIcon } from '@material-ui/core'
import { ArrowForwardIos as ArrowRightIcon, Add as AddIcon, Edit as EditIcon } from '@material-ui/icons'

import * as CONSTANTS from '../../../../utils/constants'
import AddBuilding from './AddBuilding/AddBuilding'
import * as NOTIFICATIONS from '../../../../utils/notification'
import * as COMPANIES_REQUESTS from '../../../../redux/actions/companies'
import * as USERS_REQUESTS from '../../../../redux/actions/users'
import * as TAG_REQUESTS from '../../../../redux/actions/tags'
import Form from '../../../common/FormGenerator'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#f7f8fa',
        display: 'flex',
        flexDirection: 'column',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        maxHeight: 'calc(100% - 130px)'
    },
    listIcon: {
        display: 'flex', justifyContent: 'flex-end'
    },
    listItem: {
        cursor: 'pointer'
    },
    list: {
        height: '100%',
        overflowY: 'auto'
    },
    addListItem: {
        backgroundColor: '#4caf50',
        cursor: 'pointer',
        color: '#fafafa'
    },
    editListItem: {
        backgroundColor: '#757575',
        cursor: 'pointer',
        color: '#fafafa'
    },
    selectedBuilding: {
        borderLeft: '4px solid #00458b'
    }
})


let BuildingsList = props => {
    let { classes } = props
    let [showAddBuildingModal, setShowAddBuildingModal] = React.useState(false)
    let [buildings, setBuildings] = React.useState([])
    let [showEditModal, setShowEditModal] = React.useState(false)
    let [editFields, setEditFields] = React.useState([
        { value: '', type: 'text', label: 'Nume', name: 'name' },
        { value: '', type: 'text', label: 'Cui', name: 'cui' },
        { value: '', type: 'text', label: 'Domeniu de activitate', name: 'mainBusiness' },
        { type: 'dropdownSelector', name: 'type', utils: 'Tip', value: '', options: [] },
        { type: 'dropdownSelector', name: 'official', utils: 'Reprezentant', value: '', options: [] },
        { type: 'fixedMultiSelect', name: 'services', utils: 'Servicii', value: [], options: [] }
    ])

    let editCompany = newCompany => props.editCompany(props.companyId, newCompany)
        .then(() => getCompanyData())
        .then(() => {
            setShowEditModal(false)
            NOTIFICATIONS.success('Companie editata')
        })
        .catch(err => {
            setShowEditModal(false)
            NOTIFICATIONS.error('Eroare editare companie')
        })

    let deleteCompany = () => {
        if (window.confirm(props.language.toastr.company.wishToDeleteCompany)) {
            props.deleteCompany(props.companyId)
                .then(() => NOTIFICATIONS.success(props.language.toastr.company.successDelete))
                .then(() => props.history.push('/companies'))
                .catch(() => NOTIFICATIONS.error(props.language.toastr.company.errorDelete))
        }
        else
            NOTIFICATIONS.success(props.language.toastr.company.errorDelete)
    }

    React.useEffect(() => {
        getCompanyData()
        Promise.all([
            props.getCompany(props.companyId),
            // Populate types
            props.getTags()
                .then(result => {
                    const types = [{ name: '', value: false, label: '' }].concat([...result.map(type => ({
                        name: type.name,
                        value: false,
                        label: type.name
                    }))])

                    let newFields = [...editFields]
                    let indexOfType = newFields.findIndex(field => field.name == 'type')

                    if (indexOfType < 0) return

                    newFields[indexOfType].options = types

                    setEditFields(newFields)

                    return true
                }),
            // Populate possible officials
            props.getUsers()
                .then(({ users }) => {
                    const userOptions = [{ name: '', value: false, label: '' }].concat([...users].map(user => ({
                        name: user._id,
                        value: false,
                        label: `${user.firstName} ${user.lastName}`
                    })))

                    let newFields = [...editFields]
                    let indexOfOfficial = newFields.findIndex(field => field.name == 'official')

                    if (indexOfOfficial < 0) return

                    newFields[indexOfOfficial].options = userOptions

                    setEditFields(newFields)

                    return true
                }),
            props.getServices()
                .then(result => {
                    let newFields = [...editFields]
                    let indexOfTags = newFields.findIndex(field => field.name == 'services')

                    if (indexOfTags < 0) return false

                    newFields[indexOfTags].options = result.map(tag => ({ name: '', label: tag.name, value: false }))

                    setEditFields(newFields)
                }),
        ]).then(result => {
            let company = result[0]
            // Options are populated, now fields can be populated
            let newFields = [...editFields]
            if (company.type && company.type.toLowerCase() != 'contractor') {
                newFields = newFields.filter(field => field.name != 'services')
            }


            newFields.forEach(field => {
                if (field.name == 'official') {
                    field.value = company[field.name] ? company[field.name]._id : ''
                } else if (field.name == 'services') {
                    let mappedServices = company.services.map(service => ({ name: service, label: service, value: true }))
                    field.defaultValue = mappedServices
                    field.value = mappedServices
                } else field.value = company[field.name]
            })

            setEditFields(newFields)
        })

    }, [props.companyId, showEditModal])

    let getCompanyData = () => props.getCompany(props.companyId)
        .then(result => {
            setBuildings(result.buildings.map(companyInBuilding => ({
                ...companyInBuilding,
                name: companyInBuilding.building.name,
                subText: `${props.language.labels.contactPhone} ${companyInBuilding.official ? companyInBuilding.official.phoneNumber : '-'}, ${props.language.labels.email} ${companyInBuilding.official ? companyInBuilding.official.email : '-'}`
            })))
            return true
        })

    let onCancelHandler = () => {
        setShowAddBuildingModal(false)
    }

    let onAddBuildingHandler = data => {
        return props.addBuilding(props.companyId, data)
            .then(() => getCompanyData())
            .then(() => {
                setShowAddBuildingModal(false)
                return NOTIFICATIONS.success(props.language.toastr.building.succesfullyAdded)
            })
            .catch(() => {
                setShowAddBuildingModal(false)
                return NOTIFICATIONS.error(props.language.toastr.building.unsuccesfullyAdded)
            })
    }

    return (
        <>
            <Form
                open={showEditModal}
                title={`Editeaza ${props.company ? props.company.name : ''}`}
                acceptButtonText='Editeaza'
                cancelButtonText='Renunta'
                deleteButtonText='Sterge'
                withCancelButton
                withDeleteButton
                onAccept={editCompany}
                onDelete={deleteCompany}
                fields={editFields}
                maxWidth='xs'
                onCancel={() => setShowEditModal(false)}
            />
            <List className={classes.list}>
                <ListItem className={classes.editListItem} onClick={() => setShowEditModal(true)} >
                    <ListItemText primary="Editeaza companie principala" secondary={props.company ? props.company.name : ''} secondaryTypographyProps={{ style: { color: '#e0e0e0' } }} />
                    <ListItemIcon className={classes.listIcon}>
                        <EditIcon style={{ color: '#fafafa' }} />
                    </ListItemIcon>
                </ListItem>
                <ListItem className={classes.addListItem} onClick={() => setShowAddBuildingModal(true)} >
                    <ListItemText primary="Adauga cladire noua" secondary={`In ${buildings.length} cladiri`} secondaryTypographyProps={{ style: { color: '#e0e0e0' } }} />
                    <ListItemIcon className={classes.listIcon}>
                        <AddIcon style={{ color: '#fafafa' }} />
                    </ListItemIcon>
                </ListItem>
                {buildings.map((building, index) => (
                    <ListItem
                        key={building._id}
                        onClick={() => props.buildingChanged(building._id)}
                        className={`${classes.listItem} ${props.currentBuilding === building._id ? classes.selectedBuilding : ''}`}
                        style={{ backgroundColor: index % 2 == 0 ? '#f7f8fa' : null }}>
                        <ListItemText primary={building.name || ''} secondary={building.subText || ''} />
                        <ListItemIcon className={classes.listIcon}>
                            <ArrowRightIcon />
                        </ListItemIcon>
                    </ListItem>
                ))}
            </List>
            <AddBuilding
                open={showAddBuildingModal}
                onSubmit={data => onAddBuildingHandler(data)}
                onCancel={onCancelHandler}
            />
        </>
    )
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
})

const mapDispatchToProps = dispatch => ({
    addBuilding: (id, building) => dispatch(COMPANIES_REQUESTS.addBuilding(id, building)),
    getCompanies: () => dispatch(COMPANIES_REQUESTS.get({ all: true })),
    getCompany: id => dispatch(COMPANIES_REQUESTS.getById(id)),
    editCompany: (id, newCompany) => dispatch(COMPANIES_REQUESTS.edit(id, newCompany)),
    deleteCompany: id => dispatch(COMPANIES_REQUESTS.remove(id)),
    getServices: () => dispatch(TAG_REQUESTS.getAll({ type: 'services' })),
    getTags: () => dispatch(TAG_REQUESTS.getAll({ type: 'company' })),
    getUsers: () => dispatch(USERS_REQUESTS.get({ all: true })),
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BuildingsList))) 