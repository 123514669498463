import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { RadioGroup, Radio, FormControlLabel, FormHelperText, FormControl } from '@material-ui/core'
import { connect } from 'react-redux'

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: 0
    },
    formGroup: {
        display: 'inline'
    },
    formGroupNoInline: {
        display: 'contents'
    },
    fromHelper: {
        fontSize: 12,
        paddingBottom: 4,
        fontWeight: 'bold'
    },
    paddingStyle: {
        padding: '0px 6px !important'
    }
});

class CheckboxesGroup extends React.Component {
    state = {
        value: this.props.options[0].name || ''
    }

    onChange = event => {
        this.setState({
            value: event.target.value
        })

        this.props.onChange(event.target.value)
    }

    componentDidMount() {
        this.props.onChange(this.state.value)
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormHelperText className={classes.fromHelper}>{this.props.utils.toUpperCase()}</FormHelperText>
                    <RadioGroup
                        onChange={this.onChange}
                        value={this.state.value}
                        className={`${this.props.noInline ? this.props.classes.formGroupNoInline : ""} ${classes.formGroup}`}>
                        {this.props.options.map(util => {
                            return <FormControlLabel
                                key={util.name}
                                control={
                                    <Radio
                                        name={util.name}
                                        checked={util.name === this.state.value}
                                        value={util.name} />
                                }
                                label={<div className={classes.paddingStyle} style={{ backgroundColor: util.color ? util.color : '', color: util.color ? '#fff' : '' }}><span>{util.label}</span></div>} />
                        })}
                    </RadioGroup>
                </FormControl>
            </div >
        );
    }
}

CheckboxesGroup.propTypes = {
    classes: PropTypes.object.isRequired,
    utils: PropTypes.string.isRequired
}


const mapStateToProps = reducers => ({
    languageReducer: reducers.languageReducer.i18n.selector
})

export default withStyles(styles)(connect(mapStateToProps)(CheckboxesGroup))