import * as TYPES from '../actionTypes'

const initialState = {
    create: false
}

function changeLanguage(state = initialState, action) {
    switch (action.type) {
        case TYPES.SET_CREATE:
            return { ...state, create: true }
        case TYPES.UNSET_CREATE:
            return { ...state, create: false }
        default:
            return state
    }
}

export default changeLanguage