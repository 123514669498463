import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CardMedia, Typography, CardContent } from '@material-ui/core'

import CardWrapper from '../../common/CardWrapper'
import { BACKEND_URL } from '../../../utils/constants'

let Card = props => {
    let classes = useStyles()

    const buildingImage = props.image ? `${BACKEND_URL}/static/${props.image.path}` : 'https://via.placeholder.com/150'

    return (
        <CardWrapper onClick={props.onClick} className={classes.overflowCard}>
            <CardMedia
                component="img"
                alt="Building image"
                height="200"
                image={buildingImage}
                title="Building image"
                className={classes.buildingImageStyle}
            />
            <CardContent className={classes.disableBorder}>
                <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                    {props.name || '-'}
                </Typography>

                <Typography gutterBottom color="textSecondary" className={classes.details}>
                    {props.language.labels.propertyOwner} <Typography className={classes.user} color='textPrimary'>{props.owner || '-'}</Typography>
                </Typography>

                <Typography gutterBottom color="textSecondary" className={classes.details}>
                    {props.language.labels.facilityManager} <Typography className={classes.user} color='textPrimary'>{props.facilityManager || '-'}</Typography>
                </Typography>

                <Typography gutterBottom color="textSecondary" className={classes.details}>
                    {props.language.labels.propertyManager} <Typography className={classes.user} color='textPrimary'>{props.propertyManager || '-'}</Typography>
                </Typography>

                <Typography gutterBottom color="textPrimary" className={classes.surfaceTitle || '-'}>
                    {props.language.labels.surfaceDetails}
                </Typography>

                <Typography color="textSecondary" className={classes.details}>
                    {props.language.labels.total} <Typography className={classes.user} color='textPrimary'>{props.area.total || '-'} mp</Typography>
                </Typography>

                <Typography color="textSecondary" className={classes.details}>
                    {props.language.labels.util} <Typography className={classes.user} color='textPrimary'>{props.area.usable || '-'} mp</Typography>
                </Typography>

                <Typography color="textSecondary" className={classes.details}>
                    {props.language.labels.office} <Typography className={classes.user} color='textPrimary'>{props.area.office || '-'} mp</Typography>
                </Typography>

                <Typography color="textSecondary" className={classes.details}>
                    {props.language.labels.commercial} <Typography className={classes.user} color='textPrimary'>{props.area.commercial || '-'} mp</Typography>
                </Typography>

                <Typography gutterBottom color="textPrimary" className={classes.surfaceTitle}>
                    {props.language.labels.contact}
                </Typography>

                <Typography color="textSecondary" className={classes.details}>
                    {props.language.labels.contactPhone} <Typography className={classes.user} color='textPrimary'>{props.contact.phoneNumber || '-'}</Typography>
                </Typography>

                <Typography color="textSecondary" className={classes.details}>
                    {props.language.labels.mail} <Typography className={classes.user} color='textPrimary'>{props.contact.email || '-'}</Typography>
                </Typography>

                <Typography color="textSecondary" className={classes.details} >
                    {props.language.labels.website} <Typography className={classes.user} color='textPrimary'>{props.contact.website ? <a href={props.contact.website} target="blank">{props.contact.website}</a> : '-'}</Typography>
                </Typography>


            </CardContent>
        </CardWrapper>
    )
}

let useStyles = makeStyles({
    disableBorder: {
        borderBottom: 'none',
        overflowY: 'scroll',
        maxHeight: '300px'
    },
    surfaceTitle: {
        paddingTop: 20,
        fontWeight: 'bold',
        color: '#606771'
    },
    title: {
        paddingBottom: 20,
        fontWeight: 'bold',
        color: '#3778C2'
    },
    details: {
        display: 'flex',
        flexDirection: 'row'
    },
    user: {
        paddingLeft: 10,
        fontSize: 15,
        color: 'primary'
    },
    buildingImageStyle: {
        maxHeight: '180px',
        width: '100%',
        objectFit: 'fill'
    },
    overflowCard: {
        overflowY: 'scroll'
    }
})

export default Card