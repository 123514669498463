import axios from '../../utils/axios'
import { errorHandler } from '../../utils/handlers'

const COMPANY_ROUTE = '/companies'

export const get = query => dispatch => axios.get(`${COMPANY_ROUTE}?from=${query.from || ''}&limit=${query.limit || ''}&search=${query.search ? query.search : ''}&building=${query.building ? query.building : ''}&contractor=${query.contractor ? query.contractor : ''}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getById = id => dispatch => axios.get(`${COMPANY_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getAnafData = cui => dispatch => axios.get(`${COMPANY_ROUTE}/anaf/${cui}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const addBuilding = (id, building) => dispatch => axios.put(`${COMPANY_ROUTE}/${id}/buildings`, { building })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const create = data => dispatch => axios.post(COMPANY_ROUTE, { ...data })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const updateCompanyBuilding = (companyId, buildingId, building) => dispatch => axios.put(`${COMPANY_ROUTE}/${companyId}/buildings/${buildingId}`, { building })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const deleteCompanyFromBuilding = (companyId, buildingId) => dispatch => axios.delete(`${COMPANY_ROUTE}/${companyId}/buildings/${buildingId}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getByIdWithBuilding = (companyId, buildingId) => dispatch => axios.get(`${COMPANY_ROUTE}/${companyId}/buildings/${buildingId}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const remove = id => dispatch => axios.delete(`${COMPANY_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const edit = (id, newCompany) => dispatch => axios.put(`${COMPANY_ROUTE}/${id}`, { company: { ...newCompany } })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const uploadImage = (id, images) => dispatch => axios.post(`${COMPANY_ROUTE}/${id}/image`, images)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const uploadFile = (id, buildingId, files) => dispatch => axios.post(`${COMPANY_ROUTE}/${id}/file/building/${buildingId}`, files)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)