import axios from '../../utils/axios'
import * as TYPES from '../actionTypes'
import { errorHandler } from '../../utils/handlers'

const TICKET_ROUTE = '/tickets'

export const get = query => dispatch => axios.get(`${TICKET_ROUTE}?from=${query.from}&limit=${query.limit}&search=${query.search ? query.search : ''}&filter=${query.filter ? query.filter : ''}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getById = id => dispatch => axios.get(`${TICKET_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const update = (id, ticket) => dispatch => axios.put(`${TICKET_ROUTE}/${id}`, { ticket })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const del = id => dispatch => axios.delete(`${TICKET_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const create = data => dispatch => axios.post(TICKET_ROUTE, { ...data })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const createComment = (id, comment) => dispatch => axios.patch(`${TICKET_ROUTE}/${id}/comments`, { comment })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const setCreated = () => dispatch => dispatch({ type: TYPES.SET_CREATE })
export const unsetCreated = () => dispatch => dispatch({ type: TYPES.UNSET_CREATE })