import React, { useState, useEffect } from 'react'
import { withStyles, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { KeyboardArrowRight as RightArrowIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import moment from 'moment'

import Header from '../../common/Header'
import Table from './table/Table'
import * as TAG_REQUESTS from '../../../redux/actions/tags'
import * as CONSTANTS from '../../../utils/constants'
import Create from './create/Create'
import * as NOTIFICATIONS from '../../../utils/notification'

let Drawer = props => {
    let [items, setItems] = useState([
        { name: 'ticket', label: props.language.labels.ticketType },
        { name: 'services', label: props.language.labels.serviceType },
        { name: 'company', label: props.language.labels.companyType }
    ])
    let [selectedIndex, setSelectedIndex] = useState(0)

    let onItemClickedHandler = (index, name) => {
        setSelectedIndex(index)
        props.onChange(name)
    }

    return (
        <div>
            <List>
                {items.map((item, index) => (
                    <ListItem
                        button
                        key={item.name}
                        selected={selectedIndex === index}
                        onClick={() => onItemClickedHandler(index, item.name)}>
                        <ListItemText primary={<p className={props.classes.listItemText}>{item.label}</p>} />
                        <ListItemIcon className={props.classes.listItemIcon}><RightArrowIcon /></ListItemIcon>
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

let Tags = props => {
    let { classes, language } = props
    let [tags, setTags] = useState([])
    let [selectedTag, setSelectedTag] = useState('ticket')
    let [showCreateTag, setShowCreateTag] = useState(false)

    useEffect(() => {
        getTags()
    }, [selectedTag])

    let getTags = () => {
        let deleteTag = tagId => {
            let accepted = window.confirm('Sunteti sigur ca vreti sa stergeti tagul acesta?')

            if (!accepted) return Promise.reject()

            return props.deleteTag(tagId)
                .then(() => getTags())
                .then(() => NOTIFICATIONS.success('Tag-ul sters cu succes'))
                .catch(err => NOTIFICATIONS.error('Eroare stergere tag'))
        }

        return props.getTags(selectedTag)
            .then(tags => {
                setTags(tags.map(tag => ({
                    name: tag.name,
                    createdAt: moment(tag.createdAt).format(CONSTANTS.DEFAULT_DATE_FORMAT),
                    _id: tag.createdBy ? tag.createdBy._id.slice(-6) : '',
                    createdBy: tag.createdBy ? `${tag.createdBy.lastName} ${tag.createdBy.firstName}` : '',
                    onDeleteClicked: () => deleteTag(tag._id)
                })))
            })
    }

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Header
                    title={'Taguri'}
                    simple
                    button
                    buttonText={'Adauga'}
                    onClick={() => setShowCreateTag(true)}
                />
            </div>
            <div className={classes.tagsContainer}>
                <div className={classes.selectTag}>
                    <Drawer
                        classes={classes}
                        language={language}
                        onChange={tag => setSelectedTag(tag)}
                    />
                </div>
                <div className={classes.tagListContaier}>
                    <div className={classes.tagList}>
                        <Table
                            language={language}
                            titlu='titlu'
                            items={tags}
                        />
                    </div>
                </div>
            </div>
            <Create
                open={showCreateTag}
                type={selectedTag}
                acceptButtonText={props.language.buttons.add}
                title={`${props.language.buttons.add} ${props.language.labels[selectedTag]}`}
                onCancel={() => setShowCreateTag(false)}
                onCreate={() => getTags().then(() => setShowCreateTag(false))}
            />
        </div>
    )
}

let styles = () => ({
    listItemIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    listItem: {
    },
    listItemText: {
        fontWeight: 500,
        margin: 0,
        padding: 0
    },
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#f7f8fa'
    },
    header: {
        width: '100%',
        height: 70
    },
    tagsContainer: {
        width: '100%',
        height: 'calc(100% - 70px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectTag: {
        width: '20%',
        height: '100%',
        borderRight: '1px solid rgba(0,0,0,0.15)',
    },
    tagListContaier: {
        width: '80%',
        height: '95%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    tagList: {
        width: '80%',
        height: '90%'
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getTags: type => dispatch(TAG_REQUESTS.getAll({ type })),
    deleteTag: tagId => dispatch(TAG_REQUESTS.remove(tagId))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Tags))