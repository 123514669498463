import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Tooltip } from '@material-ui/core'

import SimpleModal from '../../common/SimpleModal'
import AddComment from '../details/comments/AddComment'
import InputGenerator from '../../common/InputGenerator'

import * as CONSTANTS from '../../../utils/constants'
import * as TICKET_REQUESTS from '../../../redux/actions/tickets'
import * as BUILDING_REQUEST from '../../../redux/actions/buildings'
import * as TAGS_REQUESTS from '../../../redux/actions/tags'
import * as NOTIFICATIONS from '../../../utils/notification'

const styles = theme => ({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    inputField: {
        flex: 1
    },
    paddingRightLeft: {
        paddingRight: 12,
        '&:last-child': {
            paddingLeft: 12
        }
    },
    smallText: {
        fontSize: 11,
        color: '#757575'
    },
    paddingTopButton: {
        padding: '8px 0px'
    }
})

class CreateTicketModal extends Component {

    priorityWithLabels = CONSTANTS.PRIORITY.map(priority => ({ ...priority, label: this.props.language.labels.priority[priority.name] }))

    initialTicketFields = [
        { value: '', type: 'text', label: this.props.language.labels.title, name: 'subject' },
        { type: 'dropdownSelector', name: 'building', utils: this.props.language.labels.building, value: '', options: [] },
        { type: 'dropdownSelector', name: 'type', utils: this.props.language.labels.type.type, value: '', options: [] },
        {
            type: 'dropdownSelector',
            name: 'services',
            utils: this.props.language.labels.service,
            options: [],
            value: ''

        },
        { type: 'multicheckbox', name: 'priority', utils: this.props.language.labels.priority.priority, value: 'normal', options: this.priorityWithLabels },
        //{ value: '', type: 'text', label: this.props.language.labels.description, name: 'description' },
    ]

    state = {
        ticketFields: this.initialTicketFields,
        priority: '',
        comment: '',
        reset: false
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open) {

            this.populateFields()
        }
    }

    modifyTicketFields = (fields, toEdit, newValues) => {
        const ticketsCopy = this.state.ticketFields.map(field => ({ ...field }))
        fields.forEach((field, position) => {
            const fieldIndex = ticketsCopy.findIndex(index => index.name === field)
            if (fieldIndex > -1) {
                ticketsCopy[fieldIndex][toEdit[position]] = newValues[position]
            }
        })

        this.setState({ ticketFields: ticketsCopy })
    }

    getBuildings = options => this.props.getBuildings(options)
        .then(data => data.buildings)

    getServicesTags = () => this.props.getTags('services')
        .then(data => data)

    getTicketTags = () => this.props.getTags('ticket')
        .then(data => data)

    populateFields = async () => {
        // let servicesTags = await this.getServicesTags()
        let ticketTags = await this.getTicketTags()
        let allBuildings = await this.getBuildings()

        // const tagsService = [{ name: '', value: false, label: '' }].concat(servicesTags.map(tag => ({
        //     _id: tag._id,
        //     name: tag.name,
        //     value: false,
        //     label: tag.name
        // })))

        const tagsTicket = [{ name: '', value: false, label: '' }].concat(ticketTags.map(tag => ({
            _id: tag._id,
            name: tag.name,
            value: false,
            label: tag.name
        })))

        const buildings = [{ name: '', value: false, label: '' }].concat(allBuildings.map(group => ({
            name: group._id,
            value: false,
            label: group.name
        })))

        this.modifyTicketFields(['type', 'building'], ['options', 'options'], [tagsTicket, buildings])

    }

    InputWrapper = ({ input, key, shrink, onChange, onFocus }) => <div>
        <InputGenerator
            key={key}
            fullWidth={true}
            InputLabelProps={shrink ? { shrink: true } : {}}
            margin="dense"
            {...input}
            onFocus={event => event.target.name === 'services' ? this.onChangeHandler(event) : () => { }}
            onChange={event => onChange ? onChange(event) : this.onChangeHandler(event)}
        />
    </div>

    onChangeHandler = async event => {
        const currentIndex = this.state.ticketFields.findIndex(index => index.name === event.target.name)

        if (currentIndex > -1) {
            const ticketFieldsCopy = this.state.ticketFields.map(field => ({ ...field }))
            const buildingIndex = ticketFieldsCopy.findIndex(index => index.name === 'building')
            const serviceIndex = ticketFieldsCopy.findIndex(index => index.name === 'services')

            if (event.target.name === 'services') {
                if (ticketFieldsCopy[buildingIndex].value === '')
                    return NOTIFICATIONS.error(this.props.language.toastr.building.chooseBuilding)
            }

            if (event.target.name === 'building') {
                let servicesTags = await this.getServicesTags()
                let building = await this.props.getBuildingServices(event.target.value)

                const tagsService = [{ name: '', value: false, label: '' }].concat([...building.services.map(tag => ({
                    _id: '',
                    name: tag,
                    value: false,
                    label: tag
                }))])
                ticketFieldsCopy[serviceIndex].options = tagsService
            }

            ticketFieldsCopy[currentIndex].value = event.target.value
            this.setState({ ticketFields: ticketFieldsCopy })
        }
    }

    onCloseHandler = () => {
        this.props.creatTicketModalHandler(false)
        this.setState({ ticketFields: this.initialTicketFields })
    }

    onAcceptHandler = () => {
        this.props.creatTicketModalHandler(false)

        let ticket = {}
        this.state.ticketFields.forEach(field => {
            if (field.name === 'priority') {
                return ticket[field.name] = this.state.priority.length ? this.state.priority : null
            }
            ticket[field.name] = field.value
        })
        ticket.author = this.props.loginReducer._id
        ticket.commentInfo = { comment: this.state.comment, writtenBy: this.props.loginReducer._id }

        return this.props.create({ ticket: { ...ticket } })
            .then(() => {
                this.props.setCreated()
                NOTIFICATIONS.success(this.props.language.toastr.ticket.successCreate)
                this.setState({ ticketFields: this.initialTicketFields })
            })
            .catch(() => {
                NOTIFICATIONS.error(this.props.language.toastr.ticket.errorCreate)
                this.setState({ ticketFields: this.initialTicketFields })
            })

    }

    setComment = newComment => {
        this.setState({ comment: newComment, reset: false })
    }

    renderFields = () => {
        const InputWrapper = this.InputWrapper

        let jsonMap = {}
        this.state.ticketFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={this.props.classes.flexColumn}>
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['priority']} key={'priority'} onChange={value => this.setState({ priority: value })} />
                </div>
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['subject']} key={'subject'} />
                </div>
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['building']} key={'building'} />
                </div>
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['type']} key={'type'} />
                </div>
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    <InputWrapper shrink={true} input={jsonMap['services']} key={'services'} />
                </div>
                <div className={`${this.props.classes.inputField} ${this.props.classes.paddingTopButton}`}>
                    {/* <InputWrapper input={jsonMap['description']} key={'description'} /> */}
                    <AddComment setComment={newComment => this.setComment(newComment)} reset={this.state.reset} />
                </div>
                <span className={this.props.classes.smallText}>
                    {this.props.language.createTicketMessage}
                </span>
            </div>
        )
    }

    render() {
        const { language } = this.props

        return <>
            <SimpleModal
                open={this.props.open}
                onCancel={() => this.onCloseHandler()}
                onAccept={() => this.onAcceptHandler()}
                acceptButtonText={this.props.language.buttons.create}
                cancelButtonText={this.props.language.buttons.cancel}
                canSubmitForm={true}
                maxWidth={'sm'}
                title={this.props.language.titles.createTicket}
            >

                {this.renderFields()}
            </SimpleModal>

        </>
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    setCreated: () => dispatch(TICKET_REQUESTS.setCreated()),
    create: data => dispatch(TICKET_REQUESTS.create(data)),
    getBuildings: () => dispatch(BUILDING_REQUEST.get({ all: true })),
    getBuildingServices: id => dispatch(BUILDING_REQUEST.getBuildingServices(id)),
    getTags: type => dispatch(TAGS_REQUESTS.getAll({ type }))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreateTicketModal))