import React, { Component } from 'react'
import { TextField, Button, CircularProgress, withStyles, makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import * as NOTIFICATION from '../../utils/notification'
import * as LOGIN from '../../redux/actions/login'
import Form from '../common/FormGenerator'

import '../../styles/Login.css'

class Login extends Component {
    state = {
        isLoading: false,
        email: '',
        password: '',
        usernameTouched: false,
        passwordTouched: false,
        canSubmit: false,
        showResetModal: false
    }

    login = () => this.props.login(this.state.email, this.state.password)
        .catch(err => {
            if (!err.response) {
                NOTIFICATION.error(this.props.languageReducer.serverDown)
            }
            else {
                NOTIFICATION.error(this.props.languageReducer.invalidCredentials)
                this.setState({ isLoading: false })
            }
        })

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    onResetPasswordHandler = ({ email }) => {
        this.props.recover(email)
            .then(() => {
                NOTIFICATION.success('Parola a fost schimbata si trimisa pe mailul tau')
                alert('Ai primit un email cu noua ta parola, dupa logare te rugam sa iti schimbi parola!')
            })
            .catch(() => NOTIFICATION.error('Eroare resetare parola'))
    }

    render() {
        let { loginForm } = this.props.languageReducer
        let { classes } = this.props

        return (
            <div className='loginContainer'>
                <Form
                    open={this.state.showResetModal}
                    title='Reseteaza parola'
                    acceptButtonText='Reseteaza'
                    cancelButtonText='Renunta'
                    withCancelButton
                    onAccept={this.onResetPasswordHandler}
                    fields={[
                        { value: '', type: 'text', label: 'Adresa email', name: 'email' }
                    ]}
                    maxWidth='xs'
                    onCancel={() => this.setState({ showResetModal: false })}
                />
                <div className='login'>
                    <p>Login</p>
                    <TextField
                        className='loginInput'
                        label={loginForm.email}
                        onChange={event => this.onChange(event)}
                        name='email'
                    />
                    <TextField
                        className='loginInput'
                        label={loginForm.password}
                        type='password'
                        onChange={event => this.onChange(event)}
                        onKeyPress={event => event.key === 'Enter' ? this.login() : null}
                        name='password'
                    />
                    <Button
                        className='loginButton'
                        variant='contained'
                        onClick={() => this.login()} color='primary'>
                        {this.state.isLoading ? <CircularProgress style={{ color: '#ffffff' }} size={24} /> : loginForm.login}
                    </Button>
                    <div
                        className={classes.forgottPassword}
                        onClick={() => this.setState({ showResetModal: true })}
                    >Ai uitat parola? </div>
                </div>
            </div>
        )
    }
}

const styles = {
    forgottPassword: {
        width: '100%',
        '&:hover': {
            cursor: 'pointer'
        }
    }
}

const mapDispatchToProps = dispatch => ({
    login: (username, password) => dispatch(LOGIN.login(username, password)),
    recover: email => dispatch(LOGIN.recover(email))
})

const mapStateToProps = reducers => ({
    languageReducer: reducers.languageReducer.i18n.login
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Login))