import React, { useEffect } from 'react'
import { withStyles, Card, CardContent, CardHeader, Avatar, IconButton, Menu, MenuItem, CardMedia, Tooltip } from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'

import * as CONSTANTS from '../../utils/constants'
import { ClickWrapper } from '../../utils/helpers'
import { BACKEND_URL } from '../../utils/constants'
import * as LANGUAGE from '../../utils/language/RO.json'

const ITEM_HEIGHT = 48

const options = [
    LANGUAGE.buttons.edit,
    LANGUAGE.buttons.delete
]

const Actions = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectOption = option => {
        if (option === LANGUAGE.buttons.delete) {
            handleClose()
            return props.onDelete()
        }
        else {
            handleClose()
            return props.onEdit()
        }

    }

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map(option => (
                    <MenuItem key={option} style={option === LANGUAGE.buttons.delete ? { color: 'red' } : {}} onClick={() => handleSelectOption(option)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div >
    );
}

const card = props => {
    let { classes } = props

    const computeContentValue = content => {
        if (props.item[content.name]) {
            switch (content.type) {
                case CONSTANTS.TYPE_TEXT:
                    return content.name === 'role' ? props.language.labels.userRole[props.item[content.name]] : props.item[content.name]
                case CONSTANTS.TYPE_NUMBER:
                    return props.item[content.name].length
                case CONSTANTS.TYPE_OBJECT:
                    if (content.multiple) {
                        let result = ''
                        content.multiple.forEach(field => {
                            result = result.concat(props.item[content.name][field] + ' ')
                        })
                        return result
                    }
                    return props.item[content.name].map((item, index) => item.name + `${index !== props.item[content.name].length - 1 ? ',' : ''}`)
            }
        }
        return '-'
    }

    const profileImage = props.item.profileImage ? `${BACKEND_URL}/static/${props.item.profileImage.path}` : props.avatarInitials

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    props.logo &&
                    <Avatar aria-label="recipe" src={profileImage}></Avatar>
                }
                action={
                    <IconButton aria-label="settings">
                        <Actions {...props} />
                    </IconButton>
                }
                title={<h2 className={classes.titleText}>{props.title}</h2>}
                subheader={<h4 className={classes.subtitleText}>{props.subtitle}</h4>}
            />
            <ClickWrapper onClick={() => props.onSelect(props.item)} >
                <CardContent className={classes.cardContent}>
                    <h4 className={classes.detailsTitle}>Detalii generale</h4>
                    {props.contentFields.map((content, index) => {
                        if (content.type === CONSTANTS.PHOTO) {
                            return (
                                <CardMedia
                                    className={classes.media}
                                    image={content.imageUrl}
                                    title={content.imageTitle}
                                />
                            )
                        }

                        return (
                            <>
                                <div className={classes.data}>
                                    <div className={classes.dataLabel}>
                                        {content.label} &nbsp;
                                    </div>
                                    <span className={classes.value}>{computeContentValue(content)}</span>
                                </div>
                            </>
                        )
                    })}
                    {props.smallDetails && <div className={`${props.classes.smallDetailsContainer}`}>
                        <span className={`${classes.smallDetailsContent}`}>{props.smallDetails.icon}</span>
                        <span className={`${classes.smallDetailsContent} ${classes.smallDetailsText}`}>{props.smallDetails.text}</span>
                    </div>}
                </CardContent>
            </ClickWrapper >
        </Card >
    )
}

const styles = theme => ({
    value: {
        fontSize: 15,
        color: 'black'
    },
    smallDetailsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingTop: 12
    },
    smallDetailsContent: {
        paddingRight: 5
    },
    smallDetailsText: {
        marginTop: '-3px'
    },
    media: {
        height: 100
    },
    cardContent: {
        borderBottom: 'none',
        minHeight: 150,
        cursor: 'pointer'
    },
    container: {
        width: '100%',
        height: '100%'
    },
    root: {
        minWidth: 270,
        padding: '12px 0px',
        height: 'auto',
        maxHeight: 300,
    },
    titleText: {
        margin: 0,
        padding: 0,
        color: '#3778C2',
        fontSize: 16
    },
    subtitleText: {
        margin: 0,
        padding: 0
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'red',
        avatar: {
            backgroundColor: 'yellow'
        }
    },
    separator: {
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        marginTop: 6,
        marginBottom: 6
    },
    data: {
        display: 'flex',
        color: 'rgba(0,0,0,0.40)',
        flexDirection: 'row',
    },
    dataRed: {
        display: 'flex',
        justifyContent: 'center',
        color: 'red',
        height: 50,
        flexDirection: 'column',
    },
    dataLabel: {
        color: 'rgba(0,0,0,0.40)',
        marginBottom: 10
    },
    detailsTitle: {
        margin: '4px 0px 18px 0px',
        fontSize: 16,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.87)'
    }
})

export default withStyles(styles)(card)