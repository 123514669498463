import React from 'react'
import { makeStyles } from '@material-ui/core'

import Modal from '../../../common/SimpleModal'

let FilesPreview = props => {
    let classes = useStyles()

    React.useEffect(() => {

    }, [props.open, props.files])


    return (
        <Modal
            open={props.open}
            maxWidth={'sm'}
            title={'Fisiere'}
            onClose={props.onClose}
            acceptButtonText={'Inchide'}
            onAccept={props.onClose}
        >
            <div className={classes.container}>
                {props.files.map(file => <div className={classes.link}
                    onClick={() => window.open(`http://localhost:9000/static/company-files/${file.fileName}`)}
                >
                    {file.originalName}
                </div>)}
            </div>
        </Modal>
    )
}

let useStyles = makeStyles({
    container: {
        width: '100%',
        height: '100%',
        overflowY: true,
        display: 'flex',
        flexDirection: 'column'
    },
    link: {
        paddingTop: 10,
        cursor: 'pointer'
    }
})

export default FilesPreview