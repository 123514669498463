import React, { Component } from 'react'
import JoditEditor from './JoditEditor'

class AddComment extends Component {
    state = {
        content: ''
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.reset && !this.props.reset) {
            this.setState({ content: '' })
        }
    }

    render() {

        const editor = React.createRef()
        const config = {
            readonly: false,
            uploader: {
                insertImageAsBase64URI: true,
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                format: 'json'
            },
            enableDragAndDropFileToEditor: true
        }

        return (
            <JoditEditor
                ref={editor}
                value={this.state.content}
                tabIndex={1}
                config={config}
                onChange={newContent => {
                    this.setState({ content: newContent }, () => this.props.setComment(this.state.content))
                }}
                props={this.props}
            />
        )
    }
}

export default AddComment