import React from 'react'
import { connect } from 'react-redux'

import FormGenerator from '../../common/FormGenerator'
import * as USER_REQUESTS from '../../../redux/actions/users'
import * as NOTIFICATIONS from '../../../utils/notification'

let INITIAL_FIELDS = [
    { value: '', type: 'text', label: 'Nume', name: 'firstName' },
    { value: '', type: 'text', label: 'Prenume', name: 'lastName' },
    { value: '', type: 'text', label: 'Email', name: 'email' },
    { value: '', type: 'text', label: 'Title', name: 'title' },
    { value: '', type: 'text', label: 'Numar telefon', name: 'phoneNumber' },
    { value: '', type: 'text', label: 'Parola', name: 'password' }
]

let EditUserModal = props => {
    let [fields, setFields] = React.useState(INITIAL_FIELDS)

    React.useEffect(() => {
        populate()
    }, [props.open])

    let populate = () => props.getMe()
        .then(user => {
            let newFields = [...fields]

            Object.keys(user).forEach(key => {
                let indexOfKey = newFields.findIndex(field => field.name == key)

                if (indexOfKey > -1 && key != 'password') {
                    newFields[indexOfKey].value = user[key]
                }
            })

            setFields(newFields)
        })

    let onSubmitHandler = data => {
        return props.editMe(data)
            .then(() => populate())
            .then(() => NOTIFICATIONS.success('User editat cu success'))
            .catch(() => NOTIFICATIONS.error('Eroare editare'))
    }

    return (
        <FormGenerator
            open={props.open}
            title={props.language.titles.editProfile}
            acceptButtonText={props.language.buttons.accept}
            cancelButtonText={props.language.buttons.drop}
            withCancelButton={true}
            onCancel={props.onCancel}
            fields={fields}
            onAccept={onSubmitHandler}
        />
    )
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getMe: () => dispatch(USER_REQUESTS.getMe()),
    editMe: newUser => dispatch(USER_REQUESTS.editMe(newUser))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal)