import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles, Tooltip, Button } from '@material-ui/core'
import {
    Check as AgreeIcon,
    Clear as DeclineIcon,
    PhotoLibrary as UploadCompanyImage,
    CloudUploadRounded as UploadFile
} from '@material-ui/icons'

import InputGenerator from '../../../common/InputGenerator'
import FilePreview from './FilesPreview'

import * as CONSTANTS from '../../../../utils/constants'
import * as COMPANY_REQUESTS from '../../../../redux/actions/companies'
import * as TAG_REQUESTS from '../../../../redux/actions/tags'
import * as BUILDING_REQUESTS from '../../../../redux/actions/buildings'
import * as USER_REQUESTS from '../../../../redux/actions/users'
import * as NOTIFICATIONS from '../../../../utils/notification'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#f7f8fa',
        display: 'flex',
        flexDirection: 'column',
    },
    titleContainer: {
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 28px',
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    titleText: {
        color: '#606771',
        fontSize: 24,
        fontWeight: 300
    },
    contentContainer: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 8px 0px 8px',
        maxHeight: 'calc(100% - 76px)'
    },
    status: {
        marginLeft: 'auto'
    },
    content: {
        padding: '8px 28px',
        minHeight: 350,
        maxHeight: 440
    },
    contentWhiteBg: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        overflowY: 'auto'
    },
    details: {
        padding: '8px 28px'
    },
    detailsText: {
        fontSize: 14,
        fontWeight: 300,
        color: '#3d4147'
    },
    agreeIcon: {
        color: '#34aa44'
    },
    declineIcon: {
        color: '#E74C3C'
    },
    detailsAssigned: {
        display: 'flex',
        flexDirection: 'row'
    },
    extraPadding: {
        padding: '4px 12px 14px 12px'
    },
    borderBottom: {
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    equalsFlex: {
        flex: 1
    },
    iconContainer: {
        alignItems: 'center',
        padding: '14px 0px 0px 24px'
    },
    smallButton: {
        width: 30,
        height: 30
    },
    uploadCompanyImage: {
        display: 'none'
    },
    buildingName: {
        padding: 10,
        fontSize: 20,
        fontWeight: 300,
        color: '#3d4147'
    },
    deleteBuilding: {
        paddingTop: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    deleteButton: {
        backgroundColor: '#e6492d',
        color: 'white'
    },
    seeFiles: {
        backgroundColor: '#00458B',
        color: 'white'
    },
    uploadImageContainer: {
        display: 'flex',
        flex: '1',
        justifyContent: 'flex-end',
        color: '#0F2557',
        marginTop: '6px'
    },
    uploadLabel: {
        cursor: 'pointer',
        marginRight: '7px'
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})


class CompanyEdit extends Component {

    initialCompanyFields = [
        { value: '', type: 'text', editing: false, label: this.props.language.labels.name, name: 'name' },
        { value: '', type: 'text', editing: false, label: this.props.language.labels.mainBusiness, name: 'mainBusiness' },
        { type: 'dropdownSelector', name: 'type', utils: this.props.language.labels.type.type, value: '', options: [] },
        { type: 'dropdownSelector', name: 'official', utils: this.props.language.labels.official, value: '', options: [] },
        {
            type: 'fixedMultiSelect',
            name: 'services',
            utils: this.props.language.labels.service,
            defaultvalue: [],
            options: [],
            value: []
        },
    ]

    state = {
        companyId: null,
        company: null,
        building: null,
        tags: [],
        users: [],
        types: [],
        editableFields: this.initialCompanyFields,
        done: false,
        seeFiles: false
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.company !== nextProps.company) {
            this.getCompany(nextProps.company)
            this.getOptions(nextProps)
        }

        if (this.props.building !== nextProps.building) {
            this.setState({ building: nextProps.building, done: false }, () => this.getOptions(nextProps))
        }
    }

    componentDidMount() {
        this.setState({ building: this.props.building, company: this.props.company })
        this.getOptions(this.props)
    }

    mapToDropdown = values => {
        let mappedArray = []
        values.forEach(value => {
            let valueJson = {}
            valueJson.name = value._id
            valueJson.value = false
            valueJson.label = value.name || `${value.firstName} ${value.lastName}`
            mappedArray.push(valueJson)
            valueJson = {}
        })
        return mappedArray
    }

    getOptions = newProps => {
        const getTags = this.props.getBuildingServices(newProps.company.building._id)
        const getTypes = this.props.getTags('company')
        const getUsers = this.props.getUsers()

        Promise.all([getTags, getUsers, getTypes]).then(values => {
            let mappedTags = values[0].services.map(tag => ({
                name: tag,
                value: false,
                label: tag
            }))

            let mappedTypes = [{ name: '', value: true, label: '' }].concat(values[2].map(type => ({
                name: type.name,
                value: false,
                label: type.name
            })))

            //.filter(user => user.company ? user.company._id === this.props.company._id : false)
            let mappedUsers = [{ name: "", label: "", value: false }].concat(this.mapToDropdown(values[1].users))

            this.setState({
                tags: mappedTags,
                types: mappedTypes,
                users: mappedUsers
            }, () => this.getCompany(newProps.company))
        })
    }

    getCompany = company => {
        if (company) {
            this.setState({ company }, () => {
                this.mapFields(company)
                this.setState({ done: true })
            })
        }
    }

    imageUploadHandler = event => {
        let filesArray = Array.from(event.target.files)
        const fileFormData = new FormData();
        fileFormData.append('file', filesArray[0])
        this.props.uploadImageTo(this.props.company._id, fileFormData)
            .then(() => {
                NOTIFICATIONS.success(this.props.language.toastr.image.successUpload)
            })
            .catch(() => {
                NOTIFICATIONS.error(this.props.language.toastr.image.errorUpload)
            })
    }

    fileUploadHandler = event => {
        let filesArray = Array.from(event.target.files)
        const fileFormData = new FormData();
        fileFormData.append('file', filesArray[0])
        this.props.uploadFileTo(this.props.company._id, this.props.buildingId, fileFormData)
            .then(() => {
                NOTIFICATIONS.success(this.props.language.toastr.file.successUpload)
            })
            .catch(() => {
                NOTIFICATIONS.error(this.props.language.toastr.file.errorUpload)
            })
    }

    mapFields = () => {
        let fieldsCopy = this.state.editableFields.map(field => ({ ...field }))
        let result = this.state.company

        Object.keys(result).forEach(key => {
            const currentIndex = fieldsCopy.findIndex(index => index.name === key)

            if (currentIndex > -1) {
                fieldsCopy[currentIndex].editing = false
                if (fieldsCopy[currentIndex].type === 'dropdownSelector' || fieldsCopy[currentIndex].type === 'fixedMultiSelect') {
                    fieldsCopy[currentIndex].options = this.setOptions(key)
                    if (fieldsCopy[currentIndex].name === 'services') {
                        fieldsCopy[currentIndex].defaultValue = this.setValues(key, result)
                    }
                    else {
                        if (fieldsCopy[currentIndex].name === 'official' && this.state.building && this.state.building.official) {
                            fieldsCopy[currentIndex].value = this.state.building.official ? [this.state.building.official._id] : null
                        } else fieldsCopy[currentIndex].value = this.setValues(key, result)
                    }
                }
                else {
                    fieldsCopy[currentIndex].value = result[key]
                }

                this.setState({ editableFields: fieldsCopy })
            }
        })
    }

    setOptions = key => {
        if (key.toLowerCase() === 'services') return this.state.tags
        if (key.toLowerCase() === CONSTANTS.TYPE_FIELD.toLowerCase()) return this.state.types
        if (key.toLowerCase() === CONSTANTS.USER_FIELD.toLowerCase()) return this.state.users
        return []
    }

    setValues = (key, result) => {
        if (key.toLowerCase() === 'services' && result[key]) {
            return result[key].map(service => ({
                name: service
            }))
        }

        if (key.toLowerCase() === CONSTANTS.TYPE_FIELD.toLowerCase() && this.state.types.length && result[key]) {
            const keyValue = this.state.types.filter(type => type.name.toLowerCase() === result[key].toLowerCase())
            if (keyValue.length) {
                keyValue[0].value = true
                return keyValue[0]
            }
            return ""
        }

        if (key.toLowerCase() === CONSTANTS.USER_FIELD.toLowerCase() && this.state.users.length && result[key]) {
            const keyValue = this.state.users.filter(user => user.label.toLowerCase() === `${result[key].firstName} ${result[key].lastName}`.toLowerCase())
            if (keyValue.length) {
                keyValue[0].value = true
                return keyValue[0]
            }
            return ""
        }

        return []
    }

    checkForEdit = json => {
        if (json.editing) return (
            <div className={`${this.props.classes.flexRow} ${this.props.classes.iconContainer}`}>
                <Tooltip placement={"top"} title={this.props.language.tooltip.acceptChanges}>
                    <Button className={this.props.classes.smallButton}>
                        <AgreeIcon
                            className={`${this.props.classes.agreeIcon}`}
                            onClick={() => this.onChangeHandler({ target: json }, true)}
                        />
                    </Button>
                </Tooltip>
                <Tooltip placement={"top"} title={this.props.language.tooltip.declineChanges}>
                    <Button className={this.props.classes.smallButton}>
                        <DeclineIcon
                            className={`${this.props.classes.declineIcon}`}
                            onClick={() => this.onChangeHandler({ target: json }, false)}
                        />
                    </Button>
                </Tooltip>
            </div >)
        else return null
    }

    renderEditableFields = () => {
        const InputWrapper = this.InputWrapper
        let jsonMap = {}
        this.state.editableFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <>
                <div className={`${this.props.classes.equalsFlex} ${this.props.classes.extraPadding} ${this.props.classes.flexRow}`}>
                    <div className={`${this.props.classes.equalsFlex}`}>
                        <InputWrapper shrink={true} input={jsonMap['name']} key={'name'} disabled={true} />
                    </div>
                    {this.checkForEdit(jsonMap['name'])}
                </div>
                <div className={`${this.props.classes.equalsFlex} ${this.props.classes.extraPadding} ${this.props.classes.flexRow}`}>
                    <div className={`${this.props.classes.equalsFlex}`}>
                        <InputWrapper shrink={true} input={jsonMap['mainBusiness']} key={'mainBusiness'} disabled={true} />
                    </div>
                    {this.checkForEdit(jsonMap['mainBusiness'])}
                </div>
                <div className={`${this.props.classes.equalsFlex} ${this.props.classes.extraPadding}`}>
                    <InputWrapper shrink={true} input={jsonMap['official']} key={'official'} />
                </div>
                <div className={`${this.props.classes.equalsFlex} ${this.props.classes.extraPadding}`}>
                    <InputWrapper shrink={true} input={jsonMap['type']} key={'type'} disabled={true} />
                </div>

                {this.props.company.type.toLowerCase() === CONSTANTS.COMPANY_TYPES.CONTRACTOR.toLowerCase() && <div className={`${this.props.classes.equalsFlex} ${this.props.classes.extraPadding}`}>
                    <InputWrapper shrink={true} input={jsonMap['services']} key={'services'} disabled={true} />
                </div>}
            </>
        )

    }

    onChangeHandler = (event, accepted) => {
        const fieldToEdit = event.target.name
        const valueToAdd = event.target.name === 'services' ? event.target.value.map(tag => tag._id || tag.name) : event.target.value

        // If official changes it changes to a particular building where the company is a tenant
        if (event.target.name === 'official') {
            return this.props.updateCompanyBuilding(this.props.companyId, this.props.buildingId, {
                [fieldToEdit]: valueToAdd.length > 0 ? valueToAdd : null
            })
                .then(() => NOTIFICATIONS.success(this.props.language.toastr.company.successEdit))
                .catch(() => NOTIFICATIONS.error(this.props.language.toastr.company.errorEdit))
        }

        if (event.target.name === 'type' || event.target.name === 'services' || accepted) {
            this.props.update(this.props.companyId, { [fieldToEdit]: valueToAdd }).then(() => {
                this.props.getCompany()
                NOTIFICATIONS.success(this.props.language.toastr.company.successEdit)
            })
                .catch(() => {
                    NOTIFICATIONS.error(this.props.language.toastr.company.errorEdit)
                })
        }
        else {
            const currentIndex = this.state.editableFields.findIndex(index => index.name === event.target.name)
            if (currentIndex > -1) {
                let fieldsCopy = this.state.editableFields.map(field => ({ ...field }))
                if (accepted === false) {
                    fieldsCopy[currentIndex].editing = false
                    fieldsCopy[currentIndex].value = this.props.company[event.target.name]
                }
                else {
                    fieldsCopy[currentIndex].value = event.target.value
                    fieldsCopy[currentIndex].editing = true
                }
                this.setState({ editableFields: fieldsCopy })
            }
        }
    }

    InputWrapper = ({ disabled, input, key, shrink }) => {
        return <div>
            <InputGenerator
                key={key}
                disabled={disabled}
                fullWidth={true}
                InputLabelProps={shrink ? { shrink: true } : {}}
                margin="dense"
                {...input}
                onChange={event => this.onChangeHandler(event)}
            />
        </div>
    }

    render() {
        let { classes } = this.props

        if (this.state.company && this.state.done && this.state.building) {
            return (
                <div className={classes.container}>
                    <FilePreview
                        open={this.state.seeFiles}
                        onClose={() => this.setState({ seeFiles: false })}
                        files={this.state.building ? this.state.building.files : []}
                    />
                    <div className={classes.titleContainer}>
                        <span className={classes.titleText}>{this.props.company.name}</span>
                        <div className={classes.uploadImageContainer}>
                            <label htmlFor='fileUpload' className={classes.uploadLabel}>
                                <Tooltip placement={'top'} title={this.props.language.tooltip.uploadFile}>
                                    <UploadFile />
                                </Tooltip>
                            </label>
                            <input id='fileUpload' type={'file'} className={classes.uploadCompanyImage} onChange={this.fileUploadHandler}></input>

                            <label htmlFor='imageUpload' className={classes.uploadLabel}>
                                <Tooltip placement={'top'} title={this.props.language.tooltip.uploadCompanyImage}>
                                    <UploadCompanyImage />
                                </Tooltip>  </label>
                            <input id='imageUpload' type={'file'} className={classes.uploadCompanyImage} onChange={this.imageUploadHandler}></input>
                        </div>
                    </div>
                    <div className={classes.contentContainer}>
                        <div className={`${classes.content}`}>
                            <div className={classes.buildingName}>
                                {this.state.building ? this.state.building.building.name : ''}
                            </div>
                            <div className={`${classes.contentWhiteBg}`}>
                                {this.renderEditableFields()}
                            </div>
                            <div className={classes.buttonsContainer}>
                                <div className={classes.deleteBuilding}>
                                    <Button
                                        className={classes.seeFiles}
                                        onClick={() => this.setState({ seeFiles: true })}
                                    >
                                        Vezi fisiere
                                </Button>
                                </div>
                                <div className={classes.deleteBuilding}>
                                    <Button
                                        className={classes.deleteButton}
                                        onClick={() => {
                                            let confirmed = window.confirm('Esti sigur ca vrei sa stergi firma din aceasta cladire?')
                                            if (confirmed) {
                                                return this.props.deleteCompanyFromBuilding(this.state.company._id, this.state.building.building._id)
                                                    .then(() => this.props.getCompany(true))
                                                    .then(() => this.props.history.push('/companies'))
                                                    .catch(err => NOTIFICATIONS.error('Eroare stergere'))
                                            }
                                        }}
                                    >
                                        Sterge
                                </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else return null
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    update: (id, ticket) => dispatch(COMPANY_REQUESTS.edit(id, ticket)),
    getTags: type => dispatch(TAG_REQUESTS.getAll({ type })),
    getUsers: () => dispatch(USER_REQUESTS.get({ all: true })),
    getBuildingServices: id => dispatch(BUILDING_REQUESTS.getBuildingServices(id)),
    uploadImageTo: (id, images) => dispatch(COMPANY_REQUESTS.uploadImage(id, images)),
    uploadFileTo: (id, buildingId, files) => dispatch(COMPANY_REQUESTS.uploadFile(id, buildingId, files)),
    deleteCompanyFromBuilding: (companyId, buildingId) => dispatch(COMPANY_REQUESTS.deleteCompanyFromBuilding(companyId, buildingId)),
    updateCompanyBuilding: (companyId, buildingId, building) => dispatch(COMPANY_REQUESTS.updateCompanyBuilding(companyId, buildingId, building))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CompanyEdit)))