import axios from '../../utils/axios'
import { errorHandler } from '../../utils/handlers'

const USER_ROUTE = '/users'

export const get = query => dispatch => axios.get(`${USER_ROUTE}?from=${query.from || ''}&limit=${query.limit || ''}&search=${query.search ? query.search : ''}&all=${query.all || ''}&role=${query.role || ''}&teamId=${query.teamId || ''}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const getMe = () => dispatch => axios.get(`${USER_ROUTE}/me`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const editMe = newUser => dispatch => axios.put(`${USER_ROUTE}/me`, { user: newUser })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const create = data => dispatch => axios.post(USER_ROUTE, { ...data })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const createAccountFromCompany = data => dispatch => axios.post(`${USER_ROUTE}/account`, { ...data })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const remove = id => dispatch => axios.delete(`${USER_ROUTE}/${id}`)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const edit = (id, newUser) => dispatch => axios.put(`${USER_ROUTE}/${id}`, { user: { ...newUser } })
    .then(response => ({ ...response.data }))
    .catch(errorHandler)

export const uploadImage = (id, images) => dispatch => axios.post(`${USER_ROUTE}/${id}/image`, images)
    .then(response => ({ ...response.data }))
    .catch(errorHandler)