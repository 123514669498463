import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { FormControl, TextField, Select, MenuItem } from '@material-ui/core'

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        spacing: 1,
        minWidth: 60,
        width: '100%'
    },
    selectEmpty: {
        spacing: 2
    },
    menu: {
        backgroundColor: 'red !important'
    },
    padding: {
        padding: 6
    },
    selectOption: {
    },
    menuItemCustom: {
        width: 'auto',
        height: 30,
        border: 'none !important',
        color: '#fff !important',
        margin: 4
    },
    textFieldCustom: {
        color: '#fff',
        width: 'auto',
        height: 30,
        borderRadius: 3,
        padding: '4px 8px',
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        },
        "&&&&:hover:before": {
            borderBottom: "none",
        }
    },
    textField: {
        borderColor: '#545A63'
    },
    inputProps: {
        fontSize: 12,
        color: '#545A63'
    }
})

class DropdownSelector extends React.Component {
    state = {
        value: ""
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({ value: this.props.value.name ? this.props.value.name : this.props.value })
        }
    }

    handleChange = event => {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: event.target.value
            }
        })
        this.setState({ value: event.target.value })
    }

    render() {
        const { classes } = this.props
        if (this.props.custom) {
            return (
                <div className={classes.root}>
                    <FormControl className={classes.formControl}>
                        <Select
                            value={this.props.value}
                            onChange={this.handleChange}
                            defaultValue={this.props.defaultValue ? this.props.defaultValue : null}
                            className={classes.textFieldCustom}
                            style={{ backgroundColor: this.props.value && this.props.value.color ? this.props.value.color : '' }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center'
                                }
                            }}
                        >
                            {this.props.options.map((option, index) => {
                                return (
                                    <MenuItem className={classes.menuItemCustom} style={{ backgroundColor: option.color ? option.color : '' }} value={option}>{option.label || option.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div >
            )
        }
        else {
            return (
                <div className={classes.root}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            select
                            disabled={this.props.disabled}
                            label={this.props.utils}
                            className={classes.textField}
                            defaultValue={this.props.defaultValue ? this.props.defaultValue : null}
                            value={this.state.value ? this.state.value : null}
                            onChange={event => this.handleChange(event)}
                            onFocus={event => this.props.onFocus ? this.props.onFocus({
                                target: {
                                    name: this.props.name,
                                    value: event.target.value
                                }
                            }) : () => { }}
                            InputLabelProps={{
                                shrink: this.props.shrink ? this.props.shrink : true
                            }}
                            InputProps={{
                                className: `${this.props.classes.inputProps} ${this.props.classes.textField}`
                            }}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                }
                            }}
                            margin="normal"
                            id='dropdownSelector'
                        >
                            {this.props.options.map((option, index) => (
                                <option key={index} value={option.name}>
                                    {option.label || option.name}
                                </option>
                            ))}
                        </TextField>
                    </FormControl>
                </div>
            );
        }
    }
}


const mapStateToProps = reducers => ({
    languageReducer: reducers.languageReducer.i18n.selector
})

export default withStyles(styles)(connect(mapStateToProps)(DropdownSelector))