import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import * as NOTIFICATIONS from '../../utils/notification'
import * as CONSTANTS from '../../utils/constants'
import * as USERS_REQUEST from '../../redux/actions/users'

import Card from '../common/Card'
import CreateUserModal from './create/Create'
import Header from '../common/Header'

import Pagination from '../common/Pagination'

let generateRows = (props, users, onDeleteHandler, onEditHandler, classes) => users.map(user => {
    return (
        <div className={classes.spacing}>
            <Card
                language={props.language}
                onSelect={user => onEditHandler(user)}
                contentFields={[
                    { name: 'role', label: props.language.labels.role, type: CONSTANTS.TYPE_TEXT },
                    { name: 'email', label: props.language.labels.email, type: CONSTANTS.TYPE_TEXT, color: 'red' },
                    { name: 'phoneNumber', label: props.language.labels.phone, type: CONSTANTS.TYPE_TEXT },
                    { name: 'groups', label: props.language.labels.group, type: CONSTANTS.TYPE_OBJECT }]}
                item={user}
                title={user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : '-'}
                subtitle={user.company ? user.company.name : '-'}
                logo={true}
                onDelete={() => onDeleteHandler(user._id)}
                onEdit={() => onEditHandler(user)}
            />
        </div>
    )
})

const users = props => {
    let { classes } = props
    let [users, setUsers] = useState([])
    let [selectedUser, setSelectedUser] = useState(null)
    let [count, setCount] = useState(0)
    let [showCreateUser, setShowCreateUser] = useState(false)

    let [from, setFrom] = useState(0)
    let [rowsPerPage, setRowsPerPage] = useState(CONSTANTS.DEFAULT_CARD_ROWS_PER_PAGE_VALUE)

    const filterMapping = filter => {
        if (filter === 'pms') return 'pm'
        if (filter === 'fms') return 'fm'
        if (filter === 'users') return 'user'
        return filter
    }

    const onDeleteHandler = id => props.removeUser(id)
        .then(() => getUsers()
            .then(() => {
                setShowCreateUser(false)
                NOTIFICATIONS.success(props.language.toastr.user.successDelete)
            }))

    const onEditHandler = user => {
        setSelectedUser(user)
        setShowCreateUser(true)
    }

    const changeRowsPerPageHandler = rowsPerPage => {
        setRowsPerPage(rowsPerPage)
    }

    const changePageHandler = currentFrom => {
        setFrom(currentFrom)
    }

    useEffect(() => {
        getUsers()
    }, [rowsPerPage, from])

    let filter = filterMapping(props.match.params.FILTER)

    const getUsers = search => props.getUsers({ role: filter || '', search, from: from, limit: rowsPerPage })
        .then(data => {
            setCount(data.count)
            setUsers(data.users)

            return true
        })

    useEffect(() => {
        getUsers()

        return () => { }
    }, [showCreateUser, props.match.params.FILTER, selectedUser])

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.header}>
                    <Header
                        title={props.language.customers}
                        buttonText={props.language.create}
                        onSearch={filter => getUsers(filter)}
                        onClick={() => setShowCreateUser(true)}
                    />
                </div>
                <div className={classes.container}>

                    {generateRows(props, users, onDeleteHandler, onEditHandler, classes)}

                    <div className={`${classes.container} ${classes.pagination}`}>
                        <Pagination
                            count={count}
                            rowsPerPage={rowsPerPage}
                            changeRowsPerPage={changeRowsPerPageHandler}
                            changePageHandler={changePageHandler}
                        ></Pagination>
                    </div>
                </div >


            </div>
            <CreateUserModal
                open={showCreateUser}
                user={selectedUser}
                onCancel={() => {
                    setShowCreateUser(false)
                    setSelectedUser(null)
                }}
                onAccept={() => {
                    setShowCreateUser(false)
                    setSelectedUser(null)
                }}
            />
        </>
    )
}
const styles = theme => ({
    header: {
        height: 70
    },
    wrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflowY: 'auto',
    },
    line: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    spacing: {
        margin: 10,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        minWidth: '275px',
        minHeight: '315px',
        maxWidth: '275px',
        maxHeight: '315px'

    },
    cardsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    cardContainer: {
        padding: '18px'
    },
    disableBorder: {
        borderBottom: 'none'
    },
    surfaceTitle: {
        paddingTop: 20
    },
    title: {
        paddingBottom: 5,
        margin: 0
    },
    details: {
        display: 'flex',
        flexDirection: 'row'
    },
    smallDetailsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingTop: 12
    },
    smallDetailsContent: {
        paddingRight: 5
    },
    smallDetailsText: {
        marginTop: '-3px'
    },
    pagination: {
        justifyContent: 'flex-end'
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getUsers: options => dispatch(USERS_REQUEST.get(options)),
    removeUser: id => dispatch(USERS_REQUEST.remove(id))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(users))