import React, { Component } from 'react'
import { connect } from 'react-redux'

import { withStyles, Avatar, Button } from '@material-ui/core'
import {
    PersonPin as PersonIcon,
    Clear as ClearIcon
} from '@material-ui/icons'

import InputGenerator from '../../../common/InputGenerator'
import UsersList from './UsersList'
import History from './History'

import * as CONSTANTS from '../../../../utils/constants'
import * as TICKET_REQUESTS from '../../../../redux/actions/tickets'
import * as BUILDING_REQUESTS from '../../../../redux/actions/buildings'
import * as TAG_REQUESTS from '../../../../redux/actions/tags'
import * as NOTIFICATIONS from '../../../../utils/notification'

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#f7f8fa',
        display: 'flex',
        flexDirection: 'column',
    },
    titleContainer: {
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 28px',
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    titleText: {
        color: '#606771',
        fontSize: 24,
        fontWeight: 300
    },
    noHistoryWarning: {
        color: '#606771',
        fontSize: 14,
        paddingLeft: 28,
        fontWeight: 300
    },
    contentContainer: {
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 8px 0px 8px',
        maxHeight: 'calc(100% - 76px)'
    },
    status: {
        marginLeft: 'auto'
    },
    content: {
        flex: 1,
        marginBottom: 8,
        padding: '8px 28px',
        maxHeight: 350,
        overflow: 'auto'
    },
    contentWhiteBg: {
        backgroundColor: '#fff',
        width: '100%',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        overflowY: 'auto'
    },
    details: {
        padding: '8px 28px',
        cursor: 'default'
    },
    detailsText: {
        fontSize: 14,
        fontWeight: 300,
        color: '#3d4147',
        padding: '0px 8px 6px 8px'
    },
    smallDetailsText: {
        fontSize: 12,
        fontWeight: 300,
        color: '#3d4147',
        padding: '1px 8px 1px 8px'
    },
    detailsIcon: {
        fontSize: 48,
        color: '#3d4147'
    },
    detailsAssigned: {
        display: 'flex',
        flexDirection: 'row'
    },
    extraPadding: {
        padding: '4px 12px 8px 12px'
    },
    borderBottom: {
        borderBottom: '1px solid rgba(0,0,0,0.15)'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    equalsFlex: {
        flex: 1
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    assigneeTitle: {
        fontSize: 16,
        fontWeight: 400,
        color: '#3d4147',
        flex: 1
    },
    assigneeRemoval: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    }
})


class TicketEdit extends Component {

    priorityWithLabels = CONSTANTS.PRIORITY.map(priority => ({ ...priority, label: this.props.language.labels.priority[priority.name] }))

    initialTicketFields = [
        { type: 'dropdownSelector', name: 'building', utils: this.props.language.labels.building, value: '', options: [] },
        { type: 'dropdownSelector', name: 'type', utils: this.props.language.labels.type.type, value: '', options: [] },
        {
            type: 'dropdownSelector',
            name: 'services',
            utils: this.props.language.labels.service,
            options: [],
            value: ''
        },
        { type: 'dropdownSelector', name: 'priority', utils: this.props.language.labels.priority.priority, value: '', options: this.priorityWithLabels }
    ]

    state = {
        ticketId: null,
        ticket: null,
        status: [],
        currentStatus: null,
        editableFields: this.initialTicketFields,
        buildings: [],
        types: [],
        services: [],
        priorities: [],
        done: false
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.ticket !== nextProps.ticket) {
            this.getTicket(nextProps.ticket)
        }
    }

    componentDidMount() {
        this.getOptions()
    }

    mapToDropdown = values => {
        let mappedArray = []
        values.forEach(value => {
            let valueJson = {}
            valueJson.name = value._id
            valueJson.value = false
            valueJson.label = value.name
            mappedArray.push(valueJson)
            valueJson = {}
        })
        return mappedArray
    }

    getOptions = () => {
        // const getBuildings = this.props.getBuildings()
        // const getServices = this.props.getTags('services')
        const getTypes = this.props.getTags('ticket')

        Promise.all([getTypes]).then(values => {
            // let mappedBuildings = this.mapToDropdown(values[0].buildings)
            // values[1] = values[1].filter(value => value.name === this.props.ticket.services[0])
            // let mappedServices = this.mapToDropdown(values[1])
            let mappedStatus = [...CONSTANTS.STATUS].map(status => ({ ...status, label: this.props.language.labels.status[status.name] }))
            let mappedPriorities = [...Object.values(CONSTANTS.TICKET_PRIORITIES)].map(priority => ({
                name: priority,
                value: false,
                label: this.props.language.labels.priority[priority]
            }))

            let mappedTypes = [...values[0]].map(type => ({
                name: type.name,
                value: false,
                label: type.name
            }))

            let service = [{ name: this.props.ticket.services[0], value: true, label: this.props.ticket.services[0] }]
            let building = [{ name: this.props.ticket.building.name, value: true, label: this.props.ticket.building.name }]

            this.setState({
                buildings: building,
                services: service,
                status: mappedStatus,
                types: mappedTypes,
                priorities: mappedPriorities
            }, () => this.getTicket(this.props.ticket))
        })
    }

    getTicket = ticket => {
        if (ticket) {
            this.setTicketStatus(ticket.status)
            this.mapFields(ticket)
            this.setState({ done: true })
        }
    }

    mapFields = result => {
        let fieldsCopy = this.state.editableFields.map(field => ({ ...field }))

        let buildingsIndex = fieldsCopy.findIndex(f => f.name === 'building')

        if (buildingsIndex > -1) {
            fieldsCopy[buildingsIndex].options = this.setOptions('building')
        }

        Object.keys(result).forEach(key => {
            const currentIndex = fieldsCopy.findIndex(index => index.name === key)
            if (currentIndex > -1) {
                if (fieldsCopy[currentIndex].type === 'dropdownSelector' || fieldsCopy[currentIndex].type === 'fixedMultiSelect') {
                    fieldsCopy[currentIndex].options = this.setOptions(key)
                    if (fieldsCopy[currentIndex].name === 'services') {
                        fieldsCopy[currentIndex].defaultValue = this.setValues(key, result)
                    }
                    else {
                        fieldsCopy[currentIndex].value = this.setValues(key, result)
                    }

                    this.setState({ editableFields: fieldsCopy })
                }
            }
        })
    }

    setOptions = key => {
        if (key.toLowerCase() === CONSTANTS.BUILDING_FIELD.toLowerCase()) return this.state.buildings
        if (key.toLowerCase() === CONSTANTS.TYPE_FIELD.toLowerCase()) return this.state.types
        if (key.toLowerCase() === CONSTANTS.PRIORITY_FIELD.toLowerCase()) return this.state.priorities
        if (key.toLowerCase() === CONSTANTS.SERVICE_FIELD.toLowerCase()) return this.state.services
        return []
    }

    setValues = (key, result) => {
        if (key.toLowerCase() === CONSTANTS.BUILDING_FIELD.toLowerCase()) {
            const keyValue = this.state.buildings.filter(building => building.label.toLowerCase() === result[key].name.toLowerCase())
            if (keyValue.length) {
                keyValue[0].value = true
                return keyValue[0]
            }
            return ""
        }
        if (key.toLowerCase() === CONSTANTS.TYPE_FIELD.toLowerCase()) {
            const keyValue = this.state.types.filter(type => type.name.toLowerCase() === result[key].toLowerCase())
            if (keyValue.length) {
                keyValue[0].value = true
                return keyValue[0]
            }
            return ""
        }
        if (key.toLowerCase() === CONSTANTS.PRIORITY_FIELD.toLowerCase()) {
            const keyValue = this.state.priorities.filter(priority => priority.name.toLowerCase() === result[key].toLowerCase())
            if (keyValue.length) {
                keyValue[0].value = true
                return keyValue[0]
            }
            return ""
        }
        if (key.toLowerCase() === CONSTANTS.SERVICE_FIELD.toLowerCase()) {
            return result[key]
        }

        return []
    }

    setTicketStatus = status => {
        const statusIndex = this.state.status.findIndex(index => index.name === status)
        if (statusIndex > -1) {
            let statusCopy = this.state.status.map(field => ({ ...field }))
            statusCopy = statusCopy.map(st => ({ ...st, value: false }))
            statusCopy[statusIndex].value = true
            this.setState({ status: statusCopy, currentStatus: statusCopy[statusIndex] })
        }
    }

    changeStatusHandler = value => {
        this.props.update(this.props.ticketId, { status: value.name }).then(() => {
            this.props.getTicket()
            NOTIFICATIONS.success(`${this.props.language.toastr.ticket.successEdit}`)
        })
            .catch(() => {
                NOTIFICATIONS.error(`${this.props.language.toastr.ticket.errorEdit}`)
            })
    }

    renderEditableFields = () => {
        const InputWrapper = this.InputWrapper
        let jsonMap = {}
        this.state.editableFields.forEach(field => {
            jsonMap[field.name] = field
        })
        let isTicketClosed = this.props.ticket && this.props.ticket.status && this.props.ticket.status == CONSTANTS.TICKET_STATUSES.CLOSED

        return (
            <>
                <div className={this.props.classes.flexRow}>
                    <div className={`${this.props.classes.equalsFlex} ${this.props.classes.extraPadding}`}>
                        <InputWrapper disabled={isTicketClosed} shrink={true} input={jsonMap['type']} key={'type'} />
                    </div>
                    <div className={`${this.props.classes.equalsFlex} ${this.props.classes.extraPadding}`}>
                        <InputWrapper disabled={isTicketClosed} shrink={true} input={jsonMap['priority']} key={'priority'} />
                    </div>
                </div>
                <div className={`${this.props.classes.equalsFlex} ${this.props.classes.extraPadding}`}>
                    <InputWrapper disabled={true} shrink={true} input={jsonMap['building']} key={'building'} />
                </div>
                <div className={`${this.props.classes.equalsFlex} ${this.props.classes.extraPadding}`}>
                    <InputWrapper shrink={true} disabled={isTicketClosed} input={jsonMap['services']} key={'services'} />
                </div>
            </>
        )

    }

    onChangeHandler = event => {
        const fieldToEdit = event.target.name
        const valueToAdd = event.target.value
        this.props.update(this.props.ticketId, { [fieldToEdit]: valueToAdd }).then(() => {
            this.props.getTicket()
            NOTIFICATIONS.success(this.props.language.toastr.ticket.successEdit)
        })
            .catch(() => {
                NOTIFICATIONS.error(this.props.language.toastr.ticket.errorEdit)
            })
    }

    InputWrapper = ({ disabled, input, key, shrink }) => {
        return <div>
            <InputGenerator
                key={key}
                disabled={disabled}
                fullWidth={true}
                InputLabelProps={shrink ? { shrink: true } : {}}
                margin="dense"
                {...input}
                onChange={event => this.onChangeHandler(event)}
            />
        </div>
    }

    renderUserList = () => {
        return (
            <UsersList getTicket={() => this.props.getTicket()} ticketId={this.props.ticket._id} />
        )
    }

    removeAssignee = () => {
        this.props.updateTicket(this.props.ticket._id, { assignedCompany: null })
            .then(() => {
                this.props.getTicket()
                NOTIFICATIONS.success(this.props.language.toastr.ticket.removeAssignee)
            })
            .catch(() => {
                NOTIFICATIONS.error(this.props.language.toastr.ticket.errorAssignee)
            })
    }

    renderStatus = status => {
        switch (status) {
            case CONSTANTS.TICKET_STATUSES.NEW: return <Button
                style={{ backgroundColor: CONSTANTS.STATUS_COLORS.NEW, color: 'white' }}
                disabled
            >
                {this.props.language.labels.status[CONSTANTS.TICKET_STATUSES.NEW]}
            </Button>

            case CONSTANTS.TICKET_STATUSES.CLOSED: return <Button
                style={{ backgroundColor: CONSTANTS.STATUS_COLORS.CLOSED, color: 'white' }}
                disabled
            >
                {this.props.language.labels.status[CONSTANTS.TICKET_STATUSES.CLOSED]}
            </Button>

            case CONSTANTS.TICKET_STATUSES.VERIFY: return <Button
                style={{ backgroundColor: CONSTANTS.STATUS_COLORS.VERIFY, color: 'white' }}
                disabled
            >
                {this.props.language.labels.status[CONSTANTS.TICKET_STATUSES.VERIFY]}
            </Button>

            case CONSTANTS.TICKET_STATUSES.PENDING: return <Button
                style={{ backgroundColor: CONSTANTS.STATUS_COLORS.PENDING, color: 'white' }}
                disabled
            >
                {this.props.language.labels.status[CONSTANTS.TICKET_STATUSES.PENDING]}
            </Button>

            default: return null
        }
    }

    render() {
        let { classes } = this.props
        const assigneeProfileImage = this.props.ticket.assignee && this.props.ticket.assignee.profileImage ? `${CONSTANTS.BACKEND_URL}/static/${this.props.ticket.assignee.profileImage.path}` : ''
        let isClosed = this.props.ticket && this.props.ticket.status && this.props.ticket.status == CONSTANTS.TICKET_STATUSES.CLOSED

        if (this.props.ticket && this.state.done) {
            return (
                <div className={classes.container}>
                    <div className={classes.titleContainer}>
                        <span className={classes.titleText}>{this.props.language.labels.ticket} #{this.props.ticket._id.substr(0, 5)}</span>
                        <div className={classes.status}>
                            {this.props.ticket && this.props.ticket.status ? this.renderStatus(this.props.ticket.status) : null}
                        </div>
                    </div>
                    <div className={classes.contentContainer}>
                        {this.props.ticket.assignedCompany ? <div className={classes.details}>
                            <div className={classes.assigneeRemoval}>
                                <p className={`${classes.assigneeTitle}`}>{this.props.language.utils.assignee}</p>
                                {!isClosed && this.props.loginReducer.role != 'user' && < Button onClick={() => this.removeAssignee()} title={this.props.language.buttons.delete}><ClearIcon /></Button>}
                            </div>

                            <div className={classes.detailsAssigned}>
                                {assigneeProfileImage ? <Avatar aria-label="recipe" src={assigneeProfileImage} /> : <PersonIcon className={classes.detailsIcon} />}
                                <div className={classes.flexColumn}>
                                    <span className={`${classes.detailsText}`}>{`${this.props.ticket.assignedCompany ? this.props.ticket.assignedCompany.name : " "}`}</span>
                                {/* <span className={`${classes.smallDetailsText}`}>{`${this.props.ticket.assignee.firstName} ${this.props.ticket.assignee.lastName}`}</span>
                                <span className={`${classes.smallDetailsText}`}>{`${this.props.ticket.assignee.email}`}</span> */}
                                </div>
                            </div>
                        </div> : this.renderUserList()}
                        <div className={`${classes.content}`}>
                            <div className={`${classes.contentWhiteBg}`}>
                                {this.renderEditableFields()}
                            </div>
                        </div>
                        {this.props.ticket && this.props.ticket.history.length ? <div className={`${classes.content}`}>
                            <History
                                language={this.props.language}
                                items={this.props.ticket.history}
                            />
                        </div> : <h5 className={classes.noHistoryWarning}>{this.props.language.utils.noHistory}</h5>}
                    </div>
                </div >
            )
        }
        else return null
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
    loginReducer: reducers.loginReducer
})

const mapDispatchToProps = dispatch => ({
    update: (id, ticket) => dispatch(TICKET_REQUESTS.update(id, ticket)),
    getBuildings: () => dispatch(BUILDING_REQUESTS.get({ all: true })),
    getTags: type => dispatch(TAG_REQUESTS.getAll({ type })),
    updateTicket: (ticketId, newTicket) => dispatch(TICKET_REQUESTS.update(ticketId, newTicket))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TicketEdit))