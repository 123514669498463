import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles, Button } from '@material-ui/core'

import * as COMPANIES_REQUESTS from '../../redux/actions/companies'


let AnafButton = props => {
    let classes = useStyles()

    useEffect(() => {
        
    }, [props.cui])

    let getAnafData = cui => props.getData(cui)

    let onAnafButtonClickedHandler = async cui => {
        let anafData = await getAnafData(cui)

        return props.onAnafButtonClicked(anafData)
    }

    return (
        <div className={classes.container}>
            <Button className={classes.button} onClick={() => onAnafButtonClickedHandler(props.cui)}>
                {props.language.anaf.button}
            </Button>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        width: '100%',
        height: '100%',
        backgroundColor: 'grey',
        color: 'white',
        fontWeight: 300
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getData: cui => dispatch(COMPANIES_REQUESTS.getAnafData(cui))
})

export default connect(mapStateToProps, mapDispatchToProps)(AnafButton)