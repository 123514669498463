import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'

import Editable from './editable/Editable'
import Users from './users/Users'
import BuildingsList from './buildingsList/BuildingsList'

import * as COMPANY_REQUESTS from '../../../redux/actions/companies'


const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    editableContainer: {
        height: '100%',
        flex: 1,
        borderRight: '1px solid rgba(0,0,0,0.15)'
    },
    usersContainer: {
        height: '100%',
        flex: 2
    },
    buildingsContainer: {
        height: '100%',
        flex: 1,
        borderRight: '1px solid rgba(0,0,0,0.15)'
    }
})

class CompanyDetails extends Component {

    state = {
        company: null,
        building: null
    }

    componentDidMount() {
        this.getCompany()
    }

    getCompany = () => {
        return this.props.getById(this.props.match.params.ID).then(result => {
            this.setState({ company: result })
            return true
        })
    }

    buildingChangedHandler = buildingId => {
        return this.props.getByIdWithBuilding(this.props.match.params.ID, buildingId)
            .then(result => {
                this.setState({
                    company: result.company,
                    building: result.building
                })
            })
    }

    render() {
        let { classes } = this.props

        return (
            <div className={classes.container}>
                <div className={classes.buildingsContainer}>
                    <BuildingsList
                        companyId={this.props.match.params.ID}
                        buildingChanged={this.buildingChangedHandler}
                        currentBuilding={this.state.building ? this.state.building._id : null}
                        company={this.state.company}
                    />
                </div>
                {this.state.building && <div className={classes.editableContainer}>
                    <Editable
                        companyId={this.props.match.params.ID}
                        buildingId={this.state.building._id}
                        company={this.state.company}
                        building={this.state.building}
                        getCompany={() => this.getCompany()} />
                </div>}
                {this.state.building && <div className={classes.usersContainer}>
                    <Users
                        companyId={this.props.match.params.ID}
                        company={this.state.company}
                        building={this.state.building}
                        users={this.state.building ? this.state.building.users : []}
                        getCompany={() => this.getCompany()} />
                </div>}
            </div>
        )
    }
}


const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n,
})

const mapDispatchToProps = dispatch => ({
    getById: id => dispatch(COMPANY_REQUESTS.getById(id)),
    getByIdWithBuilding: (companyId, buildingId) => dispatch(COMPANY_REQUESTS.getByIdWithBuilding(companyId, buildingId))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CompanyDetails))