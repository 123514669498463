import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { List, ListItem, ListItemText, ListItemIcon, Collapse, withStyles, Button } from '@material-ui/core'
import { FolderOutlined as FolderOutlinedIcon, ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon, Menu } from '@material-ui/icons'


const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing.unit * 2
    },
    sidebarHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sidebarHeaderContent: {
        width: '90%',
        height: 80,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #eee'
    },
    listItemText: {
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        padding: 0,
        color: '#373A36'
    },
    isSelected: {
        color: '#00458B',
        fontWeight: 'bold'
    },
    sidebarIcon: {
    },
    logoutButton: {
        color: '#3e3f42',
        backgroundColor: theme.palette.mainColor,
        position: ' absolute',
        bottom: '10px'
    },
    userAccount: {
        display: 'flex',
        borderBottom: '1px solid white'
    },
    username: {
        fontSize: '18px',
        lineHeight: '47px',
        textTransform: 'capitalize'
    },
    displayNone: {
        display: 'none'
    },
    languageSelector: {
        marginLeft: 'auto'
    },
    listWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    listElements: {
        width: '100%',
        fontFamily: 'Roboto',
    },
    headerText: {
        padding: 34,
        fontWeight: 500,
        color: 'white'
    },
    logo: {
        '& img.image': {
            height: '60px'
        },
        '& img.text': {
            height: '30px',
            marginBottom: '15px'
        },
        marginLeft: '15px'
    },
    closeSidebarButton: {
        color: 'white',
        cursor: 'pointer'
    },
    noSidebarContainer: {
        backgroundColor: '#FBFBFA',
        width: 40
    },
    noSidebar: {
        height: 46,
        paddingTop: 24,
        paddingLeft: 14,
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        backgroundColor: 'white'
    },
    openSidebarButton: {
        cursor: 'pointer'
    }
})

class Sidebar extends Component {
    state = {
        selected: {},
        expanded: {},
        displayNone: false
    }

    componentDidMount() {
        this.handlePathExpansion()
    }

    handlePathExpansion = () => {
        const currentPathname = this.props.location.pathname

        if (currentPathname.length === 1) {
            this.handleSelect('dashboard')
        }
        else {
            const labels = currentPathname.split('/')
            const currentLabel = labels.pop()
            this.handleSelect(currentLabel)
            labels.forEach(label => {
                this.handleExpand(label)
            })
        }
    }

    handleSelect = value => {
        let selectedCopy = { ...this.state.selected }
        Object.keys(selectedCopy).forEach(element => {
            selectedCopy[element] = false
        })
        selectedCopy[value] = !selectedCopy[value]
        this.setState({ selected: selectedCopy })
    }


    handleExpand = value => {
        let expandCopy = { ...this.state.expanded }
        expandCopy[value] = !expandCopy[value]
        this.setState({ expanded: expandCopy })
    }

    render() {
        const { classes } = this.props
        const ListItemComponent = props => <ListItem component={props.to ? Link : 'div'} to={props.to} divider={true} button selected={this.state.selected[props.label]} onClick={() => this.handleSelect(props.label)}>
            <ListItemIcon className={`${this.state.selected[props.label] ? classes.isSelected : ''}`}>
                {props.icon ? props.icon : <FolderOutlinedIcon />}
            </ListItemIcon>
            <ListItemText classes={{ primary: `${this.state.selected[props.label] ? classes.isSelected : ''} ${classes.listItemText}` }} primary={props.text} />
            {props.nested ? props.open ? <ExpandLessIcon className={classes.sidebarIcon} onClick={() => this.handleExpand(props.label)} /> : <ExpandMoreIcon className={classes.sidebarIcon} onClick={() => this.handleExpand(props.label)} /> : null}
        </ListItem>

        return (
            <>
                <div className={`sidebar`}>
                    <List component='nav' className={classes.listWrapper}>
                        <>
                            <div className={classes.listElements}>
                                {this.props.listItems.map(item => {
                                    if (item.hasAccess) {
                                        if (!item.nested) {
                                            return (<div key={item.label}><ListItemComponent
                                                {...item}
                                                text={this.props.language[item.label]}
                                                open={this.state.expanded[item.expandedText]} /></div>)
                                        }
                                        if (item.nested) {
                                            return (<div key={item.label}>
                                                <ListItemComponent {...item} text={this.props.language[item.label][item.label]} nested open={this.state.expanded[item.expandedText]} />
                                                <Collapse
                                                    in={this.state.expanded[item.expandedText]}
                                                    timeout='auto'
                                                    unmountOnExit>
                                                    <List
                                                        className={classes.nested}
                                                        component='div'
                                                        disablePadding>
                                                        {item.nestedComponents.map(component => <ListItemComponent key={component.label} {...component} text={this.props.language[item.label][component.label]} />)}
                                                    </List>
                                                </Collapse>
                                            </div>)
                                        }
                                    }
                                    return null
                                })}
                            </div>
                        </>
                    </List>
                    <Button className={classes.logoutButton} onClick={this.props.onClickButton}>{this.props.buttonText}</Button>
                </div>
            </>
        )
    }
}

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n.sidebar
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Sidebar)))