import React from 'react'
import { makeStyles } from '@material-ui/core'

import Modal from './SimpleModal'
import InputGenerator from './InputGenerator'

let FormGenerator = props => {
    let classes = useStyles()
    let [fields, setFields] = React.useState(props.fields || [])

    React.useEffect(() => {
        setFields(props.fields)
        return () => { }
    }, [props.fields, props.open])

    let onFieldChange = event => {
        let fieldIndex = fields.findIndex(field => field.name == event.target.name)

        if (fieldIndex < 0) return

        let newFields = [...fields]

        newFields[fieldIndex].value = event.target.value

        setFields(newFields)
    }

    let onAcceptHandler = () => {
        let data = {}

        fields.forEach(field => {
            if (field.type == 'fixedMultiSelect') {
                data[field.name] = field.value.map(f => f.label)
            } else data[field.name] = field.value
        })

        return data
    }

    return (
        <Modal
            open={props.open}
            title={props.title}
            acceptButtonText={props.acceptButtonText}
            cancelButtonText={props.cancelButtonText}
            deleteButtonText={props.deleteButtonText}
            withCancelButton={props.withCancelButton}
            withDeleteButton={props.withDeleteButton}
            onCancel={props.onCancel}
            onDelete={props.onDelete}
            maxWidth={props.maxWidth}
            onAccept={() => props.onAccept(onAcceptHandler())}
        >
            <div className={classes.container}>
                {fields.map((field, index) => <InputGenerator
                    key={index}
                    onChange={onFieldChange}
                    {...field}
                />)}
            </div>
        </Modal>
    )
}

let useStyles = makeStyles({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
})

export default FormGenerator