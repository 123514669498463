import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Companies from './companies/Companies'
import Editable from './editable/Editable'
import * as BUILDING_REQUESTS from '../../../redux/actions/buildings'

const Details = props => {
    let { classes } = props

    let [building, setBuilding] = useState(null)

    useEffect(() => {
        props.getBuilding(props.match.params.ID)
            .then(building => setBuilding(building))
    }, [])

    return (
        <div className={classes.container}>
            <div className={classes.editableContainer}>
                <Editable
                    building={building ? building : null}
                />
            </div>
            <div className={classes.usersContainer}>
                <Companies
                    buildingId={building ? building._id : props.match.params.ID}
                />
            </div>
        </div>
    )
}
const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    editableContainer: {
        height: '100%',
        flex: 2,
        borderRight: '1px solid rgba(0,0,0,0.15)'
    },
    usersContainer: {
        height: '100%',
        flex: 3
    }
})

const mapStateToProps = reducers => ({
    language: reducers.languageReducer.i18n
})

const mapDispatchToProps = dispatch => ({
    getBuilding: id => dispatch(BUILDING_REQUESTS.getById(id))
})

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Details)))